import api from './index'

interface IAddress {
  id: number
  name: string
  title: string
  tel: string
  zipcode: string
  address: string
  addressDetail: string
  createdAt: string
  updatedAt: string
}

interface IPostAddress {
  title: string
  name: string
  tel: string
  zipcode: string
  address: string
  addressDetail: string
}

export async function postAddress(data: IPostAddress): Promise<IPostAddress> {
  try {
    const res = await api.post('/addresses', data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAddresss(): Promise<{data: IAddress[]; isSave: boolean}> {
  try {
    const res = await api.get(`/addresses`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAddress(id): Promise<IAddress> {
  try {
    const res = await api.get(`/addresses/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function putAddress(id: number, data: IPostAddress): Promise<IPostAddress> {
  try {
    const res = await api.put(`/addresses/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteAddress(id: number): Promise<void> {
  try {
    const res = await api.delete(`/addresses/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
