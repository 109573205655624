import api from './index'

export interface IProductBrand {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

interface IGetProductBrandsParams {
  start: number
  perPage: number
  search?: string
}

export async function getProductBrands(
  params: IGetProductBrandsParams = {start: 0, perPage: 1000}
): Promise<{data: IProductBrand[]; total: number}> {
  try {
    const res = await api.get(`/productBrands`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductBrand(id: number): Promise<IProductBrand> {
  try {
    const res = await api.get(`/productBrands/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
