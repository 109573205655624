import {Button, Checkbox, Form, Input, Modal, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import AuthLayout from '../../layouts/AuthLayout'
import rules from '../../libs/rules'
import withRequireNoAuth from '../../hocs/withRequireNoAuth'
import {postAuth} from '../../api/auth'
import {useRecoilState} from 'recoil'
import {userState} from '../../states/user.state'
import styled from 'styled-components'
import {useCookies} from 'react-cookie'

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 17
  }
}

function Login() {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useRecoilState(userState)
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies()

  const handleFinish = async (values: any) => {
    const {accountId, password, remember} = values

    // if (location.href.includes('dev') && accountId !== '111111') {
    //   Modal.error({
    //     title: '개발용 서버 접속 중',
    //     content: '현재 개발용 서버에 접속중입니다. 실제 서버로 이동 후 다시 로그인해주세요.',
    //     onOk: () => {
    //       location.href = 'https://vendor.sonovashop.co.kr/auth'
    //     }
    //   })
    // } else {
    try {
      setLoading(true)
      const res = await postAuth(accountId, password, remember)
      navigate('/console/vendor', {replace: true})
      if (res.status === 'leave') Modal.error({content: '탈퇴한 회원입니다.'})
      else setUser(res)
    } catch (e: any) {
      if (e.response.status === 404) {
        Modal.error({
          title: '로그인 실패',
          content: '아이디 혹은 패스워드가 틀렸습니다'
        })
      } else {
        Modal.error({
          title: '로그인 실패',
          content: '500 잘못된 요청입니다.'
        })
      }
    } finally {
      setLoading(false)
    }
    // }
  }

  useEffect(() => {
    if (!user) removeCookie('NOTICE_EXPIRES')
  }, [])

  return (
    <AuthLayout>
      <LogoWrapper>
        <Logo />
        <SubLogo>for Seller Member</SubLogo>
      </LogoWrapper>

      <Typography.Title level={2} style={{width: 350, marginBottom: 23}}>
        어서오세요.
      </Typography.Title>

      <Form {...layout} layout="vertical" name="basic" initialValues={{remember: true}} onFinish={handleFinish}>
        <FormItemFixedWidth
          label="아이디"
          name="accountId"
          rules={[
            {
              required: true,
              message: '아이디를 입력해주세요.'
            }
          ]}
        >
          <InputRound placeholder="아이디를 입력해주세요." />
        </FormItemFixedWidth>

        <FormItemFixedWidth
          label="비밀번호"
          name="password"
          rules={[
            {
              required: true,
              message: '비밀번호를 입력해주세요.'
            },
            ...rules.password
          ]}
        >
          <InputRoundPw placeholder="비밀번호를 입력해주세요." />
        </FormItemFixedWidth>

        <Form.Item style={{margin: '12px 0 0 0'}}>
          <SignInButton type="primary" htmlType="submit" loading={loading}>
            로그인
          </SignInButton>
        </Form.Item>

        {/* <Form.Item style={{margin: '0 0 12px 0'}}>
          <SignInButton onClick={(e) => navigate('/signup')}>회원가입</SignInButton>
        </Form.Item> */}

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>로그인 정보 기억하기</Checkbox>
        </Form.Item>

        <Form.Item style={{margin: 0}}>
          <ForgotPwWrapper>
            <Typography.Text style={{color: '#AEAEB2'}}>비밀번호를 잊으셨나요?</Typography.Text>
            <ForgotPwButton type="link" style={{marginTop: '-1px'}} onClick={(e) => navigate('/resetPassword')}>
              비밀번호 찾기
            </ForgotPwButton>
          </ForgotPwWrapper>
        </Form.Item>
      </Form>
    </AuthLayout>
  )
}

export default withRequireNoAuth(Login)

const Logo = styled.div`
  width: 126px;
  height: 36.29px;
  background-image: url('/logo256.png');
  background-size: contain;
  background-repeat: no-repeat;
`

const SubLogo = styled.div`
  margin: 10px 0 0 8px;
  color: ${process.env.REACT_APP_THEME_COLOR};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 43.71px;
`

const LogoWrapper = styled.div`
  width: 350px;
  margin-left: -9.17px;
  display: flex;
`

const SignInButton = styled(Button)`
  width: 350px;
  height: 48px;
  padding: 12px 0;
  margin-top: 12px;
  border-radius: 6px;
`

const ForgotPwWrapper = styled.div`
  width: 350px;
  height: 18px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;

  & > * {
    height: 18px;
  }
`

const ForgotPwButton = styled(Button)`
  padding: 0;
  height: 18px;
  font-size: 12px;
  font-weight: 500;
`

const FormItemFixedWidth = styled(Form.Item)`
  max-width: 350px;
  margin-bottom: 34px;

  .ant-form-item-required {
    &::before {
      margin: -10px;
      background: white;
    }
  }
`

const InputRound = styled(Input)`
  width: 350px;
  padding: 14px;
  border-radius: 8px;
`

const InputRoundPw = styled(Input.Password)`
  width: 350px;
  padding: 14px;
  border-radius: 8px;
`
