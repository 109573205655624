import styled from 'styled-components'

export default function Tag({name, value, onClick = () => {}, korean = ''}) {
  return (
    <Container onClick={onClick}>
      <div className="name">{name}</div>
      <div className="value">
        {korean || value}
        {!!korean && <div className="sub-value">{value}</div>}
      </div>
    </Container>
  )
}

const Container = styled.div<any>`
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: rgba(29, 35, 41, 0.1) 2px 0px 8px 0px;
  color: white;
  border-radius: 20px;
  font-size: 15px;

  .name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 5px 0 10px;
    border-radius: 20px 0 0 20px;
    background: ${process.env.REACT_APP_THEME_COLOR};
    border: 1.5px solid ${process.env.REACT_APP_THEME_COLOR};
  }

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 10px 0 5px;
    border-radius: 0 20px 20px 0;
    background: white;
    border: 1.5px solid ${process.env.REACT_APP_THEME_COLOR};
    color: ${process.env.REACT_APP_THEME_COLOR};
    font-weight: 500;
  }

  .sub-value {
    margin-left: 2px;
    font-size: 10px;
    position: relative;
    top: -2px;
    opacity: 0.6;
  }
`
