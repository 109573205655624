import api from './index'

export interface IDeliveryType {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

interface IGetDeliveryTypeParams {
  start: number
  perPage: number
  search?: string
}

export async function getDeliveryTypes(
  params: IGetDeliveryTypeParams = {start: 0, perPage: 1000}
): Promise<{data: IDeliveryType[]; total: number}> {
  try {
    const res = await api.get(`/deliveryTypes`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDeliveryType(id: number): Promise<IDeliveryType> {
  try {
    const res = await api.get(`/deliveryTypes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
