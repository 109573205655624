import {Helmet, HelmetProvider} from 'react-helmet-async'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Card, Image, Layout, Space, Typography} from 'antd'
// import GlobalHeader from '../components/GlobalHeader'
// import withRequireAdminAuth from '../hocs/withRequireAdminAuth'
// import BreadcrumbPageHeader from '../components/common/BreadcrumbPageHeader'
// import {BreadcrumbProvider} from '../contexts/BreadcrumbContext'
import withRequireAuth from '../hocs/withRequireAuth'
import SideMenu from '../components/common/SideMenu'
import GlobalHeader from '../components/common/GlobalHeader'
import {Outlet} from 'react-router'
import Trigger from '../components/Trigger'

const {Header, Footer, Sider, Content} = Layout

const Logo = styled.div`
  padding: 16px 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.45);
`

function BasicLayout({children}: React.PropsWithChildren) {
  const [collapsed, setCollapsed] = useState(false)
  const [isShow, setIsShow] = useState(false)

  function onCollapse(collapsed: boolean) {
    setCollapsed(collapsed)
  }

  useEffect(() => {
    setIsShow(true)
  }, [])

  return (
    <HelmetProvider>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>
      <Layout style={{minHeight: '100vh', minWidth: 1280}}>
        <SiderStyled
          trigger={<Trigger collapsed={collapsed} />}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
        >
          <Logo style={{padding: '40px 0 28px 0', background: process.env.REACT_APP_THEME_COLOR, textAlign: 'center'}}>
            <Space direction="vertical">
              <Image preview={false} src="/logoTransparentBg.png" />
              <Typography.Title level={5} style={{color: 'white', textAlign: 'center'}}>
                {!collapsed && 'Vendor Web'}
              </Typography.Title>
            </Space>
          </Logo>
          <SideMenu theme="light" mode="inline" />
        </SiderStyled>
        {isShow && (
          <Layout>
            <Header
              style={{
                padding: 0,
                background: '#fff',
                boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)',
                borderRadius: '10px',
                margin: '16px 16px 0 16px'
              }}
            >
              {<GlobalHeader />}
            </Header>
            <Content>
              <Card
                style={{
                  padding: 0,
                  background: '#fff',
                  boxShadow: '2px 0 8px 0 rgb(29 35 41 / 5%)',
                  borderRadius: '10px',
                  margin: '16px'
                }}
              >
                <Outlet />
              </Card>
            </Content>
            <Footer />
          </Layout>
        )}
      </Layout>
    </HelmetProvider>
  )
}

const SiderStyled = styled(Sider)`
  background: #fff;
  box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);
`

export default withRequireAuth(BasicLayout)
