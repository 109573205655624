import {Col, Row, Typography} from 'antd'
import React, {ReactNode, useEffect, useState} from 'react'
import styled from 'styled-components'
import {getBanners} from '../api/banners'

type AuthLayoutProps = {
  title?: ReactNode
}

export default function AuthLayout(props: React.PropsWithChildren<AuthLayoutProps>) {
  const [banner, setBanner] = useState('')

  async function onFetchBanner() {
    const res = await getBanners()
    const bannerImage = res.data.sort((a: any, b: any) => +(a.sort > b.sort) || +(a.sort === b.sort) - 1)[0].titleImage
    setBanner(bannerImage)
  }

  useEffect(() => {
    onFetchBanner()
  }, [])

  return (
    <StyledCard
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <LeftCard banner={banner} />
      <LeftCardShadow />
      <RightCard>
        <Typography.Title level={2} style={{width: '400px', color: process.env.REACT_APP_THEME_COLOR}}>
          {props.title}
        </Typography.Title>
        {props.children}
        <AppDownWrapper>
          <Row gutter={[0, 10]}>
            <Col>
              <AppDownSuggest>판매자 회원님! SONOVA 앱을 이용해보세요.</AppDownSuggest>
            </Col>
          </Row>
          <Row gutter={11.42}>
            <Col>
              <AppStore
                onClick={() => window.open('https://apps.apple.com/us/app/sonova-shop/id1666746583', '_blank')}
              />
            </Col>
            <Col>
              <GooglePlay
                onClick={() => window.open('https://play.google.com/store/apps/details?id=com.sonova', '_blank')}
              />
            </Col>
          </Row>
        </AppDownWrapper>
      </RightCard>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  width: 100%;
  height: 100vh;
`

const LeftCard = styled.div<{banner: string}>`
  width: 50vw;
  height: 100vh;
  background-image: url(${({banner}: any) => banner});
  background-repeat: no-repeat;
  background-size: cover;
`

const LeftCardShadow = styled.div`
  width: 50vw;
  height: 100vh;
  margin-right: 50%;
  position: absolute;
  background: #00000066;
`

const RightCard = styled.div`
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AppDownWrapper = styled.div`
  width: 350px;
  margin-top: 50px;
`

const AppDownSuggest = styled.div`
  color: ${process.env.REACT_APP_THEME_COLOR};
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;
`

const AppStore = styled.div`
  cursor: pointer;
  width: 147.97px;
  height: 48px;
  background-image: url('/appstore.png');
  background-size: contain;
  background-repeat: no-repeat;
`

const GooglePlay = styled.div`
  cursor: pointer;
  width: 162px;
  height: 48px;
  background-image: url('/googleplay.png');
  background-size: contain;
  background-repeat: no-repeat;
`
