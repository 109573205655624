import api from './index'

export interface IDeliveryDetail {
  id: number
  num: number
  serialNumber: string
  status: string
  date: string
  userName: string
  modelName: string
  type: string
  deliveryTime: string
  accountCode: string
  orderType: string
  createdAt: string
}

interface IGetDeliveryDetailsParams {
  startDate?: string
  endDate?: string
}

export async function getDeliveryDetails(
  params: IGetDeliveryDetailsParams
): Promise<{data: IDeliveryDetail[]; total: number}> {
  try {
    const res = await api.get('/delivery-detail', {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDeliveryDetail(id: number): Promise<IDeliveryDetail> {
  try {
    const res = await api.get(`/delivery-detail/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
