import {Divider, Typography} from 'antd'
import {ReactNode} from 'react'
import styled from 'styled-components'

interface IProp {
  title: ReactNode | string
  subTitle?: ReactNode | string
}

export default function SimpleTitle({title, subTitle = ''}: IProp) {
  return (
    <div style={{whiteSpace: 'normal'}}>
      <SubTitle>{subTitle}</SubTitle>
      <Typography.Title
        style={{
          color: process.env.REACT_APP_THEME_COLOR
        }}
        level={2}
      >
        {title}
      </Typography.Title>
      <div style={{width: '40px'}}>
        <Divider style={{border: `2px solid ${process.env.REACT_APP_THEME_COLOR}`, margin: '20px 0 25px 0'}} />
      </div>
    </div>
  )
}

const SubTitle = styled(Typography)`
  margin-bottom: -35px;
  font-size: 13px;
  color: ${process.env.REACT_APP_THEME_COLOR};
`
