export default function PrivacyPolicy() {
  const iframePart = () => {
    return {
      __html: `<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style>
    <!--
     /* Font Definitions */
     @font-face
    	{font-family:Wingdings;
    	panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
    	{font-family:Gulim;
    	panose-1:2 11 6 0 0 1 1 1 1 1;}
    @font-face
    	{font-family:"Cambria Math";
    	panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
    	{font-family:"\@Gulim";
    	panose-1:2 11 6 0 0 1 1 1 1 1;}
    @font-face
    	{font-family:"Malgun Gothic";
    	panose-1:2 11 5 3 2 0 0 2 0 4;}
    @font-face
    	{font-family:"\@Malgun Gothic";}
    @font-face
    	{font-family:나눔바른고딕;}
    @font-face
    	{font-family:"\@나눔바른고딕";}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
    	{margin-top:0in;
    	margin-right:0in;
    	margin-bottom:8.0pt;
    	margin-left:0in;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:10.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    a:link, span.MsoHyperlink
    	{color:#0563C1;
    	text-decoration:underline;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
    	{margin-top:0in;
    	margin-right:0in;
    	margin-bottom:8.0pt;
    	margin-left:40.0pt;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:10.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    .MsoChpDefault
    	{font-size:10.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    .MsoPapDefault
    	{margin-bottom:8.0pt;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;}
     /* Page Definitions */
     @page WordSection1
    	{size:595.3pt 841.9pt;
    	margin:85.05pt 1.0in 1.0in 1.0in;}
    div.WordSection1
    	{page:WordSection1;}
     /* List Definitions */
     ol
    	{margin-bottom:0in;}
    ul
    	{margin-bottom:0in;}
    -->
  </style>
</head>

<body style="word-wrap: break-word" vlink="#954F72" link="#0563C1" lang="EN-US">
  <div class="WordSection1">
    <p class="MsoNormal" style="text-align: center" align="center">
      <b
        ><span style="font-size: 12pt; line-height: 107%; font-family: 나눔바른고딕" lang="KO"
          >소노바코리아 유한회사
        </span></b
      ><b
        ><span style="font-size: 12pt; line-height: 107%; font-family: 나눔바른고딕"
          >E-order <span lang="KO">시스템 개인정보처리방침</span></span
        ></b
      >
    </p>

    <p class="MsoNormal" style="text-align: center" align="center">
      <b><span style="font-size: 12pt; line-height: 107%; font-family: 나눔바른고딕">&nbsp;</span></b>
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">소노바코리아 유한회사 </span
      ><span style="font-family: 나눔바른고딕"
        >(<span lang="KO">이하</span> “<span lang="KO">회사</span>”)<span lang="KO">는 「개인정보 보호법」</span>(<span
          lang="KO"
          >이하 </span
        >“<span lang="KO">개인정보보호법</span>”) <span lang="KO">등 모든 관련 법규를 준수하며</span>,
        <span lang="KO">회사의 서비스를 이용하는 고객</span>(<span lang="KO">이하</span> “<span lang="KO">이용자</span
        >”)<span lang="KO">의 개인정보가 보호받을 수 있도록 최선을 다하고 있습니다</span>.
        <span lang="KO"
          >회사는 개인정보처리방침의 공개를 통하여 이용자의 개인정보가 어떠한 목적과 방식으로 이용되고 있으며
          개인정보보호를 위해 어떠한 조치가 취해지고 있는지를 알려 드립니다</span
        >.
        <span lang="KO"
          >본 개인정보처리방침은 관련 법령의 개정이나 회사 내부 방침에 의해 변경될 수 있으므로 회원 가입 시나 사이트
          이용 시에 수시로 확인하여 주시기 바랍니다</span
        >.
      </span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제</span
      ><span style="font-family: 나눔바른고딕">
        1 <span lang="KO">조</span> [<span lang="KO">수집하는 개인정보의 항목 및 수집방법</span>]</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">수집하는 개인정보의 항목</span>
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">회사는 회원가입 또는 서비스 이용 시 고객상담</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">각종 서비스의 제공을 위하여 다음과 같은 개인정보를 수집 이용하고 있습니다</span>.
      </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">필수정보 </span>
    </p>

    <p
      class="MsoListParagraph"
      style="margin-top: 0in; margin-right: 0in; margin-bottom: 0in; margin-left: 40pt; line-height: normal"
    >
      <span style="font-family: 나눔바른고딕" lang="KO">거래처명</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">대표자 성명</span>, <span lang="KO">사업자 등록번호</span>, <span lang="KO">전화번호</span>,
        <span lang="KO">휴대폰번호</span>,<span lang="KO">거래처 주소</span>, <span lang="KO">납품처주소</span>,
        <span lang="KO">납품처 전화번호</span>, <span lang="KO">납품처 휴대폰번호</span></span
      >
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">선택정보</span>
    </p>

    <p
      class="MsoListParagraph"
      style="margin-top: 0in; margin-right: 0in; margin-bottom: 0in; margin-left: 40pt; line-height: normal"
    >
      <span style="font-family: 나눔바른고딕" lang="KO">전자메일</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">가상계좌</span>, <span lang="KO">거래처 업종</span></span
      >
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >서비스 이용 과정이나 사업처리 과정에서 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕"
        >IP<span lang="KO">주소</span>, <span lang="KO">쿠키</span>, <span lang="KO">방문 일시</span>,
        <span lang="KO">서비스 이용 기록</span>, <span lang="KO">불량 이용 기록 </span></span
      >
    </p>

    <p class="MsoNormal">
      <span class="MsoCommentReference"><span style="font-size: 9pt; line-height: 107%">&nbsp;</span></span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">개인정보 수집방법</span>
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">회사는 다음과 같은 방법으로 개인정보를 수집합니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕">E-order <span lang="KO">웹사이트 회원가입</span></span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">회사는 기본적 인권침해의 우려가 있는 개인정보</span
      ><span style="font-family: 나눔바른고딕"
        >(<span lang="KO">인종 및 민족</span>, <span lang="KO">사상 및 신조</span>,
        <span lang="KO">출신지 및 본적지</span>, <span lang="KO">정치적 성향 및 범죄기록</span>,
        <span lang="KO">건강상태 및 성생활 등</span>)<span lang="KO">는 요구하지 않습니다</span>.</span
      >
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제</span
      ><span style="font-family: 나눔바른고딕">
        2 <span lang="KO">조</span> [<span lang="KO">개인정보의 수집 및 이용목적</span>]</span
      >
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다</span
      ><span style="font-family: 나눔바른고딕">. </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">서비스 제공에 관한 계약 이행 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">물품배송 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">다.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">회원관리</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">라.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">회원제 서비스 이용에 따른 본인확인</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">개인식별</span>, <span lang="KO">불량회원의 부정 이용 방지와 비인가 사용 방지</span>,
        <span lang="KO">중복가입 확인</span>, <span lang="KO">가입의사 확인</span>,
        <span lang="KO">분쟁 조정을 위한 기록보전</span>, <span lang="KO">불만처리 등 민원처리</span>,
        <span lang="KO">고지사항 전달 </span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">마.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">신규 서비스 개발</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">바.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">신규 서비스 개발 및 맞춤 서비스 제공</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">통계학적 특성에 따른 서비스 제공 및 광고 게재</span>,
        <span lang="KO">서비스의 유효성 확인</span>, <span lang="KO">접속 빈도 파악</span>,
        <span lang="KO">회원의 서비스 이용에 대한 통계</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt; text-indent: -0.25in">
      <span style="font-family: 나눔바른고딕">사.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">이벤트 정보 및 참여기회 제공</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">광고성 정보 제공</span>, <span lang="KO">마케팅 및 광고에 활용</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 38pt">
      <span style="font-family: 나눔바른고딕; background: yellow">&nbsp;</span>
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제</span
      ><span style="font-family: 나눔바른고딕">
        3 <span lang="KO">조</span> [<span lang="KO">개인정보의 제공</span>, <span lang="KO">위탁</span>]</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">회사는 이용자의 개인정보를 제</span
      ><span style="font-family: 나눔바른고딕"
        >2<span lang="KO">조</span>(<span lang="KO">개인정보의 수집 및 이용목적</span>)<span lang="KO"
          >에서 고지한 범위 내에서 이용하며</span
        >,
        <span lang="KO"
          >이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 타인 또는 타 기관에 제공 하지 않습니다</span
        >. <span lang="KO">다만</span>, <span lang="KO">다음의 경우에는 예외로 합니다</span>.
      </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">이용자가 사전에 동의한 경우 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">법령의 규정에 의거하거나</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">수사상 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >다.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">서비스 제공에 따른 요금 정산을 위해 필요한 경우</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >라.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">통계작성</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 이용자에 대하여 보다 질 높은 서비스 제공 등을 위해 사전에 동의를 획득한 경우에 한하여 이용자의
        개인정보를 제공하고 있습니다</span
      ><span style="font-family: 나눔바른고딕"
        >. <span lang="KO">현재 회사는 이용자의 사전 동의를 받아 아래와 같이 개인정보를 제</span>3<span lang="KO"
          >자에게 제공하고 있습니다</span
        >.
      </span>
    </p>

    <table
      class="MsoNormalTable"
      style="width: 446.55pt; margin-left: -0.5pt; border-collapse: collapse"
      width="595"
      cellspacing="0"
      cellpadding="0"
      border="0"
    >
      <tbody>
        <tr style="height: 18.3pt">
          <td
            style="
              width: 99.2pt;
              border: solid windowtext 1pt;
              background: #e7e6e6;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 18.3pt;
            "
            width="132"
          >
            <p class="MsoNormal" style="text-align: center" align="center">
              <span style="font-family: 나눔바른고딕" lang="KO">제공받는<span style="color: black"> 자</span></span>
            </p>
          </td>
          <td
            style="
              width: 113.4pt;
              border: solid windowtext 1pt;
              border-left: none;
              background: #e7e6e6;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 18.3pt;
            "
            width="151"
          >
            <p class="MsoNormal" style="text-align: center" align="center">
              <span style="font-family: 나눔바른고딕; color: black" lang="KO">제공목적</span>
            </p>
          </td>
          <td
            style="
              width: 115.15pt;
              border: solid windowtext 1pt;
              border-left: none;
              background: #e7e6e6;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 18.3pt;
            "
            width="154"
          >
            <p class="MsoNormal" style="text-align: center" align="center">
              <span style="font-family: 나눔바른고딕; color: black" lang="KO">제공하는 항목</span>
            </p>
          </td>
          <td
            style="
              width: 1.65in;
              border: solid windowtext 1pt;
              border-left: none;
              background: #e7e6e6;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 18.3pt;
            "
            width="158"
          >
            <p class="MsoNormal" style="text-align: center" align="center">
              <span style="font-family: 나눔바른고딕; color: black" lang="KO">보유기간</span>
            </p>
          </td>
        </tr>
        <tr style="height: 36.6pt">
          <td
            style="
              width: 99.2pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="132"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >주식회사 링크 허브</span
                ></strong
              >
            </p>
          </td>
          <td
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="151"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal"
                  >SMS,LMS,<span lang="KO">카카오톡 전송</span></span
                ></strong
              >
            </p>
          </td>
          <td
            style="
              width: 115.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="154"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >주문정보 및 거래처정보</span
                ></strong
              ><strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal"
                  >(<span lang="KO">이름</span>,<span lang="KO">전화번호 등</span>)</span
                ></strong
              >
            </p>
          </td>
          <td
            style="
              width: 1.65in;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="158"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >회원탈퇴 혹은 계약종료시점까지</span
                ></strong
              >
            </p>
          </td>
        </tr>
        <tr style="height: 35.85pt">
          <td
            style="
              width: 99.2pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 35.85pt;
            "
            width="132"
          >
            <p class="MsoNormal">
              <strong><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO">㈜로젠택배</span></strong>
            </p>
          </td>
          <td
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 35.85pt;
            "
            width="151"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >상품배송 및 배송위치</span
                ></strong
              ><strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal"
                  >, <span lang="KO">도착정보등의 서비스제공</span></span
                ></strong
              >
            </p>
          </td>
          <td
            style="
              width: 115.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 35.85pt;
            "
            width="154"
          >
            <p class="MsoNormal">
              <span style="font-family: 나눔바른고딕" lang="KO">거래처정보</span
              ><span style="font-family: 나눔바른고딕"
                >(<span lang="KO">이름</span>,<span lang="KO">주소</span>,<span lang="KO">전화번호</span>)</span
              >
            </p>
          </td>
          <td
            style="
              width: 1.65in;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 35.85pt;
            "
            width="158"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >회원탈퇴 혹은 계약종료시점까지</span
                ></strong
              >
            </p>
          </td>
        </tr>
        <tr style="height: 36.6pt">
          <td
            style="
              width: 99.2pt;
              border: solid windowtext 1pt;
              border-top: none;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="132"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >주식회사 채널코퍼레이션</span
                ></strong
              >
            </p>
          </td>
          <td
            style="
              width: 113.4pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="151"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO">쳇봇 서비스제공</span></strong
              >
            </p>
          </td>
          <td
            style="
              width: 115.15pt;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="154"
          >
            <p class="MsoNormal">
              <span style="font-family: 나눔바른고딕" lang="KO">거래처정보</span
              ><span style="font-family: 나눔바른고딕"
                >(<span lang="KO">이름</span>,<span lang="KO">주소</span>,<span lang="KO">전화번호</span>)</span
              >
            </p>
          </td>
          <td
            style="
              width: 1.65in;
              border-top: none;
              border-left: none;
              border-bottom: solid windowtext 1pt;
              border-right: solid windowtext 1pt;
              padding: 0in 5.4pt 0in 5.4pt;
              height: 36.6pt;
            "
            width="158"
          >
            <p class="MsoNormal">
              <strong
                ><span style="font-family: 나눔바른고딕; font-weight: normal" lang="KO"
                  >회원탈퇴 혹은 계약종료시점까지</span
                ></strong
              >
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal" style="text-autospace: ideograph-numeric ideograph-other; word-break: normal">
      <span style="font-family: 나눔바른고딕" lang="KO">제 </span
      ><span style="font-family: 나눔바른고딕"
        >4 <span lang="KO">조</span> [<span lang="KO">개인정보의 보유 및 이용기간</span>]</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >이용자의 개인정보는 이용자가 회원탈퇴를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는
        경우</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">또는 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">상법</span
      ><span style="font-family: 나눔바른고딕"
        >,
        <span lang="KO"
          >전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령의 규정에 의해 보존할 필요성이 있는 경우</span
        >, <span lang="KO">회사는 관계법령에서 정한 일정한 기간 이상 이용자의 개인정보를 보관할 수 있습니다</span>.
        <span lang="KO">이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며</span>,
        <span lang="KO">보존근거에서 명시한 근거에 따라 보존기간 동안 보존합니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">계약 또는 청약철회 등에 관한 기록 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 근거 </span
      ><span style="font-family: 나눔바른고딕"
        >: <span lang="KO">전자상거래 등에서의 소비자보호에 관한 법률 제</span>6<span lang="KO"
          >조 및 동법 시행령 제</span
        >6<span lang="KO">조 제</span>1<span lang="KO">항 제 </span>2<span lang="KO">호</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 기간 </span
      ><span style="font-family: 나눔바른고딕">: 5<span lang="KO">년 </span></span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">재화 등의 공급에 관한 기록 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 근거 </span
      ><span style="font-family: 나눔바른고딕"
        >: <span lang="KO">전자상거래 등에서의 소비자보호에 관한 법률 제</span>6<span lang="KO"
          >조 및 동법 시행령 제</span
        >6<span lang="KO">조 제</span>1<span lang="KO">항 제</span>3<span lang="KO">호</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 기간 </span
      ><span style="font-family: 나눔바른고딕">: 5<span lang="KO">년 </span></span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >⑤<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">소비자의 불만 또는 분쟁처리에 관한 기록</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 근거 </span
      ><span style="font-family: 나눔바른고딕"
        >: <span lang="KO">전자상거래 등에서의 소비자보호에 관한 법률 제</span>6<span lang="KO"
          >조 및 동법 시행령 제</span
        >6<span lang="KO">조 제</span>1<span lang="KO">항 제</span>4<span lang="KO">호</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 기간 </span
      ><span style="font-family: 나눔바른고딕">: 3<span lang="KO">년 </span></span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >⑥<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">표시</span
      ><span style="font-family: 나눔바른고딕">/<span lang="KO">광고에 관한 기록 </span></span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 근거 </span
      ><span style="font-family: 나눔바른고딕"
        >: <span lang="KO">전자상거래 등에서의 소비자보호에 관한 법률 제</span>6<span lang="KO"
          >조 및 동법 시행령 제</span
        >6<span lang="KO">조 제</span>1<span lang="KO">항 제</span>1<span lang="KO">호</span></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">보존 기간 </span
      ><span style="font-family: 나눔바른고딕">: 6<span lang="KO">개월 </span></span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >⑦<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">웹사이트 방문 기록 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">가</span
      ><span style="font-family: 나눔바른고딕"
        >. <span lang="KO">보존 근거 </span>: <span lang="KO">통신비밀보호법 제</span>15<span lang="KO">조의</span>2
        <span lang="KO">및 동법 시행령 제</span>41<span lang="KO">조 제</span>2<span lang="KO">항 제</span>2<span
          lang="KO"
          >호</span
        ></span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">나</span
      ><span style="font-family: 나눔바른고딕">. <span lang="KO">보존 기간 </span>: 3<span lang="KO">개월 </span></span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제 </span
      ><span style="font-family: 나눔바른고딕"
        >5 <span lang="KO">조</span> [<span lang="KO">개인정보의 파기절차 및 방법</span>]</span
      >
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO"
        >이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기됩니다</span
      ><span style="font-family: 나눔바른고딕"
        >. <span lang="KO">회사의 개인정보 파기절차 및 방법은 다음과 같습니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">파기절차 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의</span
      ><span style="font-family: 나눔바른고딕">
        DB<span lang="KO"
          >로 옮겨져 내부 방침 및 기타 관계법령에 의한 정보보호 사유에 따라 일정 기간 저장 후 파기됩니다</span
        >.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >상기 개인정보는 법률에 의한 경우를 제외하고 다른 목적으로 개인정보를 이용되지 않습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">파기방법 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제 </span
      ><span style="font-family: 나눔바른고딕"
        >6 <span lang="KO">조</span> [<span lang="KO">이용자 및 법정대리인의 권리와 그 행사방법</span>]</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >이용자는 언제든지 이용자 본인의 개인정보를 조회하거나 정정할 수 있으며</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">가입해지</span>(<span lang="KO">회원탈퇴</span>)
        <span lang="KO">또는 개인정보의 삭제를 요청할 수 있습니다</span>. <span lang="KO">단</span>,
        <span lang="KO"
          >서비스 제공을 위해 반드시 필요한 개인정보를 삭제하는 경우 관련 서비스를 제공받지 못할 수 있습니다</span
        >.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">이용자의 개인정보를 조회</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">정정하기 위하여는 개인정보변경을</span>, <span lang="KO">가입해지</span>(<span lang="KO"
          >동의철회</span
        >)<span lang="KO">를 위하여는 회원탈퇴를 클릭하여 본인 확인 절차 후 직접 조회</span>,
        <span lang="KO">정정 또는 가입해지</span>(<span lang="KO">동의철회</span>)<span lang="KO">할 수 있습니다</span>.
        <span lang="KO">또는 개인정보보호책임자 및 담당자에게 서면</span>,
        <span lang="KO">전화 또는 이메일로 연락하시면 본인 확인 절차 후 지체 없이 필요한 조치를 하겠습니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지
        않습니다</span
      ><span style="font-family: 나눔바른고딕"
        >. <span lang="KO">또한 잘못된 개인정보를 제</span>3<span lang="KO"
          >자에게 이미 제공한 경우에는 정정 처리결과를 제</span
        >3<span lang="KO">자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 이용자의 요청에 의해 해지 또는 삭제된 개인정보를 제</span
      ><span style="font-family: 나눔바른고딕"
        >5<span lang="KO">조</span>(<span lang="KO">개인정보의 보유 및 이용기간</span>)<span lang="KO"
          >에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다</span
        >.</span
      >
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제 </span
      ><span style="font-family: 나눔바른고딕"
        >7 <span lang="KO">조</span> [<span lang="KO">개인정보의 자동 수집 장치의 설치</span>,
        <span lang="KO">운영 및 그 거부에 관한 사항</span>]</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 이용자에게 개인화되고 맞춤화 된 서비스를 제공하기 위해 이용자의 정보를 저장하고 수시로 찾아내는
        쿠키</span
      ><span style="font-family: 나눔바른고딕"
        >(Cookie)<span lang="KO">를 사용합니다</span>.
        <span lang="KO">쿠키는 웹사이트가 이용자의 웹 브라우저</span>(<span lang="KO">인터넷 익스플로러</span>,
        <span lang="KO">마이크로소프트 엣지</span>, <span lang="KO">파이어폭스</span>,
        <span lang="KO">크롬 등</span>)<span lang="KO">로 전송하는 소량의 정보입니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">이용자는 쿠키에 대한 선택권을 가지고 있습니다</span
      ><span style="font-family: 나눔바른고딕"
        >. <span lang="KO">이용자는 웹 브라우저에서</span> [<span lang="KO">도구</span>] &gt; [<span lang="KO"
          >인터넷옵션</span
        >] &gt; [<span lang="KO">개인정보</span>] &gt; [<span lang="KO">설정</span>]<span lang="KO"
          >을 선택함으로써 모든 쿠키를 허용하거나</span
        >, <span lang="KO">쿠키가 저장될 때마다 확인을 거치거나</span>,
        <span lang="KO">아니면 모든 쿠키의 저장을 거부할 수 있습니다</span>. <span lang="KO">단</span>,
        <span lang="KO">모든 쿠키의 저장을 거부하는 경우</span>,
        <span lang="KO">쿠키를 통해 회사에서 제공하는 서비스를 이용할 수 없습니다</span>.</span
      >
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제 </span
      ><span style="font-family: 나눔바른고딕"
        >8 <span lang="KO">조</span> [<span lang="KO">개인정보의 기술적</span>/
        <span lang="KO">관리적 보호 대책</span>]</span
      >
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">도난</span>, <span lang="KO">유출</span>,
        <span lang="KO">변조 또는 훼손되지 않도록 안전성 확보를 위해 기술적</span>,
        <span lang="KO">관리적 대책을 마련하고 있습니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">기술적 대책 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >이용자의 비밀번호는 암호화되어 있어 이용자 본인 외에는 알 수 없습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 암호화 통신을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >다.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 해킹이나 컴퓨터바이러스 등에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고
        있습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >라.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고</span
      ><span style="font-family: 나눔바른고딕"
        >,
        <span lang="KO"
          >최신 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다</span
        >.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >마.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">회사는 시스템에 대한 접근통제</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">권한관리</span>,
        <span lang="KO">취약점 점검 등의 조치를 통해 보안성이 강화될 수 있도록 지속적으로 노력하고 있습니다</span
        >.</span
      >
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">관리적 대책 </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 개인정보처리자를 대상으로 개인정보 보호 의무 등에 관해 정기적인 교육을 실시하고 있습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >다.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 개인정보보호 업무를 전담하는 부서에서 개인정보처리방침 및 내부 규정 준수 여부를 확인하여 문제가 발견될
        경우</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">즉시 바로 잡을 수 있도록 노력하고 있습니다</span>.</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >라.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는
        회사는 책임을 지지 않습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span class="MsoCommentReference"
        ><span style="font-size: 9pt; line-height: 107%; font-family: 나눔바른고딕" lang="KO">제 </span></span
      ><span style="font-family: 나눔바른고딕"
        >9 <span lang="KO">조 </span>[<span lang="KO">개인정보보호책임자 및 담당자의 연락처</span>]</span
      >
    </p>

    <p class="MsoListParagraph" style="margin-left: 20pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 다음과 같이 개인정보보호책임자를
        두고 있습니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">개인정보보호책임자</span
      ><span style="font-family: 나눔바른고딕"> : <span lang="KO">인사팀 조인희 부장 </span>(ryan.cho@sonova.com)</span
      ><a href="mailto:"></a><span style="font-family: 나눔바른고딕"> </span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">개인정보보호담당자 </span
      ><span style="font-family: 나눔바른고딕">: <span lang="KO">고객지원팀 이한솔 팀장 </span>(</span
      ><a href="mailto:angela.lee@sonova.com"><span style="font-family: 나눔바른고딕">angela.lee@sonova.com</span></a
      ><span style="font-family: 나눔바른고딕">)</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO"
        >② 기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우 다음 기관에 문의하시기 바랍니다</span
      ><span style="font-family: 나눔바른고딕">.</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >가.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">개인정보침해신고센터</span
      ><span style="font-family: 나눔바른고딕">(privacy.kisa.or.kr / <span lang="KO">국번 없이</span> 118)</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >나.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">대검찰청 사이버범죄수사단</span
      ><span style="font-family: 나눔바른고딕">(cyberid@spo.go.kr / 02-3480-3571)</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >다.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">경찰청 사이버안전국</span
      ><span style="font-family: 나눔바른고딕">(www.ctrc.go.kr / <span lang="KO">국번 없이</span> 182)</span>
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-indent: -20pt">
      <span style="font-family: 나눔바른고딕"
        >라.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span></span
      ><span style="font-family: 나눔바른고딕" lang="KO">개인정보 분쟁조정위원회</span
      ><span style="font-family: 나눔바른고딕">(kopico.go.kr / 1833-6972)</span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제</span
      ><span style="font-family: 나눔바른고딕"> 10 <span lang="KO">조</span> [<span lang="KO">링크 사이트</span>]</span>
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO"
        >회사는 이용자에게 다른 회사의 웹사이트에 대한 링크를 제공할 수 있습니다</span
      ><span style="font-family: 나눔바른고딕"
        >.
        <span lang="KO"
          >이 경우 링크되어 있는 웹사이트가 개인정보를 수집하는 행위에 대해서는 본 개인정보처리방침이 적용되지
          않습니다</span
        >.
      </span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">제</span
      ><span style="font-family: 나눔바른고딕"> 11 <span lang="KO">조</span> [<span lang="KO">기타</span>]</span>
    </p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">본 개인정보처리방침의 내용 추가</span
      ><span style="font-family: 나눔바른고딕"
        >, <span lang="KO">삭제 및 수정이 있을 시에는 최소</span> 7<span lang="KO"
          >일 전부터 홈페이지의 공지사항을 통해 고지할 것입니다</span
        >.
        <span lang="KO">이전 <a name="_Hlk126769768">개인정보처리방침의 내용은 링크에서 확인하실 수 있습니다</a></span
        >.
      </span>
    </p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal"><span style="font-family: 나눔바른고딕">&nbsp;</span></p>

    <p class="MsoNormal">
      <span style="font-family: 나눔바른고딕" lang="KO">공고일자</span
      ><span style="font-family: 나눔바른고딕">
        : 2023<span lang="KO">년</span> 1<span lang="KO">월</span> 1<span lang="KO">일</span></span
      >
    </p>

    <p class="MsoNormal">&nbsp;</p>
  </div>
</body>
`
    }
  }

  return (
    <div
      style={{margin: 'auto', position: 'relative', width: '100%', height: '1000px', overflow: 'hidden'}}
      dangerouslySetInnerHTML={iframePart()}
    />
  )
}
