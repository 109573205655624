import api from './index'

export interface IProductOrderType {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

interface IGetProductOrderTypesParams {
  start: number
  perPage: number
  search?: string
}

export async function getProductOrderTypes(
  params: IGetProductOrderTypesParams = {start: 0, perPage: 1000}
): Promise<{data: IProductOrderType[]; total: number}> {
  try {
    const res = await api.get(`/productOrderTypes`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductOrderType(id: number): Promise<IProductOrderType> {
  try {
    const res = await api.get(`/productOrderTypes/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
