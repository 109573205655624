import api from './index'

export interface IPostVerification {
  codeId?: string
  code?: string
  phone: string
  forAndroid: boolean
  type: 'register' | 'resetPassword' | 'changePhone' | 'findEmail' | string
}

export interface IPostVerificationConfirm {
  codeToken: string
  code: string
}

export async function postVerification(
  data: IPostVerification
): Promise<{codeToken: string; expireAt: string; code?: string}> {
  try {
    const res = await api.post(`/verifications`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postVerificationConfirm(data: IPostVerificationConfirm): Promise<{codeToken: string}> {
  try {
    const res = await api.post(`/verifications/confirm`, data)
    return res.data
  } catch (e) {
    throw e
  }
}
