import React, {useContext, useEffect, useRef, useState} from 'react'
import withPageContext from '../../../hocs/withPageContext'
import PageContext from '../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../components/common/BasicTableModal'
import ProductModal from './components/ProductModal'
import ImageView from '../../../components/ImageView'
import {getProduct, getProductList, getProducts} from '../../../api/products'
import styled from 'styled-components'
import {Button, Col, Input, Row, Skeleton, Space, Spin} from 'antd'
import SimpleTitle from '../../../components/common/SimpleTitle'
import {HomeOutlined, LeftOutlined} from '@ant-design/icons'
import {getCategory} from '../../../api/productCategories'
import AccProductModal from './components/AccProductModal'
import IteProductModal from './components/IteProductModal'
import BasicList from '../../../components/common/BasicList'
import Tag from '../../../components/common/Tag'
import {getAppCart} from '../../../api/cart'
import {useRecoilState} from 'recoil'
import {cartTotalState} from '../../../states/cartTotal.state'
import {getVendor} from '../../../api/vendors'

function Products() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const accModalRef = useRef<BasicTableModalRef>(null)
  const iteModalRef = useRef<BasicTableModalRef>(null)
  const [brands, setBrands] = useState<any>([])
  const [orderTypes, setOrderTypes] = useState<any>([])
  const [platforms, setPlatforms] = useState<any>([])
  const [products, setProducts] = useState<any>([])
  const [productDetails, setProductDetails] = useState<any>([])
  const [props, setProps] = useState({
    brandId: -1,
    orderTypeId: -1,
    platformId: -1,
    productId: -1,
    productDetailId: -1
  })
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [tableData, setTableData] = useState<any>([])
  const [categoryLoading, setCategoryLoading] = useState<boolean>(false)
  const [, setCartTotal] = useRecoilState(cartTotalState)
  const [isITE, setIsITE] = useState<boolean>(false)

  function showTableModal(e, data?, index?, list?, color?) {
    if (modalRef && modalRef.current) {
      if (data)
        modalRef.current.showModal({type: 'show', record: {orderType: platforms.productOrderType, data, list, color}})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  function showTableAccModal(e, data?) {
    if (accModalRef && accModalRef.current) {
      if (data) accModalRef.current.showModal({type: 'show', record: {data}})
      else accModalRef.current.showModal({type: 'add'})
    }
  }

  function showTableIteModal(e, data?) {
    if (iteModalRef && iteModalRef.current) {
      if (data)
        iteModalRef.current.showModal({
          type: 'show',
          record: {
            data: {
              ...data,
              salesProduct: products?.children?.find(({productSalesProduct: {id}}) => id === props.productId)
                ?.productSalesProduct,
              salesProductDetail: productDetails?.children?.find(
                ({productSalesProductDetail: {id}}) => id === props.productDetailId
              )?.productSalesProductDetail
            }
          }
        })
      else iteModalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  async function onFetchCart() {
    const {total} = await getAppCart()
    setCartTotal(total)
  }

  async function handleGetBrands() {
    setCategoryLoading(true)
    const {isITE, brands} = await getVendor()
    setIsITE(isITE as boolean)
    setBrands(brands)
    setCategoryLoading(false)
    return brands
  }

  async function handleGetOrderTypes(id) {
    setCategoryLoading(true)
    const data: any = await getCategory(id)
    setOrderTypes(data)
    setCategoryLoading(false)
    return data
  }

  async function handleGetPlatforms(id) {
    setCategoryLoading(true)
    const data: any = await getCategory(id)
    setPlatforms(data)
    setCategoryLoading(false)
    return data
  }

  async function handleGetProducts(id) {
    setCategoryLoading(true)
    const data: any = await getCategory(id)
    setProducts(data)
    setCategoryLoading(false)
    return data
  }

  async function handleGetProductDetails(id) {
    setCategoryLoading(true)
    const data: any = await getCategory(id)
    setProductDetails(data)
    setCategoryLoading(false)
    return data
  }

  async function handleSearch(val) {
    setLoading(true)
    if (val) {
      setSearch(val)
      const list = await getProducts()
      list.data = list.data.filter(({name}) => name.toLowerCase().indexOf(val.toLowerCase()) !== -1)
      setTableData(list.data)
    } else {
      setSearch(val)
      const params: any = {}
      if (props.brandId) params.brandId = props.brandId
      if (props.orderTypeId) params.orderTypeId = props.orderTypeId
      if (props.platformId > 0) params.salesPlatformId = props.platformId
      const list = await getProductList(params)
      setTableData(list.data)
    }
    setLoading(false)
  }

  async function handleGetList(lastId, orderTypeId = -1) {
    setLoading(true)
    if (lastId !== -2 && platforms?.productOrderType?.name === 'ITE') {
      const {data} = await getProductList({
        brandId: props.brandId,
        orderTypeId: props.orderTypeId,
        salesPlatformId: props.platformId,
        salesProductId: props.productId,
        salesProductDetailId: lastId
      })
      setTableData(data)
    } else {
      if (lastId === -2) {
        const {data} = await getProductList({
          brandId: props.brandId,
          orderTypeId: orderTypeId
        })
        setTableData(data)
      } else {
        const {data} = await getProductList({
          brandId: props.brandId,
          orderTypeId: props.orderTypeId,
          salesPlatformId: lastId
        })
        setTableData(data)
      }
    }
    setLoading(false)
  }

  function handleBackKey() {
    history.pushState(null, '', location.href)

    if (props.productDetailId !== -1) setProps((prev) => ({...prev, productDetailId: -1}))
    else if (props.productId !== -1) setProps((prev) => ({...prev, productId: -1}))
    else if (props.platformId > -1) setProps((prev) => ({...prev, platformId: -1}))
    else if (props.orderTypeId !== -1) setProps((prev) => ({...prev, orderTypeId: -1, platformId: -1}))
    else if (props.brandId !== -1 && brands.length !== 1) setProps((prev) => ({...prev, brandId: -1}))
  }

  useEffect(() => {
    history.pushState(null, '', location.href)
    window.addEventListener('popstate', handleBackKey)

    return () => {
      window.removeEventListener('popstate', handleBackKey)
    }
  }, [props])

  useEffect(() => {
    if (brands && brands.length === 1) {
      handleGetOrderTypes(brands[0].id)
      setProps((prev) => ({...prev, brandId: brands[0].id}))
    }
  }, [brands])

  useEffect(() => {
    onFetchCart()
    handleGetBrands()
  }, [])

  return (
    <>
      <SearchWrapper>
        <Input.Search
          placeholder="상품명으로 검색"
          style={{marginBottom: 20, width: 300}}
          enterButton
          onSearch={handleSearch}
        />
      </SearchWrapper>
      {!search && props.brandId === -1 ? (
        <BrandContainer>
          <SimpleTitle title="브랜드 선택" />
          {categoryLoading ? (
            <Skeleton />
          ) : (
            brands
              ?.sort((a, b) => +(a.seq > b.seq) || +(a.seq === b.seq) - 1)
              .map(
                (brand) =>
                  !brand?.hidden &&
                  brand && (
                    <Row>
                      <Col
                        key={brand.id}
                        onClick={() => {
                          handleGetOrderTypes(brand.id)
                          setProps((prev) => ({...prev, brandId: brand.id}))
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '2px 0 0 2px',
                            borderLeft: `15px solid ${
                              brand.name === 'Phonak'
                                ? '#88bc07'
                                : brand.name === 'Unitron'
                                ? '#00aeef'
                                : brand.name === 'Hansaton'
                                ? '#b9b9b9'
                                : process.env.REACT_APP_THEME_COLOR
                            }`
                          }}
                        >
                          <BrandButton style={{borderRadius: '0 2px 2px 0', borderLeft: 'none'}}>
                            <ImageView src={brand?.titleImage} width={230} height={110} />
                          </BrandButton>
                        </div>
                      </Col>
                    </Row>
                  )
              )
          )}
        </BrandContainer>
      ) : !search && props.orderTypeId === -1 ? (
        <BrandContainer>
          <SimpleTitle
            title="제품 유형 선택"
            subTitle={
              <Space>
                <BackButton>
                  {brands.length && (
                    <div className="icon" onClick={() => setProps((prev) => ({...prev, brandId: -1}))}>
                      <LeftOutlined />
                    </div>
                  )}
                  {categoryLoading ? (
                    <Spin />
                  ) : (
                    <Tag
                      name="브랜드"
                      onClick={() => setProps((prev) => ({...prev, brandId: -1}))}
                      value={orderTypes?.productBrand?.name}
                    />
                  )}
                </BackButton>
              </Space>
            }
          />
          <Row gutter={[15, 15]}>
            {categoryLoading ? (
              <Skeleton />
            ) : (
              orderTypes?.children
                ?.sort(
                  (a, b) =>
                    +(a.productOrderType?.seq > b.productOrderType?.seq) ||
                    +(a.productOrderType?.seq === b.productOrderType?.seq) - 1
                )
                .map((orderType) =>
                  orderType.productOrderType?.name !== 'ITE' ? (
                    orderType && (
                      <Col
                        key={orderType.id}
                        onClick={() => {
                          handleGetPlatforms(orderType.id)
                          if (
                            orderType.productOrderType.name !== 'ACC' &&
                            orderType.productOrderType.name !== 'Roger' &&
                            orderType.productOrderType.name !== 'DWA' &&
                            orderType.productOrderType.name !== 'CROS Tip' &&
                            orderType.productOrderType.name !== 'Dummy' &&
                            orderType.productOrderType.name !== 'SlimTip' &&
                            orderType.productOrderType.name !== 'cShell' &&
                            orderType.productOrderType.name !== 'Repair & Service' &&
                            orderType.productOrderType.name !== 'NA' &&
                            orderType.productOrderType.name !== 'Trials/Demos'
                          )
                            setProps((prev) => ({...prev, orderTypeId: orderType.productOrderType.id}))
                          else {
                            setProps((prev) => ({...prev, orderTypeId: orderType.productOrderType.id, platformId: -2}))
                            handleGetList(-2, orderType.productOrderType.id)
                          }
                        }}
                      >
                        <OrderTypeButton>
                          <div className="order-type-image">
                            <ImageView src={orderType.productOrderType?.titleImage} width={150} height={100} />
                          </div>
                          <Space>
                            <div>
                              <div className="order-type-name">{orderType.productOrderType?.name}</div>
                              {!!orderType.productOrderType?.korean && (
                                <div className="order-type-korean-name">{orderType.productOrderType?.korean}</div>
                              )}
                            </div>
                          </Space>
                        </OrderTypeButton>
                      </Col>
                    )
                  ) : isITE ? (
                    orderType && (
                      <Col
                        key={orderType.id}
                        onClick={() => {
                          handleGetPlatforms(orderType.id)
                          if (
                            orderType.productOrderType.name !== 'ACC' &&
                            orderType.productOrderType.name !== 'Roger' &&
                            orderType.productOrderType.name !== 'DWA' &&
                            orderType.productOrderType.name !== 'CROS Tip' &&
                            orderType.productOrderType.name !== 'Dummy' &&
                            orderType.productOrderType.name !== 'SlimTip' &&
                            orderType.productOrderType.name !== 'cShell' &&
                            orderType.productOrderType.name !== 'Repair & Service' &&
                            orderType.productOrderType.name !== 'NA' &&
                            orderType.productOrderType.name !== 'Trials/Demos'
                          )
                            setProps((prev) => ({...prev, orderTypeId: orderType.productOrderType.id}))
                          else {
                            setProps((prev) => ({...prev, orderTypeId: orderType.productOrderType.id, platformId: -2}))
                            handleGetList(-2, orderType.productOrderType.id)
                          }
                        }}
                      >
                        <OrderTypeButton>
                          <div className="order-type-image">
                            <ImageView src={orderType.productOrderType.titleImage} width={150} height={100} />
                          </div>
                          <Space>
                            <div>
                              <div className="order-type-name">{orderType.productOrderType.name}</div>
                              {!!orderType.productOrderType.korean && (
                                <div className="order-type-korean-name">{orderType.productOrderType.korean}</div>
                              )}
                            </div>
                          </Space>
                        </OrderTypeButton>
                      </Col>
                    )
                  ) : (
                    <></>
                  )
                )
            )}
          </Row>
        </BrandContainer>
      ) : !search && props.platformId === -1 ? (
        <BrandContainer>
          <SimpleTitle
            title="제품 플랫폼 선택"
            subTitle={
              <Space>
                <BackButton>
                  <div className="icon" onClick={() => setProps((prev) => ({...prev, orderTypeId: -1}))}>
                    <LeftOutlined />
                  </div>
                  <div
                    className="icon"
                    onClick={() =>
                      setProps((prev: any) => {
                        if (!!confirm('첫 선택 화면으로 돌아가시겠습니까?'))
                          return brands.length ? {...prev, brandId: -1, orderTypeId: -1} : {...prev, orderTypeId: -1}
                        else return prev
                      })
                    }
                  >
                    <HomeOutlined />
                  </div>
                  {categoryLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <Tag
                        name="브랜드"
                        value={orderTypes?.productBrand?.name}
                        onClick={() => setProps((prev) => ({...prev, orderTypeId: -1, brandId: -1}))}
                      />
                      <Tag
                        name="제품 유형"
                        onClick={() => setProps((prev) => ({...prev, orderTypeId: -1}))}
                        value={platforms?.productOrderType?.name}
                        korean={platforms?.productOrderType?.korean}
                      />
                    </>
                  )}
                </BackButton>
              </Space>
            }
          />
          <Row gutter={[15, 15]}>
            {categoryLoading ? (
              <Skeleton />
            ) : (
              platforms?.children
                ?.sort(
                  (a, b) =>
                    +(a.productSalesPlatform?.seq || 0 > b.productSalesPlatform?.seq || 0) ||
                    +(a.productSalesPlatform?.seq || 0 === b.productSalesPlatform?.seq || 0) - 1
                )
                .map(
                  (platform) =>
                    platform && (
                      <Col
                        key={platform.id}
                        onClick={async () => {
                          setProps((prev) => ({...prev, platformId: platform?.productSalesPlatform?.id}))
                          if (platforms?.productOrderType?.name === 'ITE') handleGetProducts(platform.id)
                          else await handleGetList(platform?.productSalesPlatform?.id)
                        }}
                      >
                        <OrderTypeButton>
                          <div className="order-type-name">
                            {platform?.productSalesPlatform?.displaySubName && platform?.productSalesPlatform?.subName
                              ? platform?.productSalesPlatform?.subName
                              : platform?.productSalesPlatform?.name}
                          </div>
                          {!!platform?.productSalesPlatform?.korean && (
                            <div className="order-type-korean-name">{platform?.productSalesPlatform?.korean}</div>
                          )}
                          <div className="order-type-image">
                            <ImageView src={platform?.productSalesPlatform?.titleImage} width={150} height={100} />
                          </div>
                        </OrderTypeButton>
                      </Col>
                    )
                )
            )}
          </Row>
        </BrandContainer>
      ) : platforms?.productOrderType?.name === 'ITE' && props.productId === -1 ? (
        <BrandContainer>
          <SimpleTitle
            title="판매 제품 선택"
            subTitle={
              <Space>
                <BackButton>
                  <div className="icon" onClick={() => setProps((prev) => ({...prev, platformId: -1}))}>
                    <LeftOutlined />
                  </div>
                  <div
                    className="icon"
                    onClick={() =>
                      setProps((prev: any) => {
                        if (confirm('첫 선택 화면으로 돌아가시겠습니까?'))
                          return brands.length
                            ? {...prev, brandId: -1, orderTypeId: -1, platformId: -1}
                            : {...prev, orderTypeId: -1, platformId: -1}
                        else return prev
                      })
                    }
                  >
                    <HomeOutlined />
                  </div>
                  {categoryLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <Tag
                        name="브랜드"
                        value={orderTypes?.productBrand?.name}
                        onClick={() =>
                          setProps((prev: any) => ({...prev, brandId: -1, orderTypeId: -1, platformId: -1}))
                        }
                      />
                      <Tag
                        name="제품 유형"
                        onClick={() => setProps((prev: any) => ({...prev, orderTypeId: -1, platformId: -1}))}
                        value={platforms?.productOrderType?.name}
                        korean={platforms?.productOrderType?.korean}
                      />
                      <Tag
                        name="플랫폼"
                        onClick={() => setProps((prev: any) => ({...prev, platformId: -1}))}
                        value={products?.productSalesPlatform?.name}
                        korean={products?.productSalesPlatform?.korean}
                      />
                    </>
                  )}
                </BackButton>
              </Space>
            }
          />
          <Row gutter={[15, 15]}>
            {categoryLoading ? (
              <Skeleton />
            ) : (
              products?.children?.map(
                (product) =>
                  product && (
                    <Col
                      key={product.id}
                      onClick={async () => {
                        setProps((prev) => ({...prev, productId: product?.productSalesProduct?.id}))
                        handleGetProductDetails(product?.id)
                      }}
                    >
                      <OrderTypeButton>
                        <div className="order-type-name">{product?.productSalesProduct?.name}</div>
                        {!!product?.productSalesProduct?.korean && (
                          <div className="order-type-korean-name">{product?.productSalesProduct?.korean}</div>
                        )}
                        <div className="order-type-image">
                          <ImageView src={product?.productSalesProduct?.titleImage} width={150} height={80} />
                        </div>
                      </OrderTypeButton>
                    </Col>
                  )
              )
            )}
          </Row>
        </BrandContainer>
      ) : platforms?.productOrderType?.name === 'ITE' && props.productDetailId === -1 ? (
        <BrandContainer>
          <SimpleTitle
            title="판매 제품 상세 선택"
            subTitle={
              <Space>
                <BackButton>
                  <div className="icon" onClick={() => setProps((prev) => ({...prev, productId: -1}))}>
                    <LeftOutlined />
                  </div>
                  <div
                    className="icon"
                    onClick={() =>
                      setProps((prev: any) => {
                        if (confirm('첫 선택 화면으로 돌아가시겠습니까?'))
                          return brands.length
                            ? {...prev, brandId: -1, orderTypeId: -1, platformId: -1, productId: -1}
                            : {...prev, orderTypeId: -1, platformId: -1, productId: -1}
                        else return prev
                      })
                    }
                  >
                    <HomeOutlined />
                  </div>
                  {categoryLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <Tag
                        name="브랜드"
                        value={orderTypes?.productBrand?.name}
                        onClick={() =>
                          setProps((prev: any) => ({
                            ...prev,
                            brandId: -1,
                            orderTypeId: -1,
                            platformId: -1,
                            productId: -1
                          }))
                        }
                      />
                      <Tag
                        name="제품 유형"
                        onClick={() =>
                          setProps((prev: any) => ({
                            ...prev,
                            orderTypeId: -1,
                            platformId: -1,
                            productId: -1
                          }))
                        }
                        value={platforms?.productOrderType?.name}
                        korean={platforms?.productOrderType?.korean}
                      />
                      <Tag
                        name="플랫폼"
                        onClick={() =>
                          setProps((prev: any) => ({
                            ...prev,
                            platformId: -1,
                            productId: -1
                          }))
                        }
                        value={products?.productSalesPlatform?.name}
                        korean={products?.productSalesPlatform?.korean}
                      />
                      <Tag
                        name="판매 제품"
                        onClick={() =>
                          setProps((prev: any) => ({
                            ...prev,
                            productId: -1
                          }))
                        }
                        value={productDetails?.productSalesProduct?.name}
                        korean={productDetails?.productSalesProduct?.korean}
                      />
                    </>
                  )}
                </BackButton>
              </Space>
            }
          />
          <Row gutter={[15, 15]}>
            {productDetails?.children?.map((productDetail) => {
              return (
                productDetail && (
                  <Col
                    key={productDetail.id}
                    onClick={async () => {
                      setProps((prev) => ({...prev, productDetailId: productDetail?.productSalesProductDetail?.id}))
                      await handleGetList(productDetail?.productSalesProductDetail?.id)
                    }}
                  >
                    <OrderTypeButton>
                      <div className="order-type-name">{productDetail?.productSalesProductDetail?.name}</div>
                      {!!productDetail?.productSalesProductDetail?.korean && (
                        <div className="order-type-korean-name">{productDetail?.productSalesProductDetail?.korean}</div>
                      )}
                      <div className="order-type-image">
                        <ImageView src={productDetail?.productSalesProductDetail?.titleImage} width={150} height={80} />
                      </div>
                    </OrderTypeButton>
                  </Col>
                )
              )
            })}
          </Row>
        </BrandContainer>
      ) : (
        <BrandContainer>
          {!search && (
            <Space>
              <BackButton>
                <div
                  className="icon"
                  onClick={() =>
                    props.platformId === -2
                      ? setProps((prev) => ({...prev, platformId: -1, orderTypeId: -1}))
                      : props.productDetailId !== -1
                      ? setProps((prev) => ({...prev, productDetailId: -1}))
                      : setProps((prev) => ({...prev, platformId: -1}))
                  }
                >
                  <LeftOutlined />
                </div>
                <div
                  className="icon"
                  onClick={() =>
                    setProps((prev: any) => {
                      if (confirm('첫 선택 화면으로 돌아가시겠습니까?'))
                        return brands.length
                          ? {...prev, brandId: -1, orderTypeId: -1, platformId: -1, productId: -1, productDetailId: -1}
                          : {...prev, orderTypeId: -1, platformId: -1, productId: -1, productDetailId: -1}
                      else return prev
                    })
                  }
                >
                  <HomeOutlined />
                </div>
                {loading ? (
                  <Spin />
                ) : (
                  <>
                    <Tag
                      name="브랜드"
                      value={orderTypes?.productBrand?.name}
                      onClick={() =>
                        setProps((prev: any) => ({
                          ...prev,
                          brandId: -1,
                          orderTypeId: -1,
                          platformId: -1,
                          productId: -1,
                          productDetailId: -1
                        }))
                      }
                    />
                    <Tag
                      name="제품 유형"
                      onClick={() =>
                        setProps((prev: any) => ({
                          ...prev,
                          orderTypeId: -1,
                          platformId: -1,
                          productId: -1,
                          productDetailId: -1
                        }))
                      }
                      value={platforms?.productOrderType?.name}
                      korean={platforms?.productOrderType?.korean}
                    />
                    {props.platformId !== -2 && (
                      <Tag
                        name="플랫폼"
                        onClick={() =>
                          setProps((prev: any) => ({
                            ...prev,
                            platformId: -1,
                            productId: -1,
                            productDetailId: -1
                          }))
                        }
                        value={
                          platforms?.children?.find(({productSalesPlatform: {id}}) => id === props.platformId)
                            ?.productSalesPlatform.name
                        }
                        korean={
                          platforms?.children?.find(({productSalesPlatform: {id}}) => id === props.platformId)
                            ?.productSalesPlatform.korean
                        }
                      />
                    )}
                    {props.productDetailId !== -1 && (
                      <>
                        <Tag
                          name="판매 제품"
                          onClick={() =>
                            setProps((prev: any) => ({
                              ...prev,
                              productId: -1,
                              productDetailId: -1
                            }))
                          }
                          value={
                            products?.children?.find(({productSalesProduct: {id}}) => id === props.productId)
                              ?.productSalesProduct.name
                          }
                          korean={
                            products?.children?.find(({productSalesProduct: {id}}) => id === props.productId)
                              ?.productSalesProduct.korean
                          }
                        />
                        <Tag
                          name="판매 제품 상세"
                          onClick={() =>
                            setProps((prev: any) => ({
                              ...prev,
                              productDetailId: -1
                            }))
                          }
                          value={
                            productDetails?.children?.find(
                              ({productSalesProductDetail: {id}}) => id === props.productDetailId
                            )?.productSalesProductDetail?.name
                          }
                          korean={
                            productDetails?.children?.find(
                              ({productSalesProductDetail: {id}}) => id === props.productDetailId
                            )?.productSalesProductDetail?.korean
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </BackButton>
            </Space>
          )}
          {loading ? (
            <Skeleton />
          ) : (
            <BasicList
              pagination={{pageSize: 52}}
              rowKey="id"
              loading={loading}
              dataSource={tableData?.filter(({status}) => status !== 'hidden')}
              total={tableData?.filter(({status}) => status !== 'hidden').length}
              brand={orderTypes?.productBrand?.name}
              orderType={platforms?.productOrderType?.korean || platforms?.productOrderType?.name}
              platform={
                platforms?.children?.find(({productSalesPlatform: {id}}) => id === props.platformId)
                  ?.productSalesPlatform.korean ||
                platforms?.children?.find(({productSalesPlatform: {id}}) => id === props.platformId)
                  ?.productSalesPlatform.name
              }
              salesProduct={
                products?.children?.find(({productSalesProduct: {id}}) => id === props.productId)?.productSalesProduct
                  ?.korean ||
                products?.children?.find(({productSalesProduct: {id}}) => id === props.productId)?.productSalesProduct
                  ?.name
              }
              salesProductDetail={
                productDetails?.children?.find(({productSalesProductDetail: {id}}) => id === props.productDetailId)
                  ?.productSalesProductDetail?.korean ||
                productDetails?.children?.find(({productSalesProductDetail: {id}}) => id === props.productDetailId)
                  ?.productSalesProductDetail?.name
              }
              onItemClick={async (e, record, rowIndex) => {
                setModalActions(['show'])
                if (
                  search &&
                  (record.info.orderType === 'ACC' ||
                    record.info.orderType === 'Roger' ||
                    record.info.orderType === 'DWA' ||
                    record.info.orderType === 'CROS Tip' ||
                    record.info.orderType === 'Dummy' ||
                    record.info.orderType === 'SlimTip' ||
                    record.info.orderType === 'cShell' ||
                    record.info.orderType === 'Repair & Service' ||
                    record.info.orderType === 'NA' ||
                    record.info.orderType === 'Trials/Demos')
                ) {
                  showTableAccModal(e, record)
                } else if (search && record.info.orderType === 'ITE') {
                  showTableIteModal(e, record)
                } else if (search) {
                  const {data: listData} = await getProducts()
                  for (const item of listData) {
                    const list = await getProducts({
                      finalValueId: item.id
                    })
                    if (list.data.find((item) => item.id === record.id)) {
                      showTableModal(e, record, rowIndex, list, record.color)
                      break
                    }
                  }
                } else if (
                  platforms?.productOrderType?.name === 'ACC' ||
                  platforms?.productOrderType?.name === 'Roger' ||
                  platforms?.productOrderType?.name === 'DWA' ||
                  platforms?.productOrderType?.name === 'CROS Tip' ||
                  platforms?.productOrderType?.name === 'Dummy' ||
                  platforms?.productOrderType?.name === 'SlimTip' ||
                  platforms?.productOrderType?.name === 'cShell' ||
                  platforms?.productOrderType?.name === 'Repair & Service' ||
                  platforms?.productOrderType?.name === 'NA' ||
                  platforms?.productOrderType?.name === 'Trials/Demos'
                ) {
                  try {
                    const res = await getProduct(record.id)
                    showTableAccModal(e, {
                      ...record,
                      ...res,
                      brand: orderTypes.productBrand,
                      orderType: platforms.productOrderType,
                      platform: products.productSalesPlatform
                    })
                  } catch (e) {}
                } else if (platforms?.productOrderType?.name === 'ITE') {
                  try {
                    const res = await getProduct(record.id)
                    showTableIteModal(e, {
                      ...record,
                      ...res,
                      brand: orderTypes.productBrand,
                      orderType: platforms.productOrderType,
                      platform: products.productSalesPlatform
                    })
                  } catch (e) {}
                } else {
                  const params: any = {
                    finalValueId: record.id,
                    brandId: props.brandId,
                    orderTypeId: props.orderTypeId,
                    salesPlatformId: props.platformId
                  }
                  const list = await getProducts(params)
                  showTableModal(
                    e,
                    {
                      ...record,
                      ...list.data[0]
                    },
                    rowIndex,
                    list
                  )
                }
              }}
            />
          )}
        </BrandContainer>
      )}
      <ProductModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
      <AccProductModal actions={modalActions} ref={accModalRef} onAction={onModalAction} />
      <IteProductModal actions={modalActions} ref={iteModalRef} onAction={onModalAction} />
    </>
  )
}

const SearchWrapper = styled.div`
  margin-bottom: 20px;
`

const BrandContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`

const BrandButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 120px;
  overflow: hidden;
`

const OrderTypeButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  padding: 20px;
  overflow: hidden;

  &:hover {
    .order-type-name {
      color: ${process.env.REACT_APP_THEME_COLOR};
      transition: all 0.3s;
    }
  }

  &:not(:hover) {
    .order-type-name {
      color: #464646;
      transition: all 0.3s;
    }
  }

  .order-type-name {
    position: absolute;
    top: 5px;
    left: 10px;
    font-weight: 400;
    font-size: 22px;
  }

  .order-type-code {
    background: ${process.env.REACT_APP_THEME_COLOR};
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: 400;
    font-size: 17px;
    color: white;
  }

  .order-type-korean-name {
    position: absolute;
    top: 32px;
    left: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .order-type-image {
    position: absolute;
    top: 75px;
    right: 5px;
  }
`

const BackButton = styled(Space)`
  margin-bottom: 10px;
  color: ${process.env.REACT_APP_THEME_COLOR};
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: ${process.env.REACT_APP_THEME_COLOR};
    box-shadow: rgba(29, 35, 41, 0.1) 2px 0px 8px 0px;
    color: white;
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      filter: brightness(110%);
      transition: all 0.3s;
    }

    &:not(:hover) {
      filter: brightness(100%);
      transition: all 0.3s;
    }
  }

  font-size: 18px;
`

export default withPageContext(Products)
