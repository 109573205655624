import api from './index'
import {IProductBrand} from './productBrands'

export interface IVendor {
  id?: number
  name: string
  zipcode: string
  priceGroupId: number
  code: string
  largeCategory: string
  mediumCategory: string
  smallCategory: string
  manager: string
  enabled: boolean
  memo: string
  accountId: string
  address: string
  tel: string
  phone: string
  group: {id: number; name: string}
  priceGroup: {
    id: number
    name: string
    createdAt: string
    updatedAt: string
  }
  agreeMarketing: boolean
  agreeService: boolean
  privacyPolicy: boolean
  status: 'registered' | 'confirmed'
  createdAt: string
  updatedAt: string
  error?: string
  isITE?: boolean
  brands?: IProductBrand
  isFirstLogin?: boolean
  coupons: any[]
}

export interface IPatchVendors
  extends Omit<
    IVendor,
    'memo' | 'accountId' | 'group' | 'priceGroup' | 'status' | 'createdAt' | 'updatedAt' | 'vendorSetting'
  > {
  setting?: {
    agreeMarketing: boolean
    agreeService: boolean
    privacyPolicy: boolean
  }
}

export async function getVendor(): Promise<IVendor> {
  try {
    const res = await api.get(`/vendor`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchVendor(data: any): Promise<{id: number}> {
  try {
    const res = await api.patch(`/vendor`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteVendor(id: number): Promise<void> {
  try {
    const res = await api.delete(`/vendor`)
    return res.data
  } catch (e) {
    throw e
  }
}
