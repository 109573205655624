import React from 'react'
import {BrowserRouter, useRoutes} from 'react-router-dom'
import {authRoute} from './auth.route'
import {resetRoute} from './resetPassword.route'
import {errorRoute} from './error.route'
import {indexRoute} from './index.route'
import {signUpRoute} from './signup.route'
import withInitialize from '../hocs/withInitialize'
import BasicLayout from '../layouts/BasicLayout'
import {
  cartRoute,
  dataItemRoute,
  dataRoute,
  noticeRoute,
  orderRoute,
  productRoute,
  sapRoute,
  vendorRoute
} from './non-group.route'

export const routes = [
  ...authRoute,
  ...resetRoute,
  ...indexRoute,
  ...signUpRoute,
  {
    path: '/console',
    element: <BasicLayout />,
    children: [vendorRoute, noticeRoute, dataRoute, dataItemRoute, cartRoute, productRoute, orderRoute, sapRoute]
  },
  ...errorRoute
]

function Routes() {
  return useRoutes(routes)
}

function DefaultRoutes() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  )
}

export default withInitialize(DefaultRoutes)
