import React, {forwardRef, RefObject} from 'react'
import {Divider, Form, Space, Tag, Typography} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {INotice} from '../../../../api/notices'
import dayjsKorean from '../../../../libs/DayjsKorean'
import styled from 'styled-components'
import {ClockCircleOutlined, PushpinOutlined} from '@ant-design/icons'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<INotice>
  onAction: (type: ActionType, record: Partial<INotice>) => void | Promise<void>
}

function Show({record}: {record: INotice}) {
  return (
    <div style={{padding: '0 28px 28px 28px', minHeight: 300}}>
      <div>
        <Space>
          {record.isFixation ? (
            <StyledTag icon={<PushpinOutlined />} color="#d54d43">
              중요공지
            </StyledTag>
          ) : (
            <StyledTag color={process.env.REACT_APP_THEME_COLOR}>일반공지</StyledTag>
          )}
          <Typography.Title level={4}>{record.title}</Typography.Title>
        </Space>
      </div>
      <Typography.Text style={{color: 'gray', fontSize: 12}}>
        <ClockCircleOutlined /> {`${dayjsKorean(record.createdAt).format('YYYY/MM/DD')}`}
      </Typography.Text>
      <Divider style={{margin: '20px 0'}} />
      <HtmlContainer dangerouslySetInnerHTML={{__html: record.content}} />
    </div>
  )
}

const NoticeModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '공지사항', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={800}
      onAction={onAction}
      render={(type, record) => (
        <Form form={form}>
          <Show record={record} />
        </Form>
      )}
    />
  )
})

const StyledTag = styled(Tag)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  font-weight: 500;
  margin-right: 0;
  margin-bottom: 0.7em;
`

const HtmlContainer = styled.div`
  img {
    max-width: 480px;
  }
`

export default NoticeModal
