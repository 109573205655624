import api from './index'

interface IBanner {
  id: number
  name: string
  titleImage: string
  mobileTitleImage: string
  order: number
  createdAt: string
  updatedAt: string
}

interface IGetBannerParams {
  start: number
  perPage: number
}

export async function getBanners(
  params: IGetBannerParams = {start: 0, perPage: 1000}
): Promise<{data: IBanner[]; total: number}> {
  try {
    const res = await api.get(`/banners`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getBanner(id): Promise<IBanner> {
  try {
    const res = await api.get(`/banner/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
