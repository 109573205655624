import api from './index'

export interface IData {
  id: number
  title: string
  files: string[]
  content: string
  isFixation: boolean
  createdAt: string
  updatedAt: string
}

interface IGetDataParams {
  start: number
  perPage: number
}

export async function getDatas(
  params: IGetDataParams = {start: 0, perPage: 1000}
): Promise<{data: IData[]; total: number}> {
  try {
    const res = await api.get(`/datas`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getData(id: number): Promise<IData> {
  try {
    const res = await api.get(`/datas/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
