import React, {ReactNode} from 'react'
import {Card, Input, List, ListProps, Space} from 'antd'
import {useLocation, useNavigate} from 'react-router-dom'
import qs from 'query-string'
import ImageView from '../ImageView'
import Meta from 'antd/lib/card/Meta'
import SimpleTitle4List from './SimpleTitle4List'
import styled from 'styled-components'
import {getProduct} from '../../api/products'

interface IBasicList<T> extends ListProps<T> {
  showSearch?: boolean
  searchPlaceHolder?: string
  searchKey?: string
  total?: number
  topButtons?: ReactNode[]
  brand?: string
  orderType?: string
  platform?: string
  salesProduct?: string
  salesProductDetail?: string
  onItemClick?: any
}

export default function BasicList<T extends object = any>({
  pagination,
  showSearch,
  searchKey,
  searchPlaceHolder,
  topButtons,
  total,
  brand,
  orderType,
  platform,
  salesProduct,
  salesProductDetail,
  onItemClick,
  ...props
}: IBasicList<T>) {
  const location = useLocation()
  const navigate = useNavigate()
  const query: any = qs.parse(location.search) as Record<string, string>

  function onChangeTable(page: number) {
    const search: Record<string, any> = {
      ...query,
      page: page
    }
    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  function onChangeSearch(text?: string) {
    const search = {...query, page: 0}
    if (searchKey) {
      if (text) search[searchKey] = text
      else delete search[searchKey]
    } else {
      if (text) search.search = text
      else delete search.search
    }

    navigate({
      pathname: location.pathname,
      search: qs.stringify(search)
    })
  }

  return (
    <>
      {(showSearch || topButtons) && (
        <div
          style={{
            float: 'right',
            textAlign: 'right',
            marginBottom: 12
          }}
        >
          <Space>
            {showSearch && (
              <Input.Search
                placeholder={searchPlaceHolder || '검색'}
                style={{width: 300}}
                enterButton
                onSearch={onChangeSearch}
              />
            )}
            {topButtons}
          </Space>
        </div>
      )}
      <List<T>
        {...props}
        grid={{gutter: 25, column: 4}}
        pagination={
          pagination || {
            onChange: onChangeTable,
            showSizeChanger: true,
            current: parseInt(query.page, 10),
            pageSize: parseInt(query.pageSize, 10),
            total
          }
        }
        renderItem={(item: any, index: number) => (
          <List.Item>
            <Container
              onClick={async (e) => {
                if (orderType !== 'ACC' && orderType !== 'Roger' && orderType !== 'DWA') onItemClick(e, item, index)
                else {
                  const res = await getProduct(item.id)
                  const record = res ? {...res, ...item} : item
                  onItemClick(e, record, index)
                }
              }}
              cover={
                <div style={{overflow: 'hidden'}}>
                  <ImageView src={item.titleImage} width={285} height={285} />
                </div>
              }
            >
              <Meta
                title={
                  <SimpleTitle4List
                    title={
                      <div>
                        <div>{item.name}</div>
                        <div style={{fontSize: 12, paddingBottom: 3}}>{item.korean}</div>
                      </div>
                    }
                    subTitle={`#${brand} #${orderType} ${platform ? `#${platform}` : ''} ${
                      salesProduct ? `#${salesProduct}` : ''
                    } ${salesProductDetail ? `#${salesProductDetail}` : ''}`}
                  />
                }
                description={
                  <div>
                    <div>{item.comment}</div>
                    <div style={{fontSize: 12, paddingBottom: 3}}>{item.summary}</div>
                  </div>
                }
              />
            </Container>
          </List.Item>
        )}
      />
    </>
  )
}

const Container = styled(Card)`
  margin-bottom: 50px;
  border: none;

  .ant-card-body {
    padding: 15px 0 0 0;
  }

  &:hover {
    h4.ant-typography {
      transition: all 0.5s;
      color: ${process.env.REACT_APP_THEME_COLOR};
    }

    cursor: pointer;
  }

  &:not(:hover) {
    h4.ant-typography {
      transition: all 0.5s;
      color: #232323;
    }

    cursor: pointer;
  }
`
