import api from './index'

export interface IAuth {
  id: number
  name: string
  nickname?: string
  profileUrl?: string
}

export interface IPostAuthRegister {
  vendor: {
    name: string
    phone: string
    password: string
    accountId: string
  }
  token: string
}

export interface IPostResetPassword {
  newPassword: string
  phone: string
  phoneToken: string
}

export async function postAuth(accountId: string, password: string, remember: boolean) {
  try {
    const {data} = await api.post(`/auth`, {accountId, password, remember})
    return data
  } catch (e) {
    throw e
  }
}

export async function postAuthRegister(reqData: IPostAuthRegister) {
  try {
    const {data} = await api.post(`/auth/register`, reqData)
    return data
  } catch (e) {
    throw e
  }
}

export async function getAuth(): Promise<IAuth> {
  const {data} = await api.get('/auth')
  return data
}

export async function deleteAuth(): Promise<void> {
  await api.delete('/auth')
}

export async function patchResetPassword(data: IPostResetPassword): Promise<void> {
  await api.patch('/auth/resetPassword', data)
}
