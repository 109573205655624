import {Typography} from 'antd'
import {ReactNode} from 'react'
import styled from 'styled-components'

interface IProp {
  title: ReactNode | string
  subTitle?: ReactNode | string
}

export default function SimpleTitle4List({title, subTitle = ''}: IProp) {
  return (
    <Container>
      <SubTitle>{subTitle}</SubTitle>
      <Title level={4}>{title}</Title>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: -15px;
`

const Title = styled(Typography.Title)`
  white-space: normal;
`

const SubTitle = styled(Typography)`
  margin-bottom: -25px;
  font-size: 13px;
  color: ${process.env.REACT_APP_THEME_COLOR};
  white-space: normal;
`
