import {Modal} from 'antd'
import axios from 'axios'

let invalidSessionCnt = false

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 40000,
  headers: {
    'Cache-Control': 'no-store',
    Pragma: 'no-store',
    Expires: '0'
  }
})

if (process.env.NODE_ENV !== 'production') {
  instance.interceptors.request.use(
    (config) => {
      const {url, method, params, data} = config
      // eslint-disable-next-line no-console
      console.log(`[Request]: ${JSON.stringify({url, params, method, data}, null, 2)}`)
      return config
    },
    (error) => Promise.reject(error)
  )
  instance.interceptors.response.use((response) => {
    // if (response.data.products) {
    //   response.data.products.forEach(({productInfo}, index) => {
    //     if (productInfo.grade) {
    //       console.log(productInfo)
    //       response.data.products[index].productInfo.grade1 = +productInfo.grade
    //       console.log(response.data.products[index].productInfo)
    //     }
    //   })
    // }
    // eslint-disable-next-line no-console
    //console.log(`[Response Data]: ${JSON.stringify(response.data, null, 2)}`)
    console.log(response)
    return response
  }, errorHandler)
} else {
  instance.interceptors.response.use((response) => response, errorHandler)
}

async function errorHandler(e: any) {
  // eslint-disable-next-line no-console
  console.log(`[Error Data]: ${JSON.stringify(e.response.data, null, 2)}`)
  try {
    if (e.response.status === 401 && e.response.data.message === 'invalid_session' && !invalidSessionCnt) {
      Modal.error({content: '쿠키를 모두 지운 뒤 새로고침 해주세요.'})
      invalidSessionCnt = true
    } else if (e.response.status === 403 && e.response.data.message === 'forbidden') {
      window.open('/auth', '_self')
    }
  } catch (e) {}
  throw e
}

export default instance
