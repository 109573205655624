import {IFinalValue} from './finalValues'
import api from './index'

export interface ICommon {
  id: number
  name: string
  korean?: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface ICommonWithTitleImage extends ICommon {
  titleImage: string
}

export interface IProduct {
  id: number
  name: string
  korean: string
  titleImage: string
  products: {id: number}[]
  info: {
    brand: ICommon
    status: string
    orderType: ICommon
    finalValue: ICommon
    infoColors: string[]
    isColorChange: boolean
    salesPlatform: ICommon
    batteryType: string
  }
  colors: ICommon[]
  grades: ICommon[]
  colorChanges: ICommon[]
}

interface IGetProductParams {
  start?: number
  perPage?: number
  search?: string
  brandId?: number
  orderTypeId?: number
  salesPlatformId?: number
  finalValueId?: number
  gradeId?: number
  colorId?: number
  salesProductId?: number
  salesProductDetailId?: number
}

interface IGetProductListParams {
  search?: string
  brandId?: number
  orderTypeId?: number
  salesPlatformId?: number
  salesProductId?: number
  salesProductDetailId?: number
}

export async function getProducts(
  params: IGetProductParams = {start: 0, perPage: 1000}
): Promise<{data: IProduct[]; total: number}> {
  try {
    const res = await api.get(`/products`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProductList(params?: IGetProductListParams): Promise<{data: IFinalValue[]}> {
  try {
    const res = await api.get('/products/list', {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getProduct(id: number): Promise<any> {
  try {
    const res = await api.get(`/products/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
