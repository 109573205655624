/* eslint-disable security/detect-unsafe-regex */
import React, {forwardRef, RefObject, useContext, useEffect, useRef, useState} from 'react'
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Typography
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {getProduct, IProduct} from '../../../../api/products'
import SimpleTitle from '../../../../components/common/SimpleTitle'
import styled, {css} from 'styled-components'
import {getAppCart, postCartIte, putCartIte} from '../../../../api/cart'
import ImageView from '../../../../components/ImageView'
import OrderModal from '../../cart/components/orderModal'
import PageContext from '../../../../contexts/PageContext'
import {getOrderSheet, postOrderSheet} from '../../../../api/orderSheets'
import {CheckCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {useNavigate} from 'react-router'
import {useRecoilValue} from 'recoil'
import {userState} from '../../../../states/user.state'
import {useWatch} from 'antd/es/form/Form'
import FileUpload from '../../../../components/FileUpload'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

function Description({record}: {record: any}) {
  return (
    <Row gutter={25}>
      <Col>
        <ImageView src={record?.titleImage} width={409} height={409} />
      </Col>
      <Col>
        <SimpleTitle title={record.name} subTitle={record?.brand?.name} />
        <Descriptions style={{width: '400px'}} size="small" column={1} bordered title="상품 정보">
          <Descriptions.Item span={1} label="브랜드">
            {record?.brand?.name || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="제품 유형">
            {(record?.orderType?.korean && `${record?.orderType?.name}(${record?.orderType?.korean})`) ||
              record?.orderType?.name ||
              '-'}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="제품 플랫폼">
            {(record?.platform?.korean && `${record?.platform?.name}(${record?.platform?.korean})`) ||
              record?.platform?.name ||
              '-'}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="판매 제품">
            {record?.salesProduct?.name || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="판매 제품 상세">
            {record?.salesProductDetail?.name || '-'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function Show({record: {data: recordRaw}}: {record: any}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const orderModalRef = useRef<BasicTableModalRef>(null)
  const {state, apiHandler} = useContext(PageContext)
  const [record, setRecord] = useState<any>([])
  const [dir, setDir] = useState<any>('')
  const [optLoading, setOptLoading] = useState<boolean>(false)
  const [iteOpts, setIteOpts] = useState<any>({})
  const navigator = useNavigate()
  const user: any = useRecoilValue(userState)
  const leftBent = useWatch(['left', 'bentId'], form)
  const rightBent = useWatch(['right', 'bentId'], form)
  const quantity = useWatch('quantity', form)

  useEffect(() => {
    if (iteOpts?.productBents?.find(({id}) => id === rightBent)?.name === '벤트없음')
      form.setFieldValue(['right', 'bentStyleId'], null)
    if (iteOpts?.productBents?.find(({id}) => id === leftBent)?.name === '벤트없음')
      form.setFieldValue(['left', 'bentStyleId'], null)
    form.setFieldValue('bentStyleId', null)
  }, [leftBent, rightBent])

  async function handleFinish(values: any, isDirectBuy?: boolean) {
    if (!values.name) {
      Modal.error({content: '착용자명은 필수값입니다.'})
      return
    }

    if (!values.earImpressionFiles || !values.earImpressionFiles.length) {
      Modal.error({content: '귓본파일 업로드는 필수값입니다.'})
      return
    }

    if (
      (values.direction !== 'left' &&
        ((iteOpts?.productBents?.find(({id}) => id === rightBent)?.name !== '벤트없음' && !values.right.bentStyleId) ||
          !values.right.programButtonId)) ||
      (values.direction !== 'right' &&
        ((iteOpts?.productBents?.find(({id}) => id === leftBent)?.name !== '벤트없음' && !values.left.bentStyleId) ||
          !values.left.programButtonId))
    ) {
      Modal.error({content: '선택하신 방향의 ITE 옵션을 모두 입력해주세요.'})
      return
    }

    let selectedId: number = 0

    values.left = {
      ...values.left,
      audiograms: values.left.audiograms
        ? values.left.audiograms.map((audiograms, index) => ({
            ...iteOpts.productAudiograms[index],
            value: audiograms || 0
          }))
        : null
    }
    values.right = {
      ...values.right,
      audiograms: values.right.audiograms
        ? values.right.audiograms.map((audiograms, index) => ({
            ...iteOpts.productAudiograms[index],
            value: audiograms || 0
          }))
        : null
    }

    if (!values.left.volumeController) values.left.volumeController = false
    if (!values.left.programButton) values.left.programButton = false

    if (!values.right.volumeController) values.right.volumeController = false
    if (!values.right.programButton) values.right.programButton = false

    if (values.direction === 'right') delete values.left
    if (values.direction === 'left') delete values.right

    Object.keys(values).forEach((key) => {
      if (!values[key] && key !== 'name') values[key] = record[key]
    })

    if (!values.name) delete values.name

    setLoading(true)
    try {
      if (!values.quantity) values.quantity = 1
      const res = await postCartIte(values)
      !isDirectBuy &&
        Modal.confirm({
          icon: <CheckCircleOutlined style={{color: '#52c41a'}} />,
          title: '장바구니에 추가되었습니다.',
          cancelText: '장바구니로 바로가기',
          okText: '계속 쇼핑하기',
          onCancel: () => navigator('../cart')
        })
      selectedId = res.id
    } catch (e: any) {
      if (e.response.status === 409) {
        if (e.response.data.message === 'quantity_is_not_enough') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else if (e.response.data.message === 'lessThan_safetyQuantity') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else {
          if (isDirectBuy) {
            const {data} = await getAppCart()
            const duplicatedCartItem = data.find((cartItem) => {
              if (cartItem.product && cartItem.product.id !== record?.id) return false
              return true
            })
            if (duplicatedCartItem?.id) {
              selectedId = duplicatedCartItem?.id
              try {
                await putCartIte(selectedId, values.quantity)
              } catch {}
            } else Modal.error({title: '장바구니 데이터를 찾을 수 없음', content: '잠시 후 다시 시도해주세요'})
          } else {
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              content: '이미 장바구니에 존재하는 상품입니다.',
              cancelText: '장바구니로 바로가기',
              okText: '계속 쇼핑하기',
              onCancel: () => navigator('../cart')
            })
          }
        }
      } else if (e.response) {
        if (e.response.status === 400) Modal.error({content: '선택하신 방향의 ITE 옵션을 모두 입력해주세요.'})
        else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
    } finally {
      setLoading(false)
      return selectedId
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  async function showOrderModal() {
    if (orderModalRef && orderModalRef.current) {
      const cartId = await handleFinish(form.getFieldsValue(), true)
      const {id} = await postOrderSheet({carts: [{id: cartId as any, quantity}]})
      const record = await getOrderSheet(id)
      orderModalRef.current.showModal({type: 'show', record: {...record, id}})
    }
  }

  useEffect(() => {
    if (dir) {
      ;(async function () {
        setOptLoading(true)
        const res: any = await getProduct(record.id)
        setIteOpts(res.iteOption)
        form.setFieldsValue({productId: record.id})
        setOptLoading(false)
      })()
    }
  }, [dir])

  useEffect(() => {
    ;(async function () {
      setRecord({...recordRaw, originPrice: user.isPricePublic ? recordRaw.originPrice : -1})
    })()
  }, [])

  useEffect(() => {
    form.setFieldValue('quantity', !record?.onSale ? 0 : 1)
  }, [record, form])

  return (
    <>
      <Space direction="vertical" style={{width: '100%', padding: '0 28px 28px 28px'}}>
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Description record={record} />
          {record.comment && (
            <Card size="small" title="상품 코멘트" style={{marginTop: 20}}>
              {record.comment}
            </Card>
          )}
          {(record.summary || record.description) && (
            <Card size="small" title="상품요약 및 설명" style={{marginTop: 20}}>
              <Typography.Title level={5}>{record.summary}</Typography.Title>
              <p>{record.description}</p>
            </Card>
          )}
          {user.isPricePublic && (
            <Col span={24} style={{margin: '20px 0'}}>
              {typeof record.originPrice === 'number' && record.originPrice !== -1 && (
                <Card>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>본 상품의 가격은 다음과 같습니다.</div>
                    <Space>
                      <div style={{color: process.env.REACT_APP_THEME_COLOR, fontSize: 20, fontWeight: 'bold'}}>
                        {record.originPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </div>
                      원
                    </Space>
                  </div>
                </Card>
              )}
            </Col>
          )}
          <Form.Item name="productId" hidden />
          <Row gutter={30}>
            <Col span={4}>
              <Form.Item name="name" label="착용자명">
                <Input />
              </Form.Item>
            </Col>
            <Col span={16}>
              <StyledFormItem name="direction" label="방향">
                <StyledRadioGroup
                  onChange={(e) => {
                    setDir(e.target.value)
                  }}
                >
                  <Radio.Button value="left">좌측</Radio.Button>
                  <Radio.Button value="all">모두</Radio.Button>
                  <Radio.Button value="right">우측</Radio.Button>
                </StyledRadioGroup>
              </StyledFormItem>
            </Col>
            <Col span={4}>
              <Form.Item name="isBarcode" label="바코드 신청" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24} style={{marginBottom: 20}}>
              <Row gutter={24}>
                <Col span={12}>
                  <IteOptsContainer title="좌측 옵션" size="small" enabled={dir && dir !== 'right'}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item label="쉘 타입" name={['left', 'shellTypeId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productShellTypes?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="쉘 색상" name={['left', 'shellColorId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productShellColors?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="쉘 사이즈" name={['left', 'shellSizeId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productShellSizes?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="플레이트" name={['left', 'plateId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productPlates?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="출력" name={['left', 'outputId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productOutputs?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="벤트" name={['left', 'bentId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productBents?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="벤트 스타일" name={['left', 'bentStyleId']}>
                          <Select
                            disabled={
                              !dir ||
                              dir === 'right' ||
                              !leftBent ||
                              iteOpts?.productBents?.find(({id}) => id === leftBent)?.name === '벤트없음'
                            }
                          >
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productBentStyles?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="손잡이" name={['left', 'handleId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productHandles?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="귀지 방지 옵션" name={['left', 'earwaxOptionId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productEarwaxOptions?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="코팅" name={['left', 'coatingId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productCoatings?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <Space>
                              카날 길이
                              <Popover
                                content={
                                  <div style={{width: 470}}>
                                    <ul style={{paddingLeft: 10}}>
                                      <li>
                                        <Typography.Title level={5}>
                                          canal길이는 귓본 채취 시 2커브굴곡에 따라 선택바랍니다.
                                        </Typography.Title>
                                      </li>
                                      <ul style={{paddingLeft: 10}}>
                                        <li>canal 길게 : 2커브에서 2~3mm 이상까지 0 °~30 °의 완만한 굴곡일 때 선택</li>
                                        <li>canal 중간 : 2커브에서 2~3mm까지 30 °~60 °의 적당한 굴곡일 때 선택</li>
                                        <li>canal 짧게 : 2커브이전에 60 °~90 °의 심한 굴곡일 때 선택</li>
                                      </ul>
                                      <br />
                                      <li>
                                        <Typography.Title level={5}>
                                          위 선택사항은 귓본채취가 잘 되었다는 조건하에 선택되어야 하며, <br />
                                          제작자 판단 시 귓본모양에 맞게 옵션이 변경될 수 있음을 안내드립니다.
                                        </Typography.Title>
                                      </li>
                                    </ul>
                                  </div>
                                }
                              >
                                <Button shape="circle" size="small">
                                  ?
                                </Button>
                              </Popover>
                            </Space>
                          }
                          name={['left', 'canalLengthId']}
                        >
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productCanalLengths?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="무선" name={['left', 'wirelessId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productWireless?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="청력도" name={['left', 'audiograms']}>
                          {optLoading ? (
                            <Spin />
                          ) : iteOpts?.productAudiograms ? (
                            <Descriptions bordered size="small" column={2}>
                              {iteOpts?.productAudiograms?.map((audiogram, index) => (
                                <Descriptions.Item label={audiogram.name}>
                                  <Form.Item name={['left', 'audiograms', index]}>
                                    <InputNumber min={0} defaultValue={0} disabled={!dir || dir === 'right'} />
                                  </Form.Item>
                                </Descriptions.Item>
                              ))}
                            </Descriptions>
                          ) : (
                            <Input disabled />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="볼륨 조절기" name={['left', 'volumeControllerId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productVolumeControllers?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="프로그램 버튼" name={['left', 'programButtonId']}>
                          <Select disabled={!dir || dir === 'right'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productProgramButtons?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </IteOptsContainer>
                </Col>
                <Col span={12}>
                  <IteOptsContainer title="우측 옵션" size="small" enabled={dir && dir !== 'left'}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Form.Item label="쉘 타입" name={['right', 'shellTypeId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productShellTypes?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="쉘 색상" name={['right', 'shellColorId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productShellColors?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="쉘 사이즈" name={['right', 'shellSizeId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productShellSizes?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="플레이트" name={['right', 'plateId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productPlates?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="출력" name={['right', 'outputId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productOutputs?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="벤트" name={['right', 'bentId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productBents?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="벤트 스타일" name={['right', 'bentStyleId']}>
                          <Select
                            disabled={
                              !dir ||
                              dir === 'left' ||
                              !rightBent ||
                              iteOpts?.productBents?.find(({id}) => id === rightBent)?.name === '벤트없음'
                            }
                          >
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productBentStyles?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="손잡이" name={['right', 'handleId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productHandles?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="귀지 방지 옵션" name={['right', 'earwaxOptionId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productEarwaxOptions?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="코팅" name={['right', 'coatingId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productCoatings?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label={
                            <Space>
                              카날 길이
                              <Popover
                                content={
                                  <div style={{width: 470}}>
                                    <ul style={{paddingLeft: 10}}>
                                      <li>
                                        <Typography.Title level={5}>
                                          canal길이는 귓본 채취 시 2커브굴곡에 따라 선택바랍니다.
                                        </Typography.Title>
                                      </li>
                                      <ul style={{paddingLeft: 10}}>
                                        <li>canal 길게 : 2커브에서 2~3mm 이상까지 0 °~30 °의 완만한 굴곡일 때 선택</li>
                                        <li>canal 중간 : 2커브에서 2~3mm까지 30 °~60 °의 적당한 굴곡일 때 선택</li>
                                        <li>canal 짧게 : 2커브이전에 60 °~90 °의 심한 굴곡일 때 선택</li>
                                      </ul>
                                      <br />
                                      <li>
                                        <Typography.Title level={5}>
                                          위 선택사항은 귓본채취가 잘 되었다는 조건하에 선택되어야 하며, <br />
                                          제작자 판단 시 귓본모양에 맞게 옵션이 변경될 수 있음을 안내드립니다.
                                        </Typography.Title>
                                      </li>
                                    </ul>
                                  </div>
                                }
                              >
                                <Button shape="circle" size="small">
                                  ?
                                </Button>
                              </Popover>
                            </Space>
                          }
                          name={['right', 'canalLengthId']}
                        >
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productCanalLengths?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="무선" name={['right', 'wirelessId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productWireless?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="청력도" name={['right', 'audiograms']}>
                          {optLoading ? (
                            <Spin />
                          ) : iteOpts?.productAudiograms ? (
                            <Descriptions bordered size="small" column={2}>
                              {iteOpts?.productAudiograms?.map((audiogram, index) => (
                                <Descriptions.Item label={audiogram.name}>
                                  <Form.Item name={['right', 'audiograms', index]}>
                                    <InputNumber min={0} defaultValue={0} disabled={!dir || dir === 'left'} />
                                  </Form.Item>
                                </Descriptions.Item>
                              ))}
                            </Descriptions>
                          ) : (
                            <Input disabled />
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="볼륨 조절기" name={['right', 'volumeControllerId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productVolumeControllers?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label="프로그램 버튼" name={['right', 'programButtonId']}>
                          <Select disabled={!dir || dir === 'left'}>
                            {optLoading ? (
                              <Spin />
                            ) : (
                              iteOpts?.productProgramButtons?.map((ite) => (
                                <Select.Option value={ite.id}>{ite.name}</Select.Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </IteOptsContainer>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={30} style={{marginBottom: 20}}>
            <Col span={6}>
              <Form.Item name="earImpressionFiles_temp" hidden />
              <Form.Item name="addFiles_temp" hidden />
              <Form.Item label="귓본파일" name="earImpressionFiles" required>
                <FileUpload form={form} kind="etc" multiple type="array" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="제작참고사진" name="addFiles">
                <FileUpload form={form} kind="etc" multiple type="array" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="memo"
                label="제작요청사항"
                rules={[
                  {
                    pattern: /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣.,\-/() ]*$/,
                    message: '특수문자는 . , - / ( ) 만 입력 가능합니다'
                  }
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <StyledSpace>
            <Form.Item>
              <Space>
                <Button
                  size="large"
                  shape="round"
                  type="primary"
                  onClick={() => handleFinish(form.getFieldsValue())}
                  loading={loading}
                  disabled={!record.onSale}
                >
                  장바구니 (계속구매)
                </Button>
                <Button
                  size="large"
                  shape="round"
                  onClick={(e) => {
                    e.preventDefault()
                    setModalActions(['show'])
                    showOrderModal()
                  }}
                  loading={loading}
                  disabled={!record.onSale}
                >
                  바로구매
                </Button>
              </Space>
            </Form.Item>
          </StyledSpace>
        </Form>
      </Space>
      <OrderModal actions={modalActions} ref={orderModalRef} onAction={onModalAction} checkedList={[]} />
    </>
  )
}

const IteProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '상품 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      maskClosable={false}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={950}
      onAction={onAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const IteOptsContainer = styled(Card)<any>`
  transition: all 0.5s;

  ${({enabled}) =>
    enabled
      ? css`
          border: 1px solid ${process.env.REACT_APP_THEME_COLOR};
        `
      : css`
          border: 1px solid rgba(0, 0, 0, 0.06);
        `};

  label {
    color: ${({enabled}) => (enabled ? 'black' : '#9e9e9e')};
  }

  .ant-card-head {
    transition: all 0.5s;
    ${({enabled}) =>
      enabled
        ? css`
            background: ${process.env.REACT_APP_THEME_COLOR};
          `
        : css`
            background: none;
          `};
    color: ${({enabled}) => (enabled ? 'white' : '#9e9e9e')};
  }
`

const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;

  .ant-radio-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 32px;
  }
`

const StyledFormItem = styled(Form.Item)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default IteProductModal
