import api from './index'
import {ICommon} from './products'

export interface IImageCommon extends ICommon {
  titleImage: string
}

export interface IProductCategory {
  id: number
  parentId: number
  titleImage: string
  productBrand: IImageCommon
  productOrderType: IImageCommon
  productSalesPlatform: IImageCommon
  createdAt: string
  updatedAt: string
}

export async function getCategories(): Promise<{data: IProductCategory[]; total: number}> {
  try {
    const res = await api.get(`/categories`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCategory(id: number): Promise<IProductCategory> {
  try {
    const res = await api.get(`/categories/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
