import api from './index'

export interface INotice {
  id: number
  title: string
  isFixation: boolean
  files: string[]
  content: string
  createdAt: string
  updatedAt: string
}

interface IGetNoticeParams {
  start: number
  perPage: number
}

export async function getNotices(
  params: IGetNoticeParams = {start: 0, perPage: 1000}
): Promise<{data: INotice[]; total: number}> {
  try {
    const res = await api.get(`/notices`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getNotice(id: number): Promise<INotice> {
  try {
    const res = await api.get(`/notices/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
