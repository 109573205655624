export default function AgreeMarketing() {
  const iframePart = () => {
    return {
      __html: `<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <style>
    <!--
     /* Font Definitions */
     @font-face
    	{font-family:Wingdings;
    	panose-1:5 0 0 0 0 0 0 0 0 0;}
    @font-face
    	{font-family:Gulim;
    	panose-1:2 11 6 0 0 1 1 1 1 1;}
    @font-face
    	{font-family:"Cambria Math";
    	panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
    	{font-family:"\@Gulim";
    	panose-1:2 11 6 0 0 1 1 1 1 1;}
    @font-face
    	{font-family:GulimChe;
    	panose-1:2 11 6 9 0 1 1 1 1 1;}
    @font-face
    	{font-family:"\@GulimChe";}
    @font-face
    	{font-family:"Malgun Gothic";
    	panose-1:2 11 5 3 2 0 0 2 0 4;}
    @font-face
    	{font-family:"\@Malgun Gothic";}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
    	{margin-top:0in;
    	margin-right:0in;
    	margin-bottom:8.0pt;
    	margin-left:0in;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:10.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    h1
    	{mso-style-link:"Heading 1 Char";
    	margin-top:0in;
    	margin-right:0in;
    	margin-bottom:8.0pt;
    	margin-left:0in;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;
    	page-break-after:avoid;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:11.0pt;
    	font-family:"Malgun Gothic",sans-serif;
    	font-weight:bold;}
    p.MsoTitle, li.MsoTitle, div.MsoTitle
    	{mso-style-link:"Title Char";
    	margin-top:12.0pt;
    	margin-right:0in;
    	margin-bottom:6.0pt;
    	margin-left:0in;
    	text-align:center;
    	line-height:107%;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:20.0pt;
    	font-family:"Malgun Gothic",sans-serif;
    	font-weight:bold;}
    p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
    	{margin:0in;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:11.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
    	{margin-top:0in;
    	margin-right:0in;
    	margin-bottom:8.0pt;
    	margin-left:40.0pt;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;
    	text-autospace:none;
    	word-break:break-all;
    	font-size:10.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    span.TitleChar
    	{mso-style-name:"Title Char";
    	mso-style-link:Title;
    	font-family:"Malgun Gothic",sans-serif;
    	font-weight:bold;}
    span.Heading1Char
    	{mso-style-name:"Heading 1 Char";
    	mso-style-link:"Heading 1";
    	font-family:"Malgun Gothic",sans-serif;
    	font-weight:bold;}
    .MsoChpDefault
    	{font-size:10.0pt;
    	font-family:"Malgun Gothic",sans-serif;}
    .MsoPapDefault
    	{margin-bottom:8.0pt;
    	text-align:justify;
    	text-justify:inter-ideograph;
    	line-height:107%;}
     /* Page Definitions */
     @page WordSection1
    	{size:595.3pt 841.9pt;
    	margin:85.05pt 1.0in 1.0in 1.0in;}
    div.WordSection1
    	{page:WordSection1;}
     /* List Definitions */
     ol
    	{margin-bottom:0in;}
    ul
    	{margin-bottom:0in;}
    -->
  </style>
</head>

<body style="word-wrap: break-word" lang="EN-US">
  <div class="WordSection1">
    <p class="MsoTitle"><span lang="KO">소노바코리아 온라인 쇼핑몰 이용약관</span></p>

    <p class="MsoNormal"><span style="font-size: 11pt; line-height: 107%">&nbsp;</span></p>

    <h1><span lang="KO">제</span>1<span lang="KO">조 </span>(<span lang="KO">목적</span>)&nbsp;</h1>

    <p class="MsoNoSpacing" style="margin-right: 10pt">
      <span lang="KO">이</span>&nbsp;<span lang="KO">약관은</span>&nbsp;<span lang="KO">소노바코리아 유한회사</span
      >(<span lang="KO">전자상거래</span>&nbsp;<span lang="KO">사업자</span>)<span lang="KO">가</span>&nbsp;<span
        lang="KO"
        >운영하는</span
      >&nbsp;<span lang="KO">소노바코리아 유한회사</span>&nbsp;<span lang="KO">온라인 쇼핑몰</span>(<span lang="KO"
        >이하</span
      >&nbsp;<span lang="KO">“몰”이라</span>&nbsp;<span lang="KO">한다</span>)<span lang="KO">에서</span>&nbsp;<span
        lang="KO"
        >제공하는</span
      >&nbsp;<span lang="KO">인터넷</span>&nbsp;<span lang="KO">관련</span>&nbsp;<span lang="KO">서비스</span>(<span
        lang="KO"
        >이하</span
      >&nbsp;<span lang="KO">“서비스”라</span>&nbsp;<span lang="KO">한다</span>)<span lang="KO">를</span>&nbsp;<span
        lang="KO"
        >이용함에</span
      >&nbsp;<span lang="KO">있어</span>&nbsp;<span lang="KO">사이버</span>&nbsp;<span lang="KO">몰과</span>&nbsp;<span
        lang="KO"
        >이용자의</span
      >&nbsp;<span lang="KO">권리 · 의무</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">책임사항을</span
      >&nbsp;<span lang="KO">규정함을</span>&nbsp;<span lang="KO">목적으로</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">
      <span lang="KO">※ 「</span>PC<span lang="KO">통신</span>,&nbsp;<span lang="KO">무선</span>&nbsp;<span lang="KO"
        >등을</span
      >&nbsp;<span lang="KO">이용하는</span>&nbsp;<span lang="KO">전자상거래에</span>&nbsp;<span lang="KO"
        >대해서도</span
      >&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">성질에</span>&nbsp;<span lang="KO">반하지</span>&nbsp;<span
        lang="KO"
        >않는</span
      >&nbsp;<span lang="KO">한</span>&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관을</span>&nbsp;<span
        lang="KO"
        >준용합니다</span
      >.<span lang="KO">」</span>
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <h1><span lang="KO">제</span>2<span lang="KO">조 </span>(<span lang="KO">정의</span>)</h1>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이란</span>&nbsp;<span
        lang="KO"
        >소노바코리아 유한회사</span
      >&nbsp;<span lang="KO">회사가</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >용역</span
      >(<span lang="KO">이하</span>&nbsp;<span lang="KO">“재화</span>&nbsp;<span lang="KO">등”이라</span>&nbsp;<span
        lang="KO"
        >함</span
      >)<span lang="KO">을</span>&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">제공하기</span>&nbsp;<span
        lang="KO"
        >위하여</span
      >&nbsp;<span lang="KO">컴퓨터</span>&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">정보통신설비를</span
      >&nbsp;<span lang="KO">이용하여</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등을</span
      >&nbsp;<span lang="KO">거래할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span
        lang="KO"
        >설정한</span
      >&nbsp;<span lang="KO">가상의</span>&nbsp;<span lang="KO">영업장을</span>&nbsp;<span lang="KO">말하며</span
      >,&nbsp;<span lang="KO">아울러</span>&nbsp;<span lang="KO">사이버몰을</span>&nbsp;<span lang="KO">운영하는</span
      >&nbsp;<span lang="KO">사업자의</span>&nbsp;<span lang="KO">의미로도</span>&nbsp;<span lang="KO">사용합니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“이용자”란</span>&nbsp;<span
        lang="KO"
        >“몰”에</span
      >&nbsp;<span lang="KO">접속하여</span>&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관에</span
      >&nbsp;<span lang="KO">따라</span>&nbsp;<span lang="KO">“몰”이</span>&nbsp;<span lang="KO">제공하는</span
      >&nbsp;<span lang="KO">서비스를</span>&nbsp;<span lang="KO">받는</span>&nbsp;<span lang="KO">회원</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">비회원을</span>&nbsp;<span lang="KO">말합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">‘회원’이라</span>&nbsp;<span
        lang="KO"
        >함은</span
      >&nbsp;<span lang="KO">“몰”에</span>&nbsp;<span lang="KO">회원등록을</span>&nbsp;<span lang="KO">한</span
      >&nbsp;<span lang="KO">자로서</span>,&nbsp;<span lang="KO">계속적으로</span>&nbsp;<span lang="KO">“몰”이</span
      >&nbsp;<span lang="KO">제공하는</span>&nbsp;<span lang="KO">서비스를</span>&nbsp;<span lang="KO">이용할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">자를</span>&nbsp;<span
        lang="KO"
        >말합니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">‘비회원’이라</span>&nbsp;<span lang="KO">함은</span>&nbsp;<span lang="KO">회원에</span
      >&nbsp;<span lang="KO">가입하지</span>&nbsp;<span lang="KO">않고</span>&nbsp;<span lang="KO">“몰”이</span
      >&nbsp;<span lang="KO">제공하는</span>&nbsp;<span lang="KO">서비스를</span>&nbsp;<span lang="KO">이용하는</span
      >&nbsp;<span lang="KO">자를</span>&nbsp;<span lang="KO">말합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>3<span lang="KO">조</span>&nbsp;(<span lang="KO">약관</span>&nbsp;<span lang="KO"
        >등의</span
      >&nbsp;<span lang="KO">명시와</span>&nbsp;<span lang="KO">설명</span>&nbsp;<span lang="KO">및</span>&nbsp;<span
        lang="KO"
        >개정</span
      >)
    </h1>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이</span
      >&nbsp;<span lang="KO">약관의</span>&nbsp;<span lang="KO">내용과</span>&nbsp;<span lang="KO">상호</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">대표자</span>&nbsp;<span lang="KO">성명</span>,&nbsp;<span
        lang="KO"
        >영업소</span
      >&nbsp;<span lang="KO">소재지</span>&nbsp;<span lang="KO">주소</span>(<span lang="KO">소비자의</span>&nbsp;<span
        lang="KO"
        >불만을</span
      >&nbsp;<span lang="KO">처리할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span
        lang="KO"
        >곳의</span
      >&nbsp;<span lang="KO">주소를</span>&nbsp;<span lang="KO">포함</span>),&nbsp;<span lang="KO"
        >전화번호 · 모사전송번호 · 전자우편주소</span
      >,&nbsp;<span lang="KO">사업자등록번호</span>,&nbsp;<span lang="KO">통신판매업</span>&nbsp;<span lang="KO"
        >신고번호</span
      >,&nbsp;<span lang="KO">개인정보관리책임자 등을</span>&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO"
        >쉽게</span
      >&nbsp;<span lang="KO">알</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span
        lang="KO"
        >소노바코리아 유한회사</span
      >&nbsp;<span lang="KO">사이버몰의</span>&nbsp;<span lang="KO">초기</span>&nbsp;<span lang="KO">서비스화면</span
      >(<span lang="KO">전면</span>)<span lang="KO">에</span>&nbsp;<span lang="KO">게시합니다</span>.&nbsp;<span
        lang="KO"
        >다만</span
      >,&nbsp;<span lang="KO">약관의</span>&nbsp;<span lang="KO">내용은</span>&nbsp;<span lang="KO">이용자가</span
      >&nbsp;<span lang="KO">연결화면을</span>&nbsp;<span lang="KO">통하여 볼</span>&nbsp;<span lang="KO">수</span
      >&nbsp;<span lang="KO">있도록</span>&nbsp;<span lang="KO">할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span
        lang="KO"
        >있습니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰은</span>&nbsp;<span
        lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">약관에</span>&nbsp;<span lang="KO">동의하기에</span>&nbsp;<span lang="KO">앞서</span
      >&nbsp;<span lang="KO">약관에</span>&nbsp;<span lang="KO">정한</span>&nbsp;<span lang="KO">내용</span>&nbsp;<span
        lang="KO"
        >중</span
      >&nbsp;<span lang="KO">청약철회 · 배송책임 · 환불조건</span>&nbsp;<span lang="KO">등과</span>&nbsp;<span lang="KO"
        >같은</span
      >&nbsp;<span lang="KO">중요한</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">이용자가</span
      >&nbsp;<span lang="KO">이해할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span
        lang="KO"
        >별도의</span
      >&nbsp;<span lang="KO">연결화면</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">팝업화면</span
      >&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">제공하여</span>&nbsp;<span lang="KO">이용자의</span
      >&nbsp;<span lang="KO">확인을</span>&nbsp;<span lang="KO">구하여야</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >「전자상거래</span
      >&nbsp;<span lang="KO">등에서의</span>&nbsp;<span lang="KO">소비자보호에</span>&nbsp;<span lang="KO">관한</span
      >&nbsp;<span lang="KO">법률」</span>,&nbsp;<span lang="KO">「약관의</span>&nbsp;<span lang="KO">규제에</span
      >&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률」</span>,&nbsp;<span lang="KO">「전자문서</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">전자거래기본법」</span>,&nbsp;<span lang="KO"
        >「전자금융거래법」</span
      >,&nbsp;<span lang="KO">「전자서명법」</span>,&nbsp;<span lang="KO">「정보통신망</span>&nbsp;<span lang="KO"
        >이용촉진</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">정보보호</span>&nbsp;<span lang="KO">등에</span>&nbsp;<span
        lang="KO"
        >관한</span
      >&nbsp;<span lang="KO">법률」</span>,&nbsp;<span lang="KO">「방문판매</span>&nbsp;<span lang="KO">등에</span
      >&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률」</span>,&nbsp;<span lang="KO">「소비자기본법」</span
      >&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">관련</span>&nbsp;<span lang="KO">법을</span>&nbsp;<span
        lang="KO"
        >위배하지</span
      >&nbsp;<span lang="KO">않는</span>&nbsp;<span lang="KO">범위에서</span>&nbsp;<span lang="KO">이</span>&nbsp;<span
        lang="KO"
        >약관을</span
      >&nbsp;<span lang="KO">개정할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >약관을</span
      >&nbsp;<span lang="KO">개정할</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">적용일자</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">개정사유를</span>&nbsp;<span lang="KO">명시하여</span
      >&nbsp;<span lang="KO">현행약관과</span>&nbsp;<span lang="KO">함께</span>&nbsp;<span lang="KO">몰의</span
      >&nbsp;<span lang="KO">초기화면에</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">적용일자</span
      >&nbsp;7<span lang="KO">일</span>&nbsp;<span lang="KO">이전부터</span>&nbsp;<span lang="KO">적용일자</span
      >&nbsp;<span lang="KO">전일까지</span>&nbsp;<span lang="KO">공지합니다</span>.&nbsp;<span lang="KO">다만</span
      >,&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">불리하게</span>&nbsp;<span lang="KO"
        >약관내용을</span
      >&nbsp;<span lang="KO">변경하는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">최소한</span
      >&nbsp;30<span lang="KO">일</span>&nbsp;<span lang="KO">이상의</span>&nbsp;<span lang="KO">사전</span>&nbsp;<span
        lang="KO"
        >유예기간을</span
      >&nbsp;<span lang="KO">두고</span>&nbsp;<span lang="KO">공지합니다</span>.&nbsp;&nbsp;<span lang="KO">이</span
      >&nbsp;<span lang="KO">경우</span>&nbsp;"<span lang="KO">몰“은</span>&nbsp;<span lang="KO">개정</span>&nbsp;<span
        lang="KO"
        >전</span
      >&nbsp;<span lang="KO">내용과</span>&nbsp;<span lang="KO">개정</span>&nbsp;<span lang="KO">후</span>&nbsp;<span
        lang="KO"
        >내용을</span
      >&nbsp;<span lang="KO">명확하게</span>&nbsp;<span lang="KO">비교하여</span>&nbsp;<span lang="KO">이용자가</span
      >&nbsp;<span lang="KO">알기</span>&nbsp;<span lang="KO">쉽도록</span>&nbsp;<span lang="KO">표시합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ⑤<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >약관을</span
      >&nbsp;<span lang="KO">개정할</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">그</span
      >&nbsp;<span lang="KO">개정약관은</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">적용일자</span
      >&nbsp;<span lang="KO">이후에</span>&nbsp;<span lang="KO">체결되는</span>&nbsp;<span lang="KO">계약에만</span
      >&nbsp;<span lang="KO">적용되고</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">이전에</span
      >&nbsp;<span lang="KO">이미</span>&nbsp;<span lang="KO">체결된</span>&nbsp;<span lang="KO">계약에</span
      >&nbsp;<span lang="KO">대해서는</span>&nbsp;<span lang="KO">개정</span>&nbsp;<span lang="KO">전의</span
      >&nbsp;<span lang="KO">약관조항이</span>&nbsp;<span lang="KO">그대로</span>&nbsp;<span lang="KO">적용됩니다</span
      >.&nbsp;<span lang="KO">다만</span>&nbsp;<span lang="KO">이미</span>&nbsp;<span lang="KO">계약을</span>&nbsp;<span
        lang="KO"
        >체결한</span
      >&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO">개정약관</span>&nbsp;<span lang="KO">조항의</span
      >&nbsp;<span lang="KO">적용을</span>&nbsp;<span lang="KO">받기를</span>&nbsp;<span lang="KO">원하는</span
      >&nbsp;<span lang="KO">뜻을</span>&nbsp;<span lang="KO">제</span>4<span lang="KO">항에</span>&nbsp;<span lang="KO"
        >의한</span
      >&nbsp;<span lang="KO">개정약관의</span>&nbsp;<span lang="KO">공지기간</span>&nbsp;<span lang="KO">내에</span
      >&nbsp;<span lang="KO">“몰”에</span>&nbsp;<span lang="KO">송신하여</span>&nbsp;<span lang="KO">“몰”의</span
      >&nbsp;<span lang="KO">동의를</span>&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">경우에는</span
      >&nbsp;<span lang="KO">개정약관</span>&nbsp;<span lang="KO">조항이</span>&nbsp;<span lang="KO">적용됩니다</span>.
      <span lang="KO">변경된 약관에 대한 정보를 알지 못해 발생하는 이용자의 피해는</span> “<span lang="KO">몰</span
      >”<span lang="KO">이 책임지지 않습니다</span>.&nbsp;
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ⑥<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이</span>&nbsp;<span
        lang="KO"
        >약관에서</span
      >&nbsp;<span lang="KO">정하지</span>&nbsp;<span lang="KO">아니한</span>&nbsp;<span lang="KO">사항과</span
      >&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관의</span>&nbsp;<span lang="KO">해석에</span>&nbsp;<span
        lang="KO"
        >관하여는</span
      >&nbsp;<span lang="KO">전자상거래</span>&nbsp;<span lang="KO">등에서의</span>&nbsp;<span lang="KO"
        >소비자보호에</span
      >&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률</span>,&nbsp;<span lang="KO">약관의</span>&nbsp;<span
        lang="KO"
        >규제</span
      >&nbsp;<span lang="KO">등에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률</span>,&nbsp;<span
        lang="KO"
        >공정거래위원회가</span
      >&nbsp;<span lang="KO">정하는</span>&nbsp;<span lang="KO">전자상거래</span>&nbsp;<span lang="KO">등에서의</span
      >&nbsp;<span lang="KO">소비자</span>&nbsp;<span lang="KO">보호지침</span>&nbsp;<span lang="KO">및</span
      >&nbsp;<span lang="KO">관계법령</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">상관례에</span
      >&nbsp;<span lang="KO">따릅니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-right: 10pt">&nbsp;</p>

    <p
      class="MsoNoSpacing"
      style="
        margin-top: 0in;
        margin-right: 10pt;
        margin-bottom: 0in;
        margin-left: 20pt;
        margin-bottom: 0.0001pt;
        text-indent: -20pt;
      "
    >
      ⑦<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO"
        >본 약관은 이용자가 약관의 내용에 동의함으로써 효력이 발생하며 이용계약 종료 일까지 적용됩니다</span
      >. <span lang="KO">단</span>, <span lang="KO">채권 또는 채무관계가 있을 경우에는 채권</span>,
      <span lang="KO">채무의 완료일까지 적용합니다</span>.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>4<span lang="KO">조 </span>(<span lang="KO">서비스의</span>&nbsp;<span lang="KO"
        >제공</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">변경</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >다음과</span
      >&nbsp;<span lang="KO">같은</span>&nbsp;<span lang="KO">업무를</span>&nbsp;<span lang="KO">수행합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">재화</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">용역에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">정보</span>&nbsp;<span
        lang="KO"
        >제공</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">구매계약의</span>&nbsp;<span lang="KO">체결</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">구매계약이</span>&nbsp;<span lang="KO">체결된</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">용역의</span>&nbsp;<span lang="KO">배송</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">기타</span>&nbsp;<span
        lang="KO"
        >“몰”이</span
      >&nbsp;<span lang="KO">정하는</span>&nbsp;<span lang="KO">업무</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">용역의</span>&nbsp;<span lang="KO">품절</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">기술적</span>&nbsp;<span lang="KO">사양의</span>&nbsp;<span lang="KO">변경</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">장차</span
      >&nbsp;<span lang="KO">체결되는</span>&nbsp;<span lang="KO">계약에</span>&nbsp;<span lang="KO">의해</span
      >&nbsp;<span lang="KO">제공할</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >용역의</span
      >&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">변경할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span
        lang="KO"
        >있습니다</span
      >.&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">변경된</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">용역의</span>&nbsp;<span
        lang="KO"
        >내용</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">제공일자를</span>&nbsp;<span lang="KO">명시하여</span
      >&nbsp;<span lang="KO">현재의</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >용역의</span
      >&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">게시한</span>&nbsp;<span lang="KO">곳에</span
      >&nbsp;<span lang="KO">즉시</span>&nbsp;<span lang="KO">공지합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >제공하기로</span
      >&nbsp;<span lang="KO">이용자와</span>&nbsp;<span lang="KO">계약을</span>&nbsp;<span lang="KO">체결한</span
      >&nbsp;<span lang="KO">서비스의</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">재화 등의</span
      >&nbsp;<span lang="KO">품절</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">기술적</span>&nbsp;<span
        lang="KO"
        >사양의</span
      >&nbsp;<span lang="KO">변경</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">사유로</span>&nbsp;<span
        lang="KO"
        >변경할</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">사유를</span
      >&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">통지</span>&nbsp;<span lang="KO">가능한</span
      >&nbsp;<span lang="KO">수단으로</span>&nbsp;<span lang="KO">즉시</span>&nbsp;<span lang="KO">통지합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>5<span lang="KO">조 </span>(<span lang="KO">서비스의</span>&nbsp;<span lang="KO"
        >중단</span
      >)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >컴퓨터</span
      >&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">정보통신설비의</span>&nbsp;<span lang="KO"
        >보수점검 · 교체</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">고장</span>,&nbsp;<span lang="KO">통신의</span>&nbsp;<span
        lang="KO"
        >두절</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">사유가</span>&nbsp;<span lang="KO">발생한</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">서비스의</span>&nbsp;<span lang="KO">제공을</span
      >&nbsp;<span lang="KO">일시적으로</span>&nbsp;<span lang="KO">중단할</span>&nbsp;<span lang="KO">수</span
      >&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">회사는 상당한 이유가 있는 경우에 회사의 정책상</span>, <span lang="KO">운영상</span>,
      <span lang="KO">기술상의 필요에 따라 제공하고 있는 서비스의 전부 또는 일부를 변경 또는 중단할 수 있으며</span>,
      <span lang="KO">이에 대하여 관련 법에 특별한 규정이 없는 한 이용자에게 별도 보상을 하지 않습니다</span>.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">서비스의 내용</span>,
      <span lang="KO">이용방법</span>,
      <span lang="KO">이용시간에 대하여 변경 또는 중단이 있는 경우에는 변경 또는 중단 사유</span>,
      <span lang="KO">변경 또는 중단될 서비스의 내용 및 제공일자 등을 그 변경 또는 중단 전에 제</span>8<span lang="KO"
        >조의 방법으로 이용자에게 통지합니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">제</span>6<span lang="KO">조 </span>(<span lang="KO">회원가입</span>)</h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이용자는</span>&nbsp;<span
        lang="KO"
        >“몰”이</span
      >&nbsp;<span lang="KO">정한</span>&nbsp;<span lang="KO">가입</span>&nbsp;<span lang="KO">양식에</span>&nbsp;<span
        lang="KO"
        >따라</span
      >&nbsp;<span lang="KO">회원정보를</span>&nbsp;<span lang="KO">기입한</span>&nbsp;<span lang="KO">후</span
      >&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관에</span>&nbsp;<span lang="KO">동의한다는</span
      >&nbsp;<span lang="KO">의사표시를</span>&nbsp;<span lang="KO">함으로서</span>&nbsp;<span lang="KO"
        >회원가입을</span
      >&nbsp;<span lang="KO">신청합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >제</span
      >1<span lang="KO">항과</span>&nbsp;<span lang="KO">같이</span>&nbsp;<span lang="KO">회원으로</span>&nbsp;<span
        lang="KO"
        >가입할</span
      >&nbsp;<span lang="KO">것을</span>&nbsp;<span lang="KO">신청한</span>&nbsp;<span lang="KO">이용자</span
      >&nbsp;<span lang="KO">중</span>&nbsp;<span lang="KO">다음</span>&nbsp;<span lang="KO">각</span>&nbsp;<span
        lang="KO"
        >호에</span
      >&nbsp;<span lang="KO">해당하지</span>&nbsp;<span lang="KO">않는</span>&nbsp;<span lang="KO">한</span>&nbsp;<span
        lang="KO"
        >회원으로</span
      >&nbsp;<span lang="KO">등록할 수 있습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">가입신청자가</span>&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관</span>&nbsp;<span
        lang="KO"
        >제</span
      >7<span lang="KO">조제</span>3<span lang="KO">항에</span>&nbsp;<span lang="KO">의하여</span>&nbsp;<span lang="KO"
        >이전에</span
      >&nbsp;<span lang="KO">회원자격을</span>&nbsp;<span lang="KO">상실한</span>&nbsp;<span lang="KO">적이</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우</span>,&nbsp;<span lang="KO">다만</span>&nbsp;<span
        lang="KO"
        >제</span
      >7<span lang="KO">조제</span>3<span lang="KO">항에</span>&nbsp;<span lang="KO">의한</span>&nbsp;<span lang="KO"
        >회원자격</span
      >&nbsp;<span lang="KO">상실</span>&nbsp;<span lang="KO">후</span>&nbsp;3<span lang="KO">년이</span>&nbsp;<span
        lang="KO"
        >경과한</span
      >&nbsp;<span lang="KO">자로서</span>&nbsp;<span lang="KO">“몰”의</span>&nbsp;<span lang="KO">회원재가입</span
      >&nbsp;<span lang="KO">승낙을</span>&nbsp;<span lang="KO">얻은</span>&nbsp;<span lang="KO">경우에는</span
      >&nbsp;<span lang="KO">예외로</span>&nbsp;<span lang="KO">한다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">등록</span>&nbsp;<span
        lang="KO"
        >내용에</span
      >&nbsp;<span lang="KO">허위</span>,&nbsp;<span lang="KO">기재누락</span>,&nbsp;<span lang="KO">오기가</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">기타</span>&nbsp;<span
        lang="KO"
        >회원으로</span
      >&nbsp;<span lang="KO">등록하는</span>&nbsp;<span lang="KO">것이</span>&nbsp;<span lang="KO">“몰”의</span
      >&nbsp;<span lang="KO">기술상</span>&nbsp;<span lang="KO">현저히</span>&nbsp;<span lang="KO">지장이</span
      >&nbsp;<span lang="KO">있다고</span>&nbsp;<span lang="KO">판단되는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">회원가입계약의</span>&nbsp;<span lang="KO">성립</span>&nbsp;<span lang="KO">시기는</span
      >&nbsp;<span lang="KO">“몰”의</span>&nbsp;<span lang="KO">승낙이</span>&nbsp;<span lang="KO">회원에게</span
      >&nbsp;<span lang="KO">도달한</span>&nbsp;<span lang="KO">시점으로</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">회원은</span>&nbsp;<span
        lang="KO"
        >회원가입</span
      >&nbsp;<span lang="KO">시</span>&nbsp;<span lang="KO">등록한</span>&nbsp;<span lang="KO">사항에</span>&nbsp;<span
        lang="KO"
        >변경이</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우</span>,&nbsp;<span lang="KO">상당한</span>&nbsp;<span
        lang="KO"
        >기간</span
      >&nbsp;<span lang="KO">이내에</span>&nbsp;<span lang="KO">“몰”에</span>&nbsp;<span lang="KO">대하여</span
      >&nbsp;<span lang="KO">회원정보</span>&nbsp;<span lang="KO">수정</span>&nbsp;<span lang="KO">등의</span
      >&nbsp;<span lang="KO">방법으로</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">변경사항을</span
      >&nbsp;<span lang="KO">알려야</span>&nbsp;<span lang="KO">합니다</span>.
      <span lang="KO">변경사항을 알리지 않아 발생한 불이익에 대하여</span> “<span lang="KO">몰</span>”<span lang="KO"
        >은 책임지지 않습니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="text-indent: 3.75pt">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>7<span lang="KO">조 </span>(<span lang="KO">회원</span>&nbsp;<span lang="KO">탈퇴</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">자격</span>&nbsp;<span lang="KO">상실</span>&nbsp;<span
        lang="KO"
        >등</span
      >)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">회원은</span>&nbsp;<span
        lang="KO"
        >“몰”에</span
      >&nbsp;<span lang="KO">언제든지</span>&nbsp;<span lang="KO">탈퇴를</span>&nbsp;<span lang="KO">요청할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있으며</span>&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >즉시</span
      >&nbsp;<span lang="KO">회원탈퇴를</span>&nbsp;<span lang="KO">처리합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">회원이</span>&nbsp;<span
        lang="KO"
        >다음</span
      >&nbsp;<span lang="KO">각</span>&nbsp;<span lang="KO">호의</span>&nbsp;<span lang="KO">사유에</span>&nbsp;<span
        lang="KO"
        >해당하는</span
      >&nbsp;<span lang="KO">경우</span>,&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span lang="KO">회원자격을</span
      >&nbsp;<span lang="KO">제한</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">정지시킬</span>&nbsp;<span
        lang="KO"
        >수</span
      >&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">가입</span>&nbsp;<span
        lang="KO"
        >신청</span
      >&nbsp;<span lang="KO">시에</span>&nbsp;<span lang="KO">허위</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span
        lang="KO"
        >등록한</span
      >&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”을</span>&nbsp;<span lang="KO">이용하여</span>&nbsp;<span lang="KO">구입한</span>&nbsp;<span
        lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">대금</span>,&nbsp;<span lang="KO">기타</span>&nbsp;<span
        lang="KO"
        >“몰” 이용에</span
      >&nbsp;<span lang="KO">관련하여</span>&nbsp;<span lang="KO">회원이</span>&nbsp;<span lang="KO">부담하는</span
      >&nbsp;<span lang="KO">채무를</span>&nbsp;<span lang="KO">기일에</span>&nbsp;<span lang="KO">지급하지</span
      >&nbsp;<span lang="KO">않는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">다른</span>&nbsp;<span
        lang="KO"
        >사람의</span
      >&nbsp;<span lang="KO">“몰”</span>&nbsp;<span lang="KO">이용을</span>&nbsp;<span lang="KO">방해하거나</span
      >&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">정보를</span>&nbsp;<span lang="KO">도용하는</span
      >&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">전자상거래</span>&nbsp;<span lang="KO">질서를</span
      >&nbsp;<span lang="KO">위협하는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      4.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”을</span>&nbsp;<span lang="KO">이용하여</span>&nbsp;<span lang="KO">법령</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관이</span>&nbsp;<span lang="KO">금지하거나</span
      >&nbsp;<span lang="KO">공서양속에</span>&nbsp;<span lang="KO">반하는</span>&nbsp;<span lang="KO">행위를</span
      >&nbsp;<span lang="KO">하는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >회원</span
      >&nbsp;<span lang="KO">자격을</span>&nbsp;<span lang="KO">제한 · 정지</span>&nbsp;<span lang="KO">시킨</span
      >&nbsp;<span lang="KO">후</span>,&nbsp;<span lang="KO">동일한</span>&nbsp;<span lang="KO">행위가</span
      >&nbsp;2<span lang="KO">회</span>&nbsp;<span lang="KO">이상</span>&nbsp;<span lang="KO">반복되거나</span
      >&nbsp;30<span lang="KO">일</span>&nbsp;<span lang="KO">이내에</span>&nbsp;<span lang="KO">그</span>&nbsp;<span
        lang="KO"
        >사유가</span
      >&nbsp;<span lang="KO">시정되지</span>&nbsp;<span lang="KO">아니하는</span>&nbsp;<span lang="KO">경우</span
      >&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span lang="KO">회원자격을</span>&nbsp;<span lang="KO">상실시킬</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >회원자격을</span
      >&nbsp;<span lang="KO">상실시키는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO"
        >회원등록을</span
      >&nbsp;<span lang="KO">말소합니다</span>.&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">경우</span
      >&nbsp;<span lang="KO">회원에게</span>&nbsp;<span lang="KO">이를</span>&nbsp;<span lang="KO">통지하고</span
      >,&nbsp;<span lang="KO">회원등록</span>&nbsp;<span lang="KO">말소</span>&nbsp;<span lang="KO">전에</span
      >&nbsp;<span lang="KO">최소한</span>&nbsp;30<span lang="KO">일</span>&nbsp;<span lang="KO">이상의</span
      >&nbsp;<span lang="KO">기간을</span>&nbsp;<span lang="KO">정하여</span>&nbsp;<span lang="KO">소명할</span
      >&nbsp;<span lang="KO">기회를</span>&nbsp;<span lang="KO">부여합니다</span>.
    </p>

    <p class="MsoListParagraph" style="margin-left: 40pt; text-align: left" align="left">
      <span style="font-size: 11pt; line-height: 107%">&nbsp;</span>
    </p>

    <h1>
      <span lang="KO">제</span>8<span lang="KO">조 </span>(<span lang="KO">회원에</span>&nbsp;<span lang="KO">대한</span
      >&nbsp;<span lang="KO">통지</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >회원에</span
      >&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">통지를</span>&nbsp;<span lang="KO">하는</span>&nbsp;<span
        lang="KO"
        >경우</span
      >,&nbsp;<span lang="KO">회원이</span>&nbsp;<span lang="KO">“몰”과</span>&nbsp;<span lang="KO">미리</span
      >&nbsp;<span lang="KO">약정하여</span>&nbsp;<span lang="KO">지정한</span>&nbsp;<span lang="KO">전자우편</span
      >&nbsp;<span lang="KO">주소로</span>&nbsp;<span lang="KO">할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span
        lang="KO"
        >있습니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >불특정다수</span
      >&nbsp;<span lang="KO">회원에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">통지의</span
      >&nbsp;<span lang="KO">경우</span>&nbsp;1<span lang="KO">주일이상</span>&nbsp;<span lang="KO">“몰”</span
      >&nbsp;<span lang="KO">게시판에</span>&nbsp;<span lang="KO">게시 함으로써</span>&nbsp;<span lang="KO">개별</span
      >&nbsp;<span lang="KO">통지에</span>&nbsp;<span lang="KO">갈음할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span
        lang="KO"
        >있습니다</span
      >.&nbsp;<span lang="KO">다만</span>,&nbsp;<span lang="KO">회원</span>&nbsp;<span lang="KO">본인의</span
      >&nbsp;<span lang="KO">거래와</span>&nbsp;<span lang="KO">관련하여</span>&nbsp;<span lang="KO">중대한</span
      >&nbsp;<span lang="KO">영향을</span>&nbsp;<span lang="KO">미치는</span>&nbsp;<span lang="KO">사항에</span
      >&nbsp;<span lang="KO">대하여는</span>&nbsp;<span lang="KO">개별통지를</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>9<span lang="KO">조 </span>(<span lang="KO">구매신청</span>&nbsp;<span lang="KO">및</span
      >&nbsp;<span lang="KO">개인정보</span>&nbsp;<span lang="KO">제공</span>&nbsp;<span lang="KO">동의</span
      >&nbsp;<span lang="KO">등</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰” 이용자는</span>&nbsp;<span lang="KO">“몰” 상에서</span>&nbsp;<span lang="KO">다음</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">이와</span>&nbsp;<span lang="KO">유사한</span>&nbsp;<span
        lang="KO"
        >방법에</span
      >&nbsp;<span lang="KO">의하여</span>&nbsp;<span lang="KO">구매를</span>&nbsp;<span lang="KO">신청하며</span
      >,&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO">구매신청을</span
      >&nbsp;<span lang="KO">함에</span>&nbsp;<span lang="KO">있어서</span>&nbsp;<span lang="KO">다음의</span
      >&nbsp;<span lang="KO">각</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">알기</span>&nbsp;<span
        lang="KO"
        >쉽게</span
      >&nbsp;<span lang="KO">제공하여야</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">재화</span>&nbsp;<span
        lang="KO"
        >등의</span
      >&nbsp;<span lang="KO">검색</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">선택</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">받는</span>&nbsp;<span
        lang="KO"
        >사람의</span
      >&nbsp;<span lang="KO">성명</span>,&nbsp;<span lang="KO">주소</span>,&nbsp;<span lang="KO">전화번호</span
      >,&nbsp;<span lang="KO">전자우편주소</span>(<span lang="KO">또는</span>&nbsp;<span lang="KO">이동전화번호</span
      >)&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">입력</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">약관내용</span>,&nbsp;<span lang="KO">청약철회권이</span>&nbsp;<span lang="KO">제한되는</span
      >&nbsp;<span lang="KO">서비스</span>,&nbsp;<span lang="KO">배송료 · 설치비</span>&nbsp;<span lang="KO">등의</span
      >&nbsp;<span lang="KO">비용부담과</span>&nbsp;<span lang="KO">관련한</span>&nbsp;<span lang="KO">내용에</span
      >&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">확인</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      4.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">이</span>&nbsp;<span
        lang="KO"
        >약관에</span
      >&nbsp;<span lang="KO">동의하고</span>&nbsp;<span lang="KO">위</span>&nbsp;3.<span lang="KO">호의</span
      >&nbsp;<span lang="KO">사항을</span>&nbsp;<span lang="KO">확인하거나</span>&nbsp;<span lang="KO">거부하는</span
      >&nbsp;<span lang="KO">표시 </span>(<span lang="KO">예</span>,&nbsp;<span lang="KO">마우스</span>&nbsp;<span
        lang="KO"
        >클릭</span
      >)
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      5.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">재화 등의</span>&nbsp;<span lang="KO">구매신청</span>&nbsp;<span lang="KO">및</span>&nbsp;<span
        lang="KO"
        >이에</span
      >&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">확인</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >“몰”의</span
      >&nbsp;<span lang="KO">확인에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">동의</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      6.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">결제방법의</span>&nbsp;<span lang="KO">선택</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >제</span
      >3<span lang="KO">자에게</span>&nbsp;<span lang="KO">구매자</span>&nbsp;<span lang="KO">개인정보를</span
      >&nbsp;<span lang="KO">제공할</span>&nbsp;<span lang="KO">필요가</span>&nbsp;<span lang="KO">있는</span
      >&nbsp;<span lang="KO">경우</span>&nbsp;1)&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO"
        >제공받는</span
      >&nbsp;<span lang="KO">자</span>,&nbsp;2)<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">제공받는</span
      >&nbsp;<span lang="KO">자의</span>&nbsp;<span lang="KO">개인정보</span>&nbsp;<span lang="KO">이용목적</span
      >,&nbsp;3)&nbsp;<span lang="KO">제공하는</span>&nbsp;<span lang="KO">개인정보의</span>&nbsp;<span lang="KO"
        >항목</span
      >,&nbsp;4)&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">제공받는</span>&nbsp;<span lang="KO"
        >자의</span
      >&nbsp;<span lang="KO">개인정보</span>&nbsp;<span lang="KO">보유</span>&nbsp;<span lang="KO">및</span>&nbsp;<span
        lang="KO"
        >이용기간을</span
      >&nbsp;<span lang="KO">구매자에게</span>&nbsp;<span lang="KO">알리고</span>&nbsp;<span lang="KO">동의를</span
      >&nbsp;<span lang="KO">받아야</span>&nbsp;<span lang="KO">합니다</span>.&nbsp;(<span lang="KO">동의를</span
      >&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">사항이</span>&nbsp;<span lang="KO">변경되는</span
      >&nbsp;<span lang="KO">경우에도</span>&nbsp;<span lang="KO">같습니다</span>.)
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >제</span
      >3<span lang="KO">자에게</span>&nbsp;<span lang="KO">구매자의</span>&nbsp;<span lang="KO">개인정보를</span
      >&nbsp;<span lang="KO">취급할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span
        lang="KO"
        >업무를</span
      >&nbsp;<span lang="KO">위탁하는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;1)&nbsp;<span lang="KO"
        >개인정보</span
      >&nbsp;<span lang="KO">취급위탁을</span>&nbsp;<span lang="KO">받는</span>&nbsp;<span lang="KO">자</span
      >,&nbsp;2)&nbsp;<span lang="KO">개인정보</span>&nbsp;<span lang="KO">취급위탁을</span>&nbsp;<span lang="KO"
        >하는</span
      >&nbsp;<span lang="KO">업무의</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">구매자에게</span
      >&nbsp;<span lang="KO">알리고</span>&nbsp;<span lang="KO">동의를</span>&nbsp;<span lang="KO">받아야</span
      >&nbsp;<span lang="KO">합니다</span>.&nbsp;(<span lang="KO">동의를</span>&nbsp;<span lang="KO">받은</span
      >&nbsp;<span lang="KO">사항이</span>&nbsp;<span lang="KO">변경되는</span>&nbsp;<span lang="KO">경우에도</span
      >&nbsp;<span lang="KO">같습니다</span>.)&nbsp;<span lang="KO">다만</span>,&nbsp;<span lang="KO">서비스제공에</span
      >&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">계약이행을</span>&nbsp;<span lang="KO">위해</span
      >&nbsp;<span lang="KO">필요하고</span>&nbsp;<span lang="KO">구매자의</span>&nbsp;<span lang="KO">편의증진과</span
      >&nbsp;<span lang="KO">관련된</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">「정보통신망</span
      >&nbsp;<span lang="KO">이용촉진</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">정보보호</span
      >&nbsp;<span lang="KO">등에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률」에서</span
      >&nbsp;<span lang="KO">정하고</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">방법으로</span
      >&nbsp;<span lang="KO">개인정보</span>&nbsp;<span lang="KO">취급방침을</span>&nbsp;<span lang="KO">통해</span
      >&nbsp;<span lang="KO">알림으로써</span>&nbsp;<span lang="KO">고지절차와</span>&nbsp;<span lang="KO"
        >동의절차를</span
      >&nbsp;<span lang="KO">거치지</span>&nbsp;<span lang="KO">않아도</span>&nbsp;<span lang="KO">됩니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>10<span lang="KO">조</span>&nbsp;(<span lang="KO">계약의</span>&nbsp;<span lang="KO"
        >성립</span
      >)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >제</span
      >9<span lang="KO">조와</span>&nbsp;<span lang="KO">같은</span>&nbsp;<span lang="KO">구매신청에</span>&nbsp;<span
        lang="KO"
        >대하여</span
      >&nbsp;<span lang="KO">다음</span>&nbsp;<span lang="KO">각</span>&nbsp;<span lang="KO">호에</span>&nbsp;<span
        lang="KO"
        >해당하면</span
      >&nbsp;<span lang="KO">승낙하지</span>&nbsp;<span lang="KO">않을</span>&nbsp;<span lang="KO">수</span>&nbsp;<span
        lang="KO"
        >있습니다</span
      >.&nbsp;<span lang="KO">다만</span>,&nbsp;<span lang="KO">미성년자와</span>&nbsp;<span lang="KO">계약을</span
      >&nbsp;<span lang="KO">체결하는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO"
        >법정대리인의</span
      >&nbsp;<span lang="KO">동의를</span>&nbsp;<span lang="KO">얻지</span>&nbsp;<span lang="KO">못하면</span
      >&nbsp;<span lang="KO">미성년자</span>&nbsp;<span lang="KO">본인</span>&nbsp;<span lang="KO">또는</span
      >&nbsp;<span lang="KO">법정대리인이</span>&nbsp;<span lang="KO">계약을</span>&nbsp;<span lang="KO">취소할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있다는</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span
        lang="KO"
        >고지하여야</span
      >&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">신청</span>&nbsp;<span
        lang="KO"
        >내용에</span
      >&nbsp;<span lang="KO">허위</span>,&nbsp;<span lang="KO">기재누락</span>,&nbsp;<span lang="KO">오기가</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">미성년자가</span>&nbsp;<span lang="KO">담배</span>,&nbsp;<span lang="KO">주류</span>&nbsp;<span
        lang="KO"
        >등</span
      >&nbsp;<span lang="KO">청소년보호법에서</span>&nbsp;<span lang="KO">금지하는</span>&nbsp;<span lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">용역을</span>&nbsp;<span lang="KO">구매하는</span
      >&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">기타</span>&nbsp;<span
        lang="KO"
        >구매신청에</span
      >&nbsp;<span lang="KO">승낙하는</span>&nbsp;<span lang="KO">것이</span>&nbsp;<span lang="KO">“몰”</span
      >&nbsp;<span lang="KO">기술상</span>&nbsp;<span lang="KO">현저히</span>&nbsp;<span lang="KO">지장이</span
      >&nbsp;<span lang="KO">있다고</span>&nbsp;<span lang="KO">판단하는</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”의</span>&nbsp;<span
        lang="KO"
        >승낙이</span
      >&nbsp;<span lang="KO">제</span>12<span lang="KO">조제</span>1<span lang="KO">항의</span>&nbsp;<span lang="KO"
        >수신확인통지형태로</span
      >&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">도달한</span>&nbsp;<span lang="KO">시점에</span
      >&nbsp;<span lang="KO">계약이</span>&nbsp;<span lang="KO">성립한</span>&nbsp;<span lang="KO">것으로</span
      >&nbsp;<span lang="KO">봅니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”의</span>&nbsp;<span
        lang="KO"
        >승낙의</span
      >&nbsp;<span lang="KO">의사표시에는</span>&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">구매</span
      >&nbsp;<span lang="KO">신청에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">확인</span>&nbsp;<span
        lang="KO"
        >및</span
      >&nbsp;<span lang="KO">판매가능</span>&nbsp;<span lang="KO">여부</span>,&nbsp;<span lang="KO">구매신청의</span
      >&nbsp;<span lang="KO">정정</span>&nbsp;<span lang="KO">취소</span>&nbsp;<span lang="KO">등에</span>&nbsp;<span
        lang="KO"
        >관한</span
      >&nbsp;<span lang="KO">정보</span>&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">포함하여야</span
      >&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">제</span>11<span lang="KO">조 </span>(<span lang="KO">지급방법</span>)&nbsp;</h1>

    <p class="MsoNoSpacing">
      <span lang="KO">“몰”에서</span>&nbsp;<span lang="KO">구매한</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">용역에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">대금지급방법은</span
      >&nbsp;<span lang="KO">다음</span>&nbsp;<span lang="KO">각</span>&nbsp;<span lang="KO">호의</span>&nbsp;<span
        lang="KO"
        >방법 중</span
      >&nbsp;<span lang="KO">가용한</span>&nbsp;<span lang="KO">방법으로</span>&nbsp;<span lang="KO">할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있습니다</span>.&nbsp;<span lang="KO">단</span>,&nbsp;<span
        lang="KO"
        >“몰”은</span
      >&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">지급방법에</span>&nbsp;<span lang="KO">대하여</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">대금에</span>&nbsp;<span
        lang="KO"
        >어떠한</span
      >&nbsp;<span lang="KO">명목의</span>&nbsp;<span lang="KO">수수료도</span>&nbsp;&nbsp;<span lang="KO"
        >추가하여</span
      >&nbsp;<span lang="KO">징수할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">없습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">폰뱅킹</span>,&nbsp;<span lang="KO">인터넷뱅킹</span>,&nbsp;<span lang="KO">메일</span
      >&nbsp;<span lang="KO">뱅킹</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">각종</span>&nbsp;<span
        lang="KO"
        >계좌이체</span
      >
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">온라인무통장입금</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>12<span lang="KO">조 </span>(<span lang="KO">수신확인통지 · 구매신청</span>&nbsp;<span
        lang="KO"
        >변경</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">취소</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자의</span
      >&nbsp;<span lang="KO">구매신청이</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우</span
      >&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">수신확인통지를</span>&nbsp;<span lang="KO"
        >합니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">수신확인통지를</span>&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">이용자는</span
      >&nbsp;<span lang="KO">의사표시의</span>&nbsp;<span lang="KO">불일치</span>&nbsp;<span lang="KO">등이</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">수신확인통지를</span
      >&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">후</span>&nbsp;<span lang="KO">즉시</span>&nbsp;<span
        lang="KO"
        >구매신청</span
      >&nbsp;<span lang="KO">변경</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">취소를</span>&nbsp;<span
        lang="KO"
        >요청할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있고</span>&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >배송</span
      >&nbsp;<span lang="KO">전에</span>&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">요청이</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">지체</span
      >&nbsp;<span lang="KO">없이</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">요청에</span>&nbsp;<span
        lang="KO"
        >따라</span
      >&nbsp;<span lang="KO">처리하여야</span>&nbsp;<span lang="KO">합니다</span>.&nbsp;<span lang="KO">다만</span
      >&nbsp;<span lang="KO">이미</span>&nbsp;<span lang="KO">대금을</span>&nbsp;<span lang="KO">지불한</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">제</span>15<span lang="KO">조의</span>&nbsp;<span
        lang="KO"
        >청약철회</span
      >&nbsp;<span lang="KO">등에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">규정에</span>&nbsp;<span
        lang="KO"
        >따릅니다</span
      >.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>13<span lang="KO">조 </span>(<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span
      >&nbsp;<span lang="KO">공급</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자와</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">공급시기에</span
      >&nbsp;<span lang="KO">관하여</span>&nbsp;<span lang="KO">별도의</span>&nbsp;<span lang="KO">약정이</span
      >&nbsp;<span lang="KO">없는</span>&nbsp;<span lang="KO">이상</span>,&nbsp;<span lang="KO">이용자가</span
      >&nbsp;<span lang="KO">청약을</span>&nbsp;<span lang="KO">한</span>&nbsp;<span lang="KO">날부터</span>&nbsp;7<span
        lang="KO"
        >일</span
      >&nbsp;<span lang="KO">이내에</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등을</span>&nbsp;<span
        lang="KO"
        >배송할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span lang="KO">주문제작</span
      >,&nbsp;<span lang="KO">포장</span>&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">기타의</span>&nbsp;<span
        lang="KO"
        >필요한</span
      >&nbsp;<span lang="KO">조치를</span>&nbsp;<span lang="KO">취합니다</span>.&nbsp;<span lang="KO">다만</span
      >,&nbsp;<span lang="KO">“몰”이</span>&nbsp;<span lang="KO">이미</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span
        lang="KO"
        >등의</span
      >&nbsp;<span lang="KO">대금의</span>&nbsp;<span lang="KO">전부</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >일부를</span
      >&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">대금의</span
      >&nbsp;<span lang="KO">전부</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">일부를</span>&nbsp;<span
        lang="KO"
        >받은</span
      >&nbsp;<span lang="KO">날부터</span>&nbsp;3<span lang="KO">영업일</span>&nbsp;<span lang="KO">이내에</span
      >&nbsp;<span lang="KO">조치를</span>&nbsp;<span lang="KO">취합니다</span>.&nbsp;<span lang="KO">이 때</span
      >&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">공급</span>&nbsp;<span lang="KO">절차</span>&nbsp;<span
        lang="KO"
        >및</span
      >&nbsp;<span lang="KO">진행</span>&nbsp;<span lang="KO">사항을</span>&nbsp;<span lang="KO">확인할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span lang="KO">적절한</span>&nbsp;<span
        lang="KO"
        >조치를</span
      >&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">구매한</span>&nbsp;<span lang="KO">재화에</span>&nbsp;<span lang="KO">대해</span
      >&nbsp;<span lang="KO">배송수단</span>,&nbsp;<span lang="KO">수단별</span>&nbsp;<span lang="KO">배송비용</span
      >&nbsp;<span lang="KO">부담자</span>&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">명시합니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">제</span>14<span lang="KO">조 </span>(<span lang="KO">환급</span>)&nbsp;</h1>

    <p class="MsoNoSpacing">
      <span lang="KO">“몰”은</span>&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO">구매신청한</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등이</span>&nbsp;<span lang="KO">품절</span>&nbsp;<span
        lang="KO"
        >등의</span
      >&nbsp;<span lang="KO">사유로</span>&nbsp;<span lang="KO">인도</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >제공을</span
      >&nbsp;<span lang="KO">할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">없을</span>&nbsp;<span
        lang="KO"
        >때에는</span
      >&nbsp;<span lang="KO">지체</span>&nbsp;<span lang="KO">없이</span>&nbsp;<span lang="KO">그</span>&nbsp;<span
        lang="KO"
        >사유를</span
      >&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">통지하고</span>&nbsp;<span lang="KO">사전에</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">대금을</span>&nbsp;<span
        lang="KO"
        >받은</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">대금을</span>&nbsp;<span lang="KO">받은</span
      >&nbsp;<span lang="KO">날부터</span>&nbsp;3<span lang="KO">영업일</span>&nbsp;<span lang="KO">이내에</span
      >&nbsp;<span lang="KO">환급하거나</span>&nbsp;<span lang="KO">환급에</span>&nbsp;<span lang="KO">필요한</span
      >&nbsp;<span lang="KO">조치를</span>&nbsp;<span lang="KO">취합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>15<span lang="KO">조 </span>(<span lang="KO">청약철회</span>&nbsp;<span lang="KO"
        >등</span
      >)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”과</span>&nbsp;<span
        lang="KO"
        >재화 등의</span
      >&nbsp;<span lang="KO">구매에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">계약을</span
      >&nbsp;<span lang="KO">체결한</span>&nbsp;<span lang="KO">이용자는</span>&nbsp;<span lang="KO">「전자상거래</span
      >&nbsp;<span lang="KO">등에서의</span>&nbsp;<span lang="KO">소비자보호에</span>&nbsp;<span lang="KO">관한</span
      >&nbsp;<span lang="KO">법률」</span>&nbsp;<span lang="KO">제</span>13<span lang="KO">조</span>&nbsp;<span
        lang="KO"
        >제</span
      >2<span lang="KO">항에</span>&nbsp;<span lang="KO">따른</span>&nbsp;<span lang="KO">계약내용에</span>&nbsp;<span
        lang="KO"
        >관한</span
      >&nbsp;<span lang="KO">서면을</span>&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">날</span>(<span
        lang="KO"
        >그</span
      >&nbsp;<span lang="KO">서면을</span>&nbsp;<span lang="KO">받은</span>&nbsp;<span lang="KO">때보다</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">공급이</span>&nbsp;<span
        lang="KO"
        >늦게</span
      >&nbsp;<span lang="KO">이루어진</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">공급받거나</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">공급이</span>&nbsp;<span lang="KO">시작된</span
      >&nbsp;<span lang="KO">날을</span>&nbsp;<span lang="KO">말합니다</span>)<span lang="KO">부터</span>&nbsp;7<span
        lang="KO"
        >일</span
      >&nbsp;<span lang="KO">이내에는</span>&nbsp;<span lang="KO">청약의</span>&nbsp;<span lang="KO">철회를</span
      >&nbsp;<span lang="KO">할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있습니다</span>.&nbsp;<span
        lang="KO"
        >다만</span
      >,&nbsp;<span lang="KO">청약철회에</span>&nbsp;<span lang="KO">관하여</span>&nbsp;<span lang="KO"
        >「전자상거래</span
      >&nbsp;<span lang="KO">등에서의</span>&nbsp;<span lang="KO">소비자보호에</span>&nbsp;<span lang="KO">관한</span
      >&nbsp;<span lang="KO">법률」에</span>&nbsp;<span lang="KO">달리</span>&nbsp;<span lang="KO">정함이</span
      >&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">동</span>&nbsp;<span
        lang="KO"
        >법</span
      >&nbsp;<span lang="KO">규정에</span>&nbsp;<span lang="KO">따릅니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이용자는</span>&nbsp;<span
        lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">배송</span>&nbsp;<span lang="KO">받은</span>&nbsp;<span
        lang="KO"
        >경우</span
      >&nbsp;<span lang="KO">다음</span>&nbsp;<span lang="KO">각</span>&nbsp;<span lang="KO">호에</span>&nbsp;<span
        lang="KO"
        >해당하는</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">반품</span>&nbsp;<span lang="KO">및</span>&nbsp;<span
        lang="KO"
        >교환을</span
      >&nbsp;<span lang="KO">할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">없습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">이용자에게</span>&nbsp;<span lang="KO">책임</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span
        lang="KO"
        >사유로</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등이</span>&nbsp;<span lang="KO">멸실</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">훼손된</span>&nbsp;<span lang="KO">경우</span>(<span lang="KO">다만</span>,&nbsp;<span
        lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">내용을</span>&nbsp;<span lang="KO">확인하기</span
      >&nbsp;<span lang="KO">위하여</span>&nbsp;<span lang="KO">포장</span>&nbsp;<span lang="KO">등을</span>&nbsp;<span
        lang="KO"
        >훼손한</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">청약철회를</span>&nbsp;<span lang="KO">할</span
      >&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있습니다</span>)
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">이용자의</span>&nbsp;<span lang="KO">사용</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >일부</span
      >&nbsp;<span lang="KO">소비에</span>&nbsp;<span lang="KO">의하여</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">가치가</span>&nbsp;<span lang="KO">현저히</span
      >&nbsp;<span lang="KO">감소한</span>&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">시간의</span>&nbsp;<span lang="KO">경과에</span>&nbsp;<span lang="KO">의하여</span>&nbsp;<span
        lang="KO"
        >재판매가</span
      >&nbsp;<span lang="KO">곤란할</span>&nbsp;<span lang="KO">정도로</span>&nbsp;<span lang="KO">재화 등의</span
      >&nbsp;<span lang="KO">가치가</span>&nbsp;<span lang="KO">현저히</span>&nbsp;<span lang="KO">감소한</span
      >&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      4.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">같은</span>&nbsp;<span
        lang="KO"
        >성능을</span
      >&nbsp;<span lang="KO">지닌</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등으로</span>&nbsp;<span
        lang="KO"
        >복제가</span
      >&nbsp;<span lang="KO">가능한</span>&nbsp;<span lang="KO">경우</span>&nbsp;<span lang="KO">그</span>&nbsp;<span
        lang="KO"
        >원본인</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">포장을</span>&nbsp;<span
        lang="KO"
        >훼손한</span
      >&nbsp;<span lang="KO">경우</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">제</span>2<span lang="KO"
        >항 제</span
      >2<span lang="KO">호</span>&nbsp;<span lang="KO">내지</span>&nbsp;<span lang="KO">제</span>4<span lang="KO"
        >호의</span
      >&nbsp;<span lang="KO">경우에</span>&nbsp;<span lang="KO">“몰”이</span>&nbsp;<span lang="KO">사전에</span
      >&nbsp;<span lang="KO">청약철회</span>&nbsp;<span lang="KO">등이</span>&nbsp;<span lang="KO">제한되는</span
      >&nbsp;<span lang="KO">사실을</span>&nbsp;<span lang="KO">소비자가</span>&nbsp;<span lang="KO">쉽게</span
      >&nbsp;<span lang="KO">알</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span
        lang="KO"
        >곳에</span
      >&nbsp;<span lang="KO">명기하거나</span>&nbsp;<span lang="KO">시용상품을</span>&nbsp;<span lang="KO"
        >제공하는</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">조치를</span>&nbsp;<span lang="KO">하지</span>&nbsp;<span
        lang="KO"
        >않았다면</span
      >&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">청약철회</span>&nbsp;<span lang="KO">등이</span
      >&nbsp;<span lang="KO">제한되지</span>&nbsp;<span lang="KO">않습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-align: left; text-indent: -20pt" align="left">
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이용자는</span>&nbsp;<span
        lang="KO"
        >제</span
      >1<span lang="KO">항</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">제</span>2<span lang="KO"
        >항의</span
      >&nbsp;<span lang="KO">규정에</span>&nbsp;<span lang="KO">불구하고</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">내용이</span>&nbsp;<span lang="KO">표시 · 광고</span
      >&nbsp;<span lang="KO">내용과</span>&nbsp;<span lang="KO">다르거나</span>&nbsp;<span lang="KO">계약내용과</span
      >&nbsp;<span lang="KO">다르게</span>&nbsp;<span lang="KO">이행된</span>&nbsp;<span lang="KO">때에는</span
      >&nbsp;<span lang="KO">당해</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등을</span>&nbsp;<span
        lang="KO"
        >공급받은</span
      >&nbsp;<span lang="KO">날부터</span>&nbsp;3<span lang="KO">개월</span>&nbsp;<span lang="KO">이내</span>,
      <span lang="KO">그</span>&nbsp;<span lang="KO">사실을</span>&nbsp;<span lang="KO">안</span>&nbsp;<span lang="KO"
        >날</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">알</span>&nbsp;<span lang="KO">수</span>&nbsp;<span
        lang="KO"
        >있었던</span
      >&nbsp;<span lang="KO">날부터</span>&nbsp;30<span lang="KO">일</span>&nbsp;<span lang="KO">이내에</span
      >&nbsp;<span lang="KO">청약철회</span>&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">할</span>&nbsp;<span
        lang="KO"
        >수</span
      >&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="text-indent: 3.75pt">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>16<span lang="KO">조 </span>(<span lang="KO">청약철회</span>&nbsp;<span lang="KO"
        >등의</span
      >&nbsp;<span lang="KO">효과</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >위</span
      >&nbsp;<span lang="KO">대금을</span>&nbsp;<span lang="KO">환급함에</span>&nbsp;<span lang="KO">있어서</span
      >&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO">신용카드</span>&nbsp;<span lang="KO">또는</span
      >&nbsp;<span lang="KO">전자화폐</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">결제수단으로</span
      >&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">대금을</span>&nbsp;<span
        lang="KO"
        >지급한</span
      >&nbsp;<span lang="KO">때에는</span>&nbsp;<span lang="KO">지체</span>&nbsp;<span lang="KO">없이</span>&nbsp;<span
        lang="KO"
        >당해</span
      >&nbsp;<span lang="KO">결제수단을</span>&nbsp;<span lang="KO">제공한</span>&nbsp;<span lang="KO">사업자로</span
      >&nbsp;<span lang="KO">하여금</span>&nbsp;<span lang="KO">재화</span>&nbsp;<span lang="KO">등의</span>&nbsp;<span
        lang="KO"
        >대금의</span
      >&nbsp;<span lang="KO">청구를</span>&nbsp;<span lang="KO">정지</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >취소하도록</span
      >&nbsp;<span lang="KO">요청합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">청약철회</span>&nbsp;<span
        lang="KO"
        >등의</span
      >&nbsp;<span lang="KO">경우</span>&nbsp;<span lang="KO">공급받은</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">반환에</span>&nbsp;<span lang="KO">필요한</span
      >&nbsp;<span lang="KO">비용은</span>&nbsp;<span lang="KO">이용자가</span>&nbsp;<span lang="KO">부담합니다</span
      >.&nbsp;<span lang="KO">“몰”은</span>&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">청약철회</span
      >&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">이유로</span>&nbsp;<span lang="KO">위약금</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">손해배상을</span>&nbsp;<span lang="KO">청구하지</span
      >&nbsp;<span lang="KO">않습니다</span>.&nbsp;<span lang="KO">다만</span>&nbsp;<span lang="KO">재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">내용이</span>&nbsp;<span lang="KO">표시 · 광고</span
      >&nbsp;<span lang="KO">내용과</span>&nbsp;<span lang="KO">다르거나</span>&nbsp;<span lang="KO">계약내용과</span
      >&nbsp;<span lang="KO">다르게</span>&nbsp;<span lang="KO">이행되어</span>&nbsp;<span lang="KO">청약철회</span
      >&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">하는</span>&nbsp;<span lang="KO">경우</span>&nbsp;<span
        lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">반환에</span>&nbsp;<span lang="KO">필요한</span
      >&nbsp;<span lang="KO">비용은</span>&nbsp;<span lang="KO">“몰”이</span>&nbsp;<span lang="KO">부담합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이용자가</span>&nbsp;<span
        lang="KO"
        >재화</span
      >&nbsp;<span lang="KO">등을</span>&nbsp;<span lang="KO">제공받을</span>&nbsp;<span lang="KO">때</span>&nbsp;<span
        lang="KO"
        >발송비를</span
      >&nbsp;<span lang="KO">부담한</span>&nbsp;<span lang="KO">경우에</span>&nbsp;<span lang="KO">“몰”은</span
      >&nbsp;<span lang="KO">청약철회</span>&nbsp;<span lang="KO">시</span>&nbsp;<span lang="KO">그</span>&nbsp;<span
        lang="KO"
        >비용을</span
      >&nbsp;&nbsp;<span lang="KO">누가</span>&nbsp;<span lang="KO">부담하는지를</span>&nbsp;<span lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">알기</span>&nbsp;<span lang="KO">쉽도록</span>&nbsp;<span lang="KO">명확하게</span
      >&nbsp;<span lang="KO">표시합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">제</span>17<span lang="KO">조 </span>(<span lang="KO">개인정보보호</span>)</h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자의</span
      >&nbsp;<span lang="KO">개인정보</span>&nbsp;<span lang="KO">수집 시</span>&nbsp;<span lang="KO">서비스제공을</span
      >&nbsp;<span lang="KO">위하여</span>&nbsp;<span lang="KO">필요한</span>&nbsp;<span lang="KO">범위에서</span
      >&nbsp;<span lang="KO">최소한의</span>&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO"
        >수집합니다</span
      >.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >회원가입시</span
      >&nbsp;<span lang="KO">구매계약이행에</span>&nbsp;<span lang="KO">필요한</span>&nbsp;<span lang="KO">정보를</span
      >&nbsp;<span lang="KO">미리</span>&nbsp;<span lang="KO">수집하지</span>&nbsp;<span lang="KO">않습니다</span
      >.&nbsp;<span lang="KO">다만</span>,&nbsp;<span lang="KO">관련</span>&nbsp;<span lang="KO">법령상</span
      >&nbsp;<span lang="KO">의무이행을</span>&nbsp;<span lang="KO">위하여</span>&nbsp;<span lang="KO">구매계약</span
      >&nbsp;<span lang="KO">이전에</span>&nbsp;<span lang="KO">본인확인이</span>&nbsp;<span lang="KO">필요한</span
      >&nbsp;<span lang="KO">경우로서</span>&nbsp;<span lang="KO">최소한의</span>&nbsp;<span lang="KO">특정</span
      >&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">수집하는</span>&nbsp;<span lang="KO">경우에는</span
      >&nbsp;<span lang="KO">그러하지</span>&nbsp;<span lang="KO">아니합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자의</span
      >&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">수집 · 이용하는</span>&nbsp;<span lang="KO"
        >때에는</span
      >&nbsp;<span lang="KO">당해</span>&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">그</span
      >&nbsp;<span lang="KO">목적을</span>&nbsp;<span lang="KO">고지하고</span>&nbsp;<span lang="KO">동의를</span
      >&nbsp;<span lang="KO">받습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >수집된</span
      >&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">목적 외의</span>&nbsp;<span lang="KO">용도로</span
      >&nbsp;<span lang="KO">이용할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">없으며</span>,&nbsp;<span
        lang="KO"
        >새로운</span
      >&nbsp;<span lang="KO">이용목적이</span>&nbsp;<span lang="KO">발생한</span>&nbsp;<span lang="KO">경우</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">제</span>3<span lang="KO">자에게</span>&nbsp;<span
        lang="KO"
        >제공하는</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">이용 · 제공 단계에서</span>&nbsp;<span lang="KO"
        >당해</span
      >&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">그</span>&nbsp;<span lang="KO">목적을</span
      >&nbsp;<span lang="KO">고지하고</span>&nbsp;<span lang="KO">동의를</span>&nbsp;<span lang="KO">받습니다</span
      >.&nbsp;<span lang="KO">다만</span>,&nbsp;<span lang="KO">관련</span>&nbsp;<span lang="KO">법령에</span
      >&nbsp;<span lang="KO">달리</span>&nbsp;<span lang="KO">정함이</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span
        lang="KO"
        >경우에는</span
      >&nbsp;<span lang="KO">예외로</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑤<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >제</span
      >2<span lang="KO">항과</span>&nbsp;<span lang="KO">제</span>3<span lang="KO">항에</span>&nbsp;<span lang="KO"
        >의해</span
      >&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">동의를</span>&nbsp;<span lang="KO">받아야</span
      >&nbsp;<span lang="KO">하는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">개인정보관리</span
      >&nbsp;<span lang="KO">책임자의</span>&nbsp;<span lang="KO">신원</span>(<span lang="KO">소속</span>,&nbsp;<span
        lang="KO"
        >성명</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">전화번호</span>,&nbsp;<span lang="KO">기타</span>&nbsp;<span
        lang="KO"
        >연락처</span
      >),&nbsp;<span lang="KO">정보의</span>&nbsp;<span lang="KO">수집목적</span>&nbsp;<span lang="KO">및</span
      >&nbsp;<span lang="KO">이용목적</span>,&nbsp;<span lang="KO">제</span>3<span lang="KO">자에</span>&nbsp;<span
        lang="KO"
        >대한</span
      >&nbsp;<span lang="KO">정보제공</span>&nbsp;<span lang="KO">관련사항</span>(<span lang="KO">제공받은자</span
      >,&nbsp;<span lang="KO">제공목적</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">제공할</span
      >&nbsp;<span lang="KO">정보의</span>&nbsp;<span lang="KO">내용</span>)&nbsp;<span lang="KO">등</span>&nbsp;<span
        lang="KO"
        >「정보통신망</span
      >&nbsp;<span lang="KO">이용촉진</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">정보보호</span
      >&nbsp;<span lang="KO">등에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률」</span>&nbsp;<span
        lang="KO"
        >제</span
      >22<span lang="KO">조 제</span>2<span lang="KO">항이</span>&nbsp;<span lang="KO">규정한</span>&nbsp;<span
        lang="KO"
        >사항을</span
      >&nbsp;<span lang="KO">미리</span>&nbsp;<span lang="KO">명시하거나</span>&nbsp;<span lang="KO">고지해야</span
      >&nbsp;<span lang="KO">하며</span>&nbsp;<span lang="KO">이용자는</span>&nbsp;<span lang="KO">언제든지</span
      >&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">동의를</span>&nbsp;<span lang="KO">철회할</span>&nbsp;<span
        lang="KO"
        >수</span
      >&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑥<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이용자는</span>&nbsp;<span
        lang="KO"
        >언제든지</span
      >&nbsp;<span lang="KO">“몰”이</span>&nbsp;<span lang="KO">가지고</span>&nbsp;<span lang="KO">있는</span
      >&nbsp;<span lang="KO">자신의</span>&nbsp;<span lang="KO">개인정보에</span>&nbsp;<span lang="KO">대해</span
      >&nbsp;<span lang="KO">열람</span>&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">수정할 수 있습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑦<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">그로부터</span>&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">제공받은</span
      >&nbsp;<span lang="KO">제</span>3<span lang="KO">자는</span>&nbsp;<span lang="KO">개인정보의</span>&nbsp;<span
        lang="KO"
        >수집목적</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">제공받은</span>&nbsp;<span lang="KO">목적을</span
      >&nbsp;<span lang="KO">달성한</span>&nbsp;<span lang="KO">때에는</span>&nbsp;<span lang="KO">당해</span
      >&nbsp;<span lang="KO">개인정보를</span>&nbsp;<span lang="KO">지체</span>&nbsp;<span lang="KO">없이</span
      >&nbsp;<span lang="KO">파기합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑧<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >개인정보의</span
      >&nbsp;<span lang="KO">수집 · 이용 · 제공에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO"
        >동의</span
      >&nbsp;<span lang="KO">란을</span>&nbsp;<span lang="KO">미리</span>&nbsp;<span lang="KO">선택한</span>&nbsp;<span
        lang="KO"
        >것으로</span
      >&nbsp;<span lang="KO">설정해두지</span>&nbsp;<span lang="KO">않습니다</span>.&nbsp;<span lang="KO">또한</span
      >&nbsp;<span lang="KO">개인정보의</span>&nbsp;<span lang="KO">수집 · 이용 · 제공에</span>&nbsp;<span lang="KO"
        >관한</span
      >&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">동의 거절 시</span>&nbsp;<span lang="KO"
        >제한되는</span
      >&nbsp;<span lang="KO">서비스를</span>&nbsp;<span lang="KO">구체적으로</span>&nbsp;<span lang="KO">명시하고</span
      >,&nbsp;<span lang="KO">필수수집항목이</span>&nbsp;<span lang="KO">아닌</span>&nbsp;<span lang="KO"
        >개인정보의</span
      >&nbsp;<span lang="KO">수집 · 이용 · 제공에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO"
        >이용자의</span
      >&nbsp;<span lang="KO">동의</span>&nbsp;<span lang="KO">거절을</span>&nbsp;<span lang="KO">이유로</span
      >&nbsp;<span lang="KO">회원가입</span>&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">서비스</span
      >&nbsp;<span lang="KO">제공을</span>&nbsp;<span lang="KO">제한하거나</span>&nbsp;<span lang="KO">거절하지</span
      >&nbsp;<span lang="KO">않습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑨<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”은 회원의 귀책사유로 인해 노출된 회원 정보에 대해서 책임을 지지 않습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>18<span lang="KO">조 </span>(<span lang="KO">“몰“의</span>&nbsp;<span lang="KO"
        >의무</span
      >)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >법령과</span
      >&nbsp;<span lang="KO">이</span>&nbsp;<span lang="KO">약관이</span>&nbsp;<span lang="KO">금지하거나</span
      >&nbsp;<span lang="KO">공서양속에</span>&nbsp;<span lang="KO">반하는</span>&nbsp;<span lang="KO">행위를</span
      >&nbsp;<span lang="KO">하지</span>&nbsp;<span lang="KO">않으며</span>&nbsp;<span lang="KO">이</span>&nbsp;<span
        lang="KO"
        >약관이</span
      >&nbsp;<span lang="KO">정하는</span>&nbsp;<span lang="KO">바에</span>&nbsp;<span lang="KO">따라</span>&nbsp;<span
        lang="KO"
        >지속적이고</span
      >,&nbsp;<span lang="KO">안정적으로</span>&nbsp;<span lang="KO">재화 · 용역을</span>&nbsp;<span lang="KO"
        >제공하는데</span
      >&nbsp;<span lang="KO">최선을</span>&nbsp;<span lang="KO">다하여야</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">안전하게</span>&nbsp;<span lang="KO">인터넷</span>&nbsp;<span lang="KO">서비스를</span
      >&nbsp;<span lang="KO">이용할</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있도록</span>&nbsp;<span
        lang="KO"
        >이용자의</span
      >&nbsp;<span lang="KO">개인정보</span>(<span lang="KO">신용정보</span>&nbsp;<span lang="KO">포함</span>)<span
        lang="KO"
        >보호를</span
      >&nbsp;<span lang="KO">위한</span>&nbsp;<span lang="KO">보안</span>&nbsp;<span lang="KO">시스템을</span
      >&nbsp;<span lang="KO">갖추어야</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”이</span>&nbsp;<span
        lang="KO"
        >상품이나</span
      >&nbsp;<span lang="KO">용역에</span>&nbsp;<span lang="KO">대하여</span>&nbsp;<span lang="KO">「표시 · 광고의</span
      >&nbsp;<span lang="KO">공정화에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">법률」</span
      >&nbsp;<span lang="KO">제</span>3<span lang="KO">조</span>&nbsp;<span lang="KO">소정의</span>&nbsp;<span lang="KO"
        >부당한</span
      >&nbsp;<span lang="KO">표시 · 광고 행위를</span>&nbsp;<span lang="KO">함으로써</span>&nbsp;<span lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">손해를</span>&nbsp;<span lang="KO">입은</span>&nbsp;<span lang="KO">때에는</span
      >&nbsp;<span lang="KO">이를</span>&nbsp;<span lang="KO">배상할</span>&nbsp;<span lang="KO">책임을</span
      >&nbsp;<span lang="KO">집니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">원하지</span>&nbsp;<span lang="KO">않는</span>&nbsp;<span lang="KO">영리목적의</span
      >&nbsp;<span lang="KO">광고성</span>&nbsp;<span lang="KO">전자우편을</span>&nbsp;<span lang="KO">발송하지</span
      >&nbsp;<span lang="KO">않습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑤<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >서비스 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정될 경우에는 즉시 처리합니다</span
      >. <span lang="KO">다만</span>,
      <span lang="KO"
        >즉시 처리가 곤란한 경우에는 서비스 이용자에게 사전에 동의 받아 수집한 이메일 등으로 그 사유와 처리 일정을
        통보할 수 있습니다</span
      >.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>19<span lang="KO">조 </span>(<span lang="KO">회원의</span>&nbsp;ID&nbsp;<span lang="KO"
        >및</span
      >&nbsp;<span lang="KO">비밀번호에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">의무</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">제</span>17<span lang="KO"
        >조의</span
      >&nbsp;<span lang="KO">경우를</span>&nbsp;<span lang="KO">제외한</span>&nbsp;ID<span lang="KO">와</span
      >&nbsp;<span lang="KO">비밀번호에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">관리책임은</span
      >&nbsp;<span lang="KO">회원에게</span>&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">회원은</span>&nbsp;<span
        lang="KO"
        >자신의</span
      >&nbsp;ID&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">비밀번호를</span>&nbsp;<span lang="KO">제</span
      >3<span lang="KO">자에게</span>&nbsp;<span lang="KO">이용하게</span>&nbsp;<span lang="KO">해서는</span>&nbsp;<span
        lang="KO"
        >안됩니다</span
      >.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">회원이</span>&nbsp;<span
        lang="KO"
        >자신의</span
      >&nbsp;ID&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">비밀번호를</span>&nbsp;<span lang="KO"
        >도난 당하거나</span
      >&nbsp;<span lang="KO">제</span>3<span lang="KO">자가</span>&nbsp;<span lang="KO">사용하고</span>&nbsp;<span
        lang="KO"
        >있음을</span
      >&nbsp;<span lang="KO">인지한</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">바로</span
      >&nbsp;<span lang="KO">“몰”에</span>&nbsp;<span lang="KO">통보하고</span>&nbsp;<span lang="KO">“몰”의</span
      >&nbsp;<span lang="KO">안내가</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우에는</span
      >&nbsp;<span lang="KO">그에</span>&nbsp;<span lang="KO">따라야</span>&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>20<span lang="KO">조 </span>(<span lang="KO">이용자의</span>&nbsp;<span lang="KO"
        >의무</span
      >)&nbsp;
    </h1>

    <p class="MsoNoSpacing">
      <span lang="KO">이용자는</span>&nbsp;<span lang="KO">다음</span>&nbsp;<span lang="KO">행위를</span>&nbsp;<span
        lang="KO"
        >하여서는</span
      >&nbsp;<span lang="KO">안</span>&nbsp;<span lang="KO">됩니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">신청</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">변경 시</span>&nbsp;<span lang="KO">허위</span>&nbsp;<span lang="KO">내용의</span
      >&nbsp;<span lang="KO">등록</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">타인의</span>&nbsp;<span lang="KO">정보</span>&nbsp;<span lang="KO">도용</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”에</span>&nbsp;<span lang="KO">게시된</span>&nbsp;<span lang="KO">정보의</span>&nbsp;<span
        lang="KO"
        >변경</span
      >
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      4.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”이</span>&nbsp;<span lang="KO">정한</span>&nbsp;<span lang="KO">정보</span>&nbsp;<span
        lang="KO"
        >이외의</span
      >&nbsp;<span lang="KO">정보</span>(<span lang="KO">컴퓨터</span>&nbsp;<span lang="KO">프로그램</span>&nbsp;<span
        lang="KO"
        >등</span
      >)&nbsp;<span lang="KO">등의</span>&nbsp;<span lang="KO">송신</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span
        lang="KO"
        >게시</span
      >
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      5.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”과</span>&nbsp;<span lang="KO">기타</span>&nbsp;<span lang="KO">제</span>3<span lang="KO"
        >자의</span
      >&nbsp;<span lang="KO">저작권</span>&nbsp;<span lang="KO">등</span>&nbsp;<span lang="KO">지적재산권에</span
      >&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">침해</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      6.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">“몰”과</span>&nbsp;<span lang="KO">기타</span>&nbsp;<span lang="KO">제</span>3<span lang="KO"
        >자의</span
      >&nbsp;<span lang="KO">명예를</span>&nbsp;<span lang="KO">손상시키거나</span>&nbsp;<span lang="KO">업무를</span
      >&nbsp;<span lang="KO">방해하는</span>&nbsp;<span lang="KO">행위</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      7.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span><span lang="KO">외설</span>&nbsp;<span
        lang="KO"
        >또는</span
      >&nbsp;<span lang="KO">폭력적인</span>&nbsp;<span lang="KO">메시지</span>,&nbsp;<span lang="KO">화상</span
      >,&nbsp;<span lang="KO">음성</span>,&nbsp;<span lang="KO">기타</span>&nbsp;<span lang="KO">공서양속에</span
      >&nbsp;<span lang="KO">반하는</span>&nbsp;<span lang="KO">정보를</span>&nbsp;<span lang="KO">몰에</span
      >&nbsp;<span lang="KO">공개</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">게시하는</span
      >&nbsp;<span lang="KO">행위</span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>21<span lang="KO">조 </span>(<span lang="KO">저작권의</span>&nbsp;<span lang="KO"
        >귀속</span
      >&nbsp;<span lang="KO">및</span>&nbsp;<span lang="KO">이용제한</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰“이</span>&nbsp;<span
        lang="KO"
        >작성한</span
      >&nbsp;<span lang="KO">저작물에</span>&nbsp;<span lang="KO">대한</span>&nbsp;<span lang="KO">저작권</span
      >&nbsp;<span lang="KO">및 기타</span>&nbsp;<span lang="KO">지적재산권은</span>&nbsp;<span lang="KO">”몰“에</span
      >&nbsp;<span lang="KO">귀속합니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 20pt">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">이용자는</span>&nbsp;<span
        lang="KO"
        >“몰”을</span
      >&nbsp;<span lang="KO">이용함으로써</span>&nbsp;<span lang="KO">얻은</span>&nbsp;<span lang="KO">정보</span
      >&nbsp;<span lang="KO">중</span>&nbsp;<span lang="KO">“몰”에게</span>&nbsp;<span lang="KO">지적재산권이</span
      >&nbsp;<span lang="KO">귀속된</span>&nbsp;<span lang="KO">정보를</span>&nbsp;<span lang="KO">“몰”의</span
      >&nbsp;<span lang="KO">사전</span>&nbsp;<span lang="KO">승낙</span>&nbsp;<span lang="KO">없이</span>&nbsp;<span
        lang="KO"
        >복제</span
      >,&nbsp;<span lang="KO">송신</span>,&nbsp;<span lang="KO">출판</span>,&nbsp;<span lang="KO">배포</span
      >,&nbsp;<span lang="KO">방송</span>&nbsp;<span lang="KO">기타</span>&nbsp;<span lang="KO">방법에</span>&nbsp;<span
        lang="KO"
        >의하여</span
      >&nbsp;<span lang="KO">영리목적으로</span>&nbsp;<span lang="KO">이용하거나</span>&nbsp;<span lang="KO">제</span
      >3<span lang="KO">자에게</span>&nbsp;<span lang="KO">이용하게</span>&nbsp;<span lang="KO">하여서는</span
      >&nbsp;<span lang="KO">안됩니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >약정에</span
      >&nbsp;<span lang="KO">따라</span>&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">귀속된</span
      >&nbsp;<span lang="KO">저작권을</span>&nbsp;<span lang="KO">사용하는</span>&nbsp;<span lang="KO">경우</span
      >&nbsp;<span lang="KO">당해</span>&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">통보하여야</span
      >&nbsp;<span lang="KO">합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">제</span>22<span lang="KO">조 </span>(<span lang="KO">분쟁해결</span>)</h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자가</span
      >&nbsp;<span lang="KO">제기하는</span>&nbsp;<span lang="KO">정당한</span>&nbsp;<span lang="KO">의견이나</span
      >&nbsp;<span lang="KO">불만을</span>&nbsp;<span lang="KO">반영하고</span>&nbsp;<span lang="KO">그</span
      >&nbsp;<span lang="KO">피해를</span>&nbsp;<span lang="KO">보상처리하기</span>&nbsp;<span lang="KO">위하여</span
      >&nbsp;<span lang="KO">피해보상처리기구를</span>&nbsp;<span lang="KO">설치 · 운영합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”은</span>&nbsp;<span
        lang="KO"
        >이용자로부터</span
      >&nbsp;<span lang="KO">제출되는</span>&nbsp;<span lang="KO">불만사항</span>&nbsp;<span lang="KO">및</span
      >&nbsp;<span lang="KO">의견은</span>&nbsp;<span lang="KO">우선적으로</span>&nbsp;<span lang="KO">그</span
      >&nbsp;<span lang="KO">사항을</span>&nbsp;<span lang="KO">처리합니다</span>.&nbsp;<span lang="KO">다만</span
      >,&nbsp;<span lang="KO">신속한</span>&nbsp;<span lang="KO">처리가</span>&nbsp;<span lang="KO">곤란한</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">이용자에게</span>&nbsp;<span lang="KO">그</span
      >&nbsp;<span lang="KO">사유와</span>&nbsp;<span lang="KO">처리일정을</span>&nbsp;<span lang="KO">즉시</span
      >&nbsp;<span lang="KO">통보해</span>&nbsp;<span lang="KO">드립니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”과</span>&nbsp;<span
        lang="KO"
        >이용자</span
      >&nbsp;<span lang="KO">간에</span>&nbsp;<span lang="KO">발생한</span>&nbsp;<span lang="KO">전자상거래</span
      >&nbsp;<span lang="KO">분쟁과</span>&nbsp;<span lang="KO">관련하여</span>&nbsp;<span lang="KO">이용자의</span
      >&nbsp;<span lang="KO">피해구제신청이</span>&nbsp;<span lang="KO">있는</span>&nbsp;<span lang="KO">경우에는</span
      >&nbsp;<span lang="KO">공정거래위원회</span>&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO"
        >시 · 도지사가</span
      >&nbsp;<span lang="KO">의뢰하는</span>&nbsp;<span lang="KO">분쟁조정기관의</span>&nbsp;<span lang="KO"
        >조정에</span
      >&nbsp;<span lang="KO">따를</span>&nbsp;<span lang="KO">수</span>&nbsp;<span lang="KO">있습니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">제</span>23<span lang="KO">조 </span>(<span lang="KO">면책사항</span>)</h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자 또는 제</span
      >3<span lang="KO">자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다</span>.
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      1.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우 </span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      2.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">서비스의 효율적인 제공을 위한 시스템 개선</span>,
      <span lang="KO">장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우 </span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      3.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">이용자의 귀책사유로 서비스 이용에 장애가 있는 경우 </span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      4.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span
        lang="KO"
        >의 고의 과실이 없는 사유로 인한 경우
      </span>
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      5.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span
        lang="KO"
        >에게 회선</span
      >, <span lang="KO">통신망</span>,
      <span lang="KO">전용선을 제공하고 있는 이동통신사 또는 부가통신사업자 측의 장애</span> ·
      <span lang="KO">귀책사유로 인한 서비스의 불완전 또는 불능으로 이용자 또는 제</span>3<span lang="KO"
        >자에게 야기된 손해</span
      >
    </p>

    <p class="MsoNoSpacing" style="margin-left: 40pt; text-indent: -20pt">
      6.<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp; </span
      ><span lang="KO">이용자의 디바이스 환경으로 인하여 발생하는 제반 문제인 경우</span>
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 이용자가 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터
        발생하거나 기대하는 손익 등에 대하여 책임을 면합니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ③<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 서비스 이용의 장애로 인하여 발생한 이용자의 부가적</span
      >, <span lang="KO">영업적인 손해에 대해서는 책임을 지지 않습니다</span>.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ④<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 이용자가 게시 또는 전송한 데이터의 내용에 대해서는 책임을 면합니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑤<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 이용자 상호 간 또는 이용자와 제</span
      >3<span lang="KO">자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임을 면합니다</span>.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑥<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑦<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 이용자가 서비스 이용 정지 또는 종료 후 제</span
      >7<span lang="KO"
        >조에 따라 파기된 데이터와 이용자가 직접 삭제를 요청한 데이터에 대해서는 책임을 지지 않습니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑧<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 이용자가 서비스를 통하여 전송한 메시지의 내용에 대해서는 그 정확성</span
      >, <span lang="KO">신뢰성</span>,
      <span lang="KO">시기적절성 등을 보장하지 않으며 해당 내용이 관현 법령을 위반하거나 제</span>3<span lang="KO"
        >자의 권리를 침해하는 경우 이에 대한 책임을 지지 않습니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑨<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 </span
      >“<span lang="KO">몰</span>”<span lang="KO"
        >의 고의 또는 과실이 없는 한 서비스를 활용하여 발송된 메시지 관련 발신자와 수신자 간 분쟁에 대하여 개입할 의무가
        없으며 이로 인한 손해를 배상할 책임이 없습니다</span
      >.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ⑩<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span>“<span lang="KO">몰</span>”<span lang="KO"
        >은 제</span
      >7<span lang="KO">조에 따라 이용자의 서비스 이용을 정지 또는 제한하는 경우</span>,
      <span lang="KO">이용의 제한으로 발생할 수 있는 이용자의 손해 등에 대해서는 책임이 면제됩니다</span>.&nbsp;
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1>
      <span lang="KO">제</span>24<span lang="KO">조 </span>(<span lang="KO">재판권</span>&nbsp;<span lang="KO">및</span
      >&nbsp;<span lang="KO">준거법</span>)
    </h1>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ①<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”과</span>&nbsp;<span
        lang="KO"
        >이용자</span
      >&nbsp;<span lang="KO">간에</span>&nbsp;<span lang="KO">발생한</span>&nbsp;<span lang="KO">전자상거래</span
      >&nbsp;<span lang="KO">분쟁에</span>&nbsp;<span lang="KO">관한</span>&nbsp;<span lang="KO">소송은</span
      >&nbsp;<span lang="KO">제소</span>&nbsp;<span lang="KO">당시의</span>&nbsp;<span lang="KO">이용자의</span
      >&nbsp;<span lang="KO">주소에</span>&nbsp;<span lang="KO">의하고</span>,&nbsp;<span lang="KO">주소가</span
      >&nbsp;<span lang="KO">없는</span>&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">거소를</span
      >&nbsp;<span lang="KO">관할하는</span>&nbsp;<span lang="KO">지방법원의</span>&nbsp;<span lang="KO"
        >전속관할로</span
      >&nbsp;<span lang="KO">합니다</span>.&nbsp;<span lang="KO">다만</span>,&nbsp;<span lang="KO">제소</span
      >&nbsp;<span lang="KO">당시</span>&nbsp;<span lang="KO">이용자의</span>&nbsp;<span lang="KO">주소</span
      >&nbsp;<span lang="KO">또는</span>&nbsp;<span lang="KO">거소가</span>&nbsp;<span lang="KO">분명하지</span
      >&nbsp;<span lang="KO">않거나</span>&nbsp;<span lang="KO">외국</span>&nbsp;<span lang="KO">거주자의</span
      >&nbsp;<span lang="KO">경우에는</span>&nbsp;<span lang="KO">민사소송법상의</span>&nbsp;<span lang="KO"
        >관할법원에</span
      >&nbsp;<span lang="KO">제기합니다</span>.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <p class="MsoNoSpacing" style="margin-left: 20pt; text-indent: -20pt">
      ②<span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp;&nbsp; </span><span lang="KO">“몰”과</span>&nbsp;<span
        lang="KO"
        >이용자</span
      >&nbsp;<span lang="KO">간에</span>&nbsp;<span lang="KO">제기된</span>&nbsp;<span lang="KO">전자상거래</span
      >&nbsp;<span lang="KO">소송에는</span>&nbsp;<span lang="KO">한국법을</span>&nbsp;<span lang="KO">적용합니다</span
      >.
    </p>

    <p class="MsoNoSpacing">&nbsp;</p>

    <h1><span lang="KO">부칙</span></h1>

    <p
      class="MsoNormal"
      style="
        text-align: left;
        line-height: normal;
        background: white;
        text-autospace: ideograph-numeric ideograph-other;
        word-break: normal;
      "
      align="left"
    >
      <span style="font-size: 11pt; color: black" lang="KO">서비스 이용약관 시행일자</span
      ><span style="font-size: 11pt; color: black">
        : 2023<span lang="KO">년 </span>1<span lang="KO">월</span> 1<span lang="KO">일</span>&nbsp;</span
      >
    </p>

    <p class="MsoNormal" style="text-align: left" align="left">
      <span style="font-size: 11pt; line-height: 107%">&nbsp;</span>
    </p>
  </div>
</body>
`
    }
  }

  return (
    <div
      style={{margin: 'auto', position: 'relative', width: '100%', height: '1000px', overflow: 'hidden'}}
      dangerouslySetInnerHTML={iframePart()}
    />
  )
}
