import {MenuProps} from 'antd/lib/menu'
import {Link, useLocation} from 'react-router-dom'
import React, {useEffect, useMemo} from 'react'
import {Badge, Menu} from 'antd'
import {
  AuditOutlined,
  CalendarOutlined,
  CloudDownloadOutlined,
  GiftOutlined,
  ShopOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons'
import {routes} from '../../routes'
import {getAppCart} from '../../api/cart'
import {useRecoilState} from 'recoil'
import {cartTotalState} from '../../states/cartTotal.state'

export const defaultItems = (cartTotal?) => [
  {label: '마이 페이지', key: '/console/vendor', icon: <ShopOutlined />},
  {label: '공지사항', key: '/console/notice', icon: <AuditOutlined />},
  {label: '자료실', key: '/console/data', icon: <CloudDownloadOutlined />},
  {label: '주문하기', key: '/console/product', icon: <GiftOutlined />},
  {
    label: (
      <>
        장바구니 <Badge style={{marginTop: -4}} count={cartTotal || 0} />
      </>
    ),
    key: '/console/cart',
    icon: <ShoppingCartOutlined />
  },
  {label: '구매내역', key: '/console/order', icon: <AuditOutlined />},
  {label: 'SAP 내역 캘린더', key: '/console/sapLog', icon: <CalendarOutlined />}
]

function getMenuItems(cartTotal) {
  const filteredItems: any[] = []
  const consoleRoutes = routes.find((r) => r.path === '/console')

  if (consoleRoutes) {
    defaultItems(cartTotal).forEach((item) =>
      filteredItems.push({...item, label: <Link to={item.key}>{item.label}</Link>})
    )
  }

  return filteredItems
}

export default function SideMenu(props: MenuProps) {
  const [cartTotal, setCartTotal] = useRecoilState(cartTotalState)
  const location = useLocation()
  const items = useMemo(() => {
    return getMenuItems(cartTotal)
  }, [cartTotal])

  async function onCartTotalFetch() {
    const res = await getAppCart()
    setCartTotal(res.total)
  }

  useEffect(() => {
    onCartTotalFetch()
  }, [])

  return (
    <Menu
      {...props}
      items={items}
      selectedKeys={[location.pathname]}
      defaultOpenKeys={[location.pathname.split('/', 3).join('/')]}
    />
  )
}
