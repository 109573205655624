/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef, RefObject, useEffect, useState} from 'react'
import {Tag as AntdTag, Col, Descriptions, Form, List, Row, Space, Typography} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {IProduct} from '../../../../api/products'
import {getOrder, IOrder} from '../../../../api/orders'
import styled from 'styled-components'
import ImageView from '../../../../components/ImageView'
import Tag from '../../../../components/common/Tag'
import {useRecoilValue} from 'recoil'
import {userState} from '../../../../states/user.state'
import {getFileCheck} from '../../../../api/files'
import {FileOutlined} from '@ant-design/icons'
import downloadFile from '../../../../libs/downloadFile'

interface ModalProps {
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

function Show({record}: {record: number}) {
  const [data, setData] = useState<IOrder[]>([])
  const user: any = useRecoilValue(userState)
  const [impressionFileNames, setImpressionFileNames] = useState<Object>([])
  const [addFileNames, setAddFileNames] = useState<Object>([])

  async function importFileNames() {
    const impressionFileNames: Object = {}
    const addFileNames: Object = {}

    for (const orderItem of data) {
      for (const {url} of orderItem?.iteOptionInfo?.earImpressionFiles) {
        const slashSplitedPath = url.split('/')
        const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
        impressionFileNames[url] = fileName
      }

      for (const {url} of orderItem?.iteOptionInfo?.addFiles) {
        const slashSplitedPath = url.split('/')
        const {fileName} = await getFileCheck({path: slashSplitedPath[slashSplitedPath.length - 1]})
        addFileNames[url] = fileName
      }
    }

    setImpressionFileNames(impressionFileNames)
    setAddFileNames(addFileNames)
  }

  const orderStatus = {
    registered: '주문 접수',
    confirmed: '주문 접수 완료',
    inspection: '검수 완료'
  }

  useEffect(() => {
    ;(async function () {
      const {data} = await getOrder(record)
      setData(data)
    })()
  }, [record])

  useEffect(() => {
    importFileNames()
  }, [])

  return (
    <Wrapper>
      <List
        itemLayout="vertical"
        pagination={{pageSize: 5}}
        dataSource={data}
        renderItem={(item: any, index: number) => (
          <List.Item key={item?.orderId} style={{width: '100%'}}>
            <List.Item.Meta
              style={{width: '100%'}}
              title={
                <Space style={{margin: '5px 0'}}>
                  <Tag name="브랜드" value={item?.productInfo?.brand} />
                  <Tag name="제품 유형" value={item?.productInfo?.orderType} />
                  <Title level={3} style={{marginBottom: 0}}>
                    {item?.productInfo?.name}
                  </Title>
                </Space>
              }
              description={
                <div style={{width: '100%'}}>
                  <Row gutter={50} style={{marginBottom: '10px'}}>
                    <Col span={6}>
                      <ImageView src={item?.productInfo?.titleImage} width={233} height={233} />
                    </Col>
                    <InfoContainer span={18}>
                      <Descriptions size="small" title="기본 상품 옵션" bordered>
                        <Descriptions.Item label="등급">{item?.productInfo?.grade || '-'}</Descriptions.Item>
                        <Descriptions.Item label="컬러">{item?.productInfo?.color || '-'}</Descriptions.Item>
                        <Descriptions.Item label="후크">
                          {item?.basicOptionInfo?.hooks[0] ? item?.basicOptionInfo?.hooks[0].name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="충전기">
                          {item?.basicOptionInfo?.charges[0]
                            ? item?.basicOptionInfo?.charges.map(({name}) => <AntdTag>{name}</AntdTag>)
                            : '-'}
                        </Descriptions.Item>
                        {item?.basicOptionInfo?.receivers?.length && (
                          <Descriptions.Item label="리시버">
                            {item?.basicOptionInfo?.receivers[0]?.product &&
                              item?.basicOptionInfo?.receivers
                                ?.filter((receiver) => receiver?.product.productReceiverDirection)
                                .map((receiver: any) => (
                                  <AntdTag>
                                    <>
                                      {receiver?.product.productReceiverDirection?.name}
                                      {' / '}
                                      {receiver?.product.productReceiverLength?.name}
                                      {receiver?.product.productReceiverPower
                                        ? ` / ${receiver?.product.productReceiverPower?.name}`
                                        : ''}
                                    </>
                                  </AntdTag>
                                ))}
                            {!item?.basicOptionInfo?.receivers[0]?.product &&
                              item?.basicOptionInfo?.receivers?.map((receiver: any) => (
                                <AntdTag>
                                  <>
                                    {receiver.productReceiverDirection.name}
                                    {' / '}
                                    {receiver.productReceiverLength.name}
                                    {receiver?.productReceiverPower ? ` / ${receiver?.productReceiverPower.name}` : ''}
                                  </>
                                </AntdTag>
                              ))}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="착용자명">
                          {item.productInfo?.weakName && item.productInfo?.weakName !== item.productInfo?.name
                            ? item.productInfo.weakName
                            : '-'}
                        </Descriptions.Item>
                        {!!Object.keys(item?.iteOptionInfo).length && (
                          <>
                            <Descriptions.Item label="제작요청사항">
                              {item.iteOptionInfo?.memo || '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="귓본파일">
                              {item?.iteOptionInfo?.earImpressionFiles.length
                                ? item?.iteOptionInfo?.earImpressionFiles.map(({url}) => (
                                    <a
                                      onClick={(e) => {
                                        e.preventDefault()
                                        downloadFile(
                                          url,
                                          impressionFileNames[url] || url.split('/')[url.split('/').length - 1]
                                        )
                                      }}
                                    >
                                      <FileOutlined />
                                      {impressionFileNames[url] || url.split('/')[url.split('/').length - 1]}
                                    </a>
                                  ))
                                : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="제작참고사진">
                              {item?.iteOptionInfo?.addFiles.length
                                ? item?.iteOptionInfo?.addFiles.map(({url}) => (
                                    <a
                                      onClick={(e) => {
                                        e.preventDefault()
                                        downloadFile(
                                          url,
                                          impressionFileNames[url] || url.split('/')[url.split('/').length - 1]
                                        )
                                      }}
                                    >
                                      <FileOutlined />
                                      {addFileNames[url] || url.split('/')[url.split('/').length - 1]}
                                    </a>
                                  ))
                                : '-'}
                            </Descriptions.Item>
                          </>
                        )}
                      </Descriptions>
                      <Descriptions size="small" bordered title="결제 정보">
                        <Descriptions.Item label="구매자명">{item?.buyerName}</Descriptions.Item>
                        <Descriptions.Item label="주문상태">{orderStatus[item?.orderStatus]}</Descriptions.Item>
                        <Descriptions.Item label="수량">{item?.quantity}</Descriptions.Item>
                        {user.isPricePublic && <Descriptions.Item label="총 금액">{item?.amount}원</Descriptions.Item>}
                        <Descriptions.Item label="사용 쿠폰">
                          {item.couponInfo.length
                            ? item.couponInfo.map(({name, type}) => (
                                <AntdTag style={{padding: 5}} color={process.env.REACT_APP_THEME_COLOR}>
                                  <AntdTag color="white" style={{color: process.env.REACT_APP_THEME_COLOR}}>
                                    {type === 'price' ? '가격할인' : '번들/특별'}
                                  </AntdTag>
                                  {name}
                                </AntdTag>
                              ))
                            : '-'}
                        </Descriptions.Item>
                      </Descriptions>
                    </InfoContainer>
                  </Row>
                  {!!Object.keys(item?.iteOptionInfo).length && (
                    <Row gutter={12}>
                      <Col span={12} style={{marginBottom: '10px'}}>
                        <Descriptions size="small" title="ITE 옵션 - 좌측" column={2} bordered>
                          <Descriptions.Item label="쉘 타입">
                            {item?.iteOptionInfo?.leftInfo?.productShellTypes?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="쉘 색상">
                            {item?.iteOptionInfo?.leftInfo?.productShellColors?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="쉘 사이즈">
                            {item?.iteOptionInfo?.leftInfo?.productShellSizes?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="플레이트">
                            {item?.iteOptionInfo?.leftInfo?.productPlates?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="출력">
                            {item?.iteOptionInfo?.leftInfo?.productOutputs?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="벤트">
                            {item?.iteOptionInfo?.leftInfo?.productBents?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="벤트 스타일">
                            {item?.iteOptionInfo?.leftInfo?.productBentStyles?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="손잡이">
                            {item?.iteOptionInfo?.leftInfo?.productHandles?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="귀지 방지 옵션">
                            {item?.iteOptionInfo?.leftInfo?.productEarwaxOptions?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="코팅">
                            {item?.iteOptionInfo?.leftInfo?.productCoatings?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="카날 길이">
                            {item?.iteOptionInfo?.leftInfo?.productCanalLengths?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="무선">
                            {item?.iteOptionInfo?.leftInfo?.productWireless?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="청력도">
                            {item?.iteOptionInfo?.leftInfo?.productAudiograms.length
                              ? item?.iteOptionInfo?.leftInfo?.productAudiograms.map(({name, value}) => (
                                  <>
                                    <AntdTag>{`${name}: ${value}`}</AntdTag>
                                    <br />
                                  </>
                                ))
                              : '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="볼륨 조절기">
                            {item?.iteOptionInfo?.leftInfo?.volumeControllers?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="프로그램 버튼">
                            {item?.iteOptionInfo?.leftInfo?.programButtons?.name || '-'}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={12} style={{marginBottom: '12px'}}>
                        <Descriptions size="small" title="ITE 옵션 - 우측" column={2} bordered>
                          <Descriptions.Item label="쉘 타입">
                            {item?.iteOptionInfo?.rightInfo?.productShellTypes?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="쉘 색상">
                            {item?.iteOptionInfo?.rightInfo?.productShellColors?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="쉘 사이즈">
                            {item?.iteOptionInfo?.rightInfo?.productShellSizes?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="플레이트">
                            {item?.iteOptionInfo?.rightInfo?.productPlates?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="출력">
                            {item?.iteOptionInfo?.rightInfo?.productOutputs?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="벤트">
                            {item?.iteOptionInfo?.rightInfo?.productBents?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="벤트 스타일">
                            {item?.iteOptionInfo?.rightInfo?.productBentStyles?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="손잡이">
                            {item?.iteOptionInfo?.rightInfo?.productHandles?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="귀지 방지 옵션">
                            {item?.iteOptionInfo?.rightInfo?.productEarwaxOptions?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="코팅">
                            {item?.iteOptionInfo?.rightInfo?.productCoatings?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="카날 길이">
                            {item?.iteOptionInfo?.rightInfo?.productCanalLengths?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="무선">
                            {item?.iteOptionInfo?.rightInfo?.productWireless?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="청력도">
                            {item?.iteOptionInfo?.rightInfo?.productAudiograms.length
                              ? item?.iteOptionInfo?.rightInfo?.productAudiograms.map(({name, value}) => (
                                  <>
                                    <AntdTag>{`${name}: ${value}`}</AntdTag>
                                    <br />
                                  </>
                                ))
                              : '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="볼륨 조절기">
                            {item?.iteOptionInfo?.rightInfo?.volumeControllers?.name || '-'}
                          </Descriptions.Item>
                          <Descriptions.Item label="프로그램 버튼">
                            {item?.iteOptionInfo?.rightInfo?.programButtons?.name || '-'}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={24}>
                      <Descriptions size="small" bordered title="배송 정보">
                        <Descriptions.Item label="배송 유형">
                          {item?.deliveryInfo?.deliveryType} {item?.parcel?.name ? `(${item?.parcel?.name})` : ''}
                        </Descriptions.Item>
                        <Descriptions.Item label="수령지">{item?.deliveryInfo?.addressName}</Descriptions.Item>
                        <Descriptions.Item label="주소">
                          {item?.deliveryInfo?.address} {item?.deliveryInfo?.addressDetail}
                        </Descriptions.Item>
                        <Descriptions.Item label="수령자명">{item?.deliveryInfo?.name}</Descriptions.Item>
                        <Descriptions.Item label="전화번호">{item?.deliveryInfo?.phone}</Descriptions.Item>
                        <Descriptions.Item label="메모">{item?.deliveryInfo?.memo}</Descriptions.Item>
                      </Descriptions>
                    </Col>
                  </Row>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Wrapper>
  )
}

const OrderModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '주문서 ', onAction, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={1200}
      onAction={onAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} />
          </Form>
        )
      }}
    />
  )
})

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 30px 20px 30px;

  .ant-descriptions-header {
    margin-bottom: 5px;
  }

  .ant-descriptions-item-label {
    min-width: 114px;
  }

  .ant-descriptions-item-content {
    max-width: 350px;
  }
`

const InfoContainer = styled(Col)`
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 15px;
  }
`

const Title = styled(Typography.Title)`
  font-size: 20px;
`

export default OrderModal
