import {RouteObject} from 'react-router'
import Cart from '../pages/console/cart'
import Product from '../pages/console/product'
import Vendor from '../pages/console/vendor'
import Order from '../pages/console/order'
import Notice from '../pages/console/notice'
import Data from '../pages/console/data'
import DataItem from '../pages/console/data/{id}'
import SapLog from '../pages/sapLog'

export const vendorRoute: RouteObject = {
  path: '/console/vendor',
  element: <Vendor />
}

export const cartRoute: RouteObject = {
  path: '/console/cart',
  element: <Cart />
}

export const productRoute: RouteObject = {
  path: '/console/product',
  element: <Product />
}

export const orderRoute: RouteObject = {
  path: '/console/order',
  element: <Order />
}

export const noticeRoute: RouteObject = {
  path: '/console/notice',
  element: <Notice />
}

export const dataRoute: RouteObject = {
  path: '/console/data',
  element: <Data />
}

export const dataItemRoute: RouteObject = {
  path: '/console/data/:id',
  element: <DataItem />
}

export const sapRoute: RouteObject = {
  path: '/console/sapLog',
  element: <SapLog />
}
