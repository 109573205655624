import {IHook} from './cart'
import api from './index'
import {IProductInfo, IteOptionInfo} from './orders'
import {ICommon, IProduct} from './products'
import {IVendor} from './vendors'

export interface ICart {
  hidden: boolean
  id: number
  vendor: IVendor
  productGrade: ICommon
  product: IProduct
  color: ICommon
  hook: IHook
  charger: ICommon
  productReceiverDirection: ICommon
  productReceiverLength: ICommon
  productReceiverPower: ICommon
  quantity: number
  memo: string
  createdAt: Date
  updatedAt: Date
}

export interface IOrderSheet {
  id: string
  productInfo: IProductInfo
  basicOptionInfo: IProductInfo
  iteOptionInfo: IteOptionInfo
  quantity: number
}

interface ICartItem {
  id: number
  quantity: number
  couponIds?: number[]
}

interface IPostOrderSheet {
  carts: ICartItem[]
}

export async function postOrderSheet(cartIds: IPostOrderSheet): Promise<{id: number}> {
  try {
    const res = await api.post(`/orderSheets`, cartIds)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOrderSheet(id: number): Promise<any> {
  try {
    const res = await api.get(`/orderSheets/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
