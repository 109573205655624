import {Button, FormInstance, Modal, Upload} from 'antd'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import type {UploadFile} from 'antd/es/upload/interface'
import {FileOutlined} from '@ant-design/icons'
import {getFileUpload, postFileUpload} from '../api/files'

interface AvatarUploadProps {
  type?: 'string' | 'array'
  kind: 'banners' | 'vendors' | 'products' | 'users' | 'etc'
  value?: any
  arrayKey?: string
  maxLength?: number
  onChange?: any
  multiple?: boolean
  disabled?: boolean
  form?: FormInstance<any>
}

export default function FileUpload(props: AvatarUploadProps) {
  const {type = 'string', onChange, value, multiple} = props
  const [fileList, setFileList] = useState<UploadFile[]>([])

  async function handleChange({fileList, file}: any) {
    setFileList(fileList)
    if (!fileList.filter(({status}) => status === 'uploading').length) {
      const ret = fileList.filter((f) => f.url).map((f) => f.url)
      if (type === 'string') {
        onChange(ret[0])
      } else {
        if (props.arrayKey) {
          onChange(ret?.map((item) => ({[props.arrayKey as any]: item})))
        } else {
          onChange(ret)
        }
      }
    }
  }

  useEffect(() => {
    const arr: any = []
    if (typeof value === 'string') {
      arr.push(value)
    } else if (Array.isArray(value)) {
      if (props.arrayKey) {
        arr.push(...value.filter((v: any) => v[props.arrayKey as any]).map((v) => v[props.arrayKey as any]))
      } else {
        arr.push(...value)
      }
    }

    const newFileList = arr.map((item, i) => {
      const file = fileList.find((f) => f.url === item)
      const splitedUrl = item ? (item.url ? item.url.split('/') : item.split('/')) : []

      return item
        ? {
            uid: file ? file.uid : `${-i}`,
            name: file ? file.name : splitedUrl[splitedUrl.length - 1],
            url: item.url ? item.url : item
          }
        : null
    })
    setFileList(newFileList)
  }, [value])

  async function customRequest(options) {
    const {file, onProgress, onSuccess} = options

    try {
      const data = await getFileUpload({
        type: file.type.split('/')[0] === 'image' ? 'image' : 'file',
        mimeType: file?.type || 'application/octet-stream'
      })

      await axios.put(data.url, file, {
        headers: {
          'Content-Type': file.type || 'application/octet-stream'
        },
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(progressEvent.loaded * 10000)
          if (onProgress) onProgress({percent: percentCompleted})
        }
      })

      const {url} = await postFileUpload({
        type: file.type.split('/')[0] === 'image' ? 'image' : 'file',
        path: data.path,
        kind: 'products',
        name: file.name
      })

      const splitedSrc = url ? url.split('/') : []
      //splitedSrc.length && splitedSrc.splice(3, 0, 'undefined')

      file.url = splitedSrc.join('/')
      onSuccess()
    } catch (e) {
      Modal.error({
        content: '파일 업로드 도중 오류가 발생했습니다. 용량이 2GB를 넘을 경우 업로드가 어려울 수 있습니다.'
      })
      if (props.form) props.form.setFieldValue(props.multiple ? 'files' : 'file', null)
      throw e
    }
  }

  return (
    <>
      <Upload
        multiple={multiple}
        fileList={fileList}
        customRequest={customRequest}
        onChange={handleChange}
        disabled={props.disabled}
      >
        <Button type="primary" shape="round" disabled={props.disabled}>
          <FileOutlined />
          파일 선택
        </Button>
      </Upload>
    </>
  )
}
