import React, {useContext} from 'react'
import BasicTable from '../../../components/common/BasicTable'
import withPageContext from '../../../hocs/withPageContext'
import PageContext from '../../../contexts/PageContext'
import dayjsKorean from '../../../libs/DayjsKorean'
import {Space} from 'antd'
import {getDataGroups} from '../../../api/dataGroups'
import ImageView from '../../../components/ImageView'
import {useNavigate} from 'react-router'
import {FolderOutlined} from '@ant-design/icons'

function Datas() {
  const {state} = useContext(PageContext)
  const navigate = useNavigate()

  const folderColumns = [
    {
      dataIndex: 'sortOrder',
      width: 70,
      title: <div style={{textAlign: 'center'}}>번호</div>,
      render: (sortOrder) => <div style={{textAlign: 'center'}}>{sortOrder}</div>
    },
    {
      width: 30,
      dataIndex: 'image',
      render: (image) => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          {image ? (
            <ImageView src={image} width={50} height={50} />
          ) : (
            <div style={{width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <FolderOutlined style={{fontSize: 20, color: 'gray'}} />
            </div>
          )}
        </div>
      )
    },
    {
      dataIndex: 'name',
      title: <div style={{textAlign: 'center'}}>폴더명</div>,
      render: (name, {data}) => (
        <Space>
          <div>{name}</div>
        </Space>
      )
    },
    {
      title: <div style={{textAlign: 'center'}}>등록일</div>,
      dataIndex: 'createdAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 150
    },
    {
      title: <div style={{textAlign: 'center'}}>수정일</div>,
      dataIndex: 'updatedAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 150
    }
  ]

  return (
    <BasicTable
      size="small"
      rowKey="id"
      columns={folderColumns}
      loading={state.loading}
      dataSource={state.data?.data?.filter(({parentId}) => !parentId).sort((a, b) => a.sortOrder - b.sortOrder)}
      total={state.data.total}
      onRow={(record: any, rowIndex) => ({
        onClick: (e) => {
          navigate('/console/data/' + record.id)
        }
      })}
    />
  )
}

export default withPageContext(Datas, getDataGroups)
