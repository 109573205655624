import React, {useContext, useEffect, useRef, useState} from 'react'
import BasicTable from '../../../components/common/BasicTable'
import withPageContext from '../../../hocs/withPageContext'
import PageContext from '../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../components/common/BasicTableModal'
import NoticeModal from './components/NoticeModal'
import {getNotices} from '../../../api/notices'
import dayjsKorean from '../../../libs/DayjsKorean'
import {PushpinOutlined} from '@ant-design/icons'

function Notices() {
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const [list, setList] = useState<any>([])

  const columns = [
    {
      dataIndex: 'isFixation',
      render: (isFixation) => (isFixation ? <PushpinOutlined style={{color: '#d54d43'}} /> : <></>),
      width: 30
    },
    {
      dataIndex: 'id',
      title: <div style={{textAlign: 'center'}}>번호</div>,
      width: 100,
      render: (id, {isFixation}) =>
        isFixation ? (
          <div style={{textAlign: 'center'}}>
            <strong style={{color: '#d54d43'}}>{id}</strong>
          </div>
        ) : (
          <div style={{textAlign: 'center'}}>{id}</div>
        )
    },
    {
      dataIndex: 'title',
      title: <div style={{textAlign: 'center'}}>제목</div>,
      render: (title, {isFixation}) => (isFixation ? <strong style={{color: '#d54d43'}}>{title}</strong> : title)
    },
    {
      title: <div style={{textAlign: 'center'}}>등록일</div>,
      dataIndex: 'createdAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 300
    },
    {
      title: <div style={{textAlign: 'center'}}>수정일</div>,
      dataIndex: 'updatedAt',
      render: (text: string) => <div style={{textAlign: 'center'}}>{dayjsKorean(text).format('YYYY/MM/DD')}</div>,
      width: 300
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    if (state.data.data) {
      const tmp = [...state.data.data]
      const fixationed = tmp.filter(({isFixation}) => isFixation)
      const disfixationed = state.data.data.filter(({isFixation}) => !isFixation)
      setList([...fixationed, ...disfixationed])
    }
  }, [state])

  return (
    <>
      <BasicTable
        showSearch
        searchPlaceHolder="이름으로 검색"
        size="small"
        rowKey="id"
        columns={columns}
        loading={state.loading}
        dataSource={list.filter(({status}) => status !== 'hidden')}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <NoticeModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
    </>
  )
}

export default withPageContext(Notices, getNotices)
