import api from './index'
import {ICommon} from './products'
import {IVendor} from './vendors'

export interface IProductInfo {
  name: string
  brand: string
  productName: string
  marketingPlatform: string
  orderType: string
  id: number
  titleImage: string
  grade: string
}

export interface IBasicOptionInfo {
  color: string
  hooks: ICommon[]
  charges: ICommon[]
  receivers: {
    id: number
    productReceiverLength: ICommon
    productReceiverPower: ICommon
    productReceiverDirection: ICommon
  }[]
}

export interface IteOptionLRInfo {
  productBents: ICommon
  productBentStyles: ICommon
  productShellTypes: ICommon
  productShellColors: ICommon
  productShellSizes: ICommon
  productPlates: ICommon
  productOutputs: ICommon
  productHandles: ICommon
  productCoatings: ICommon
  productEarwaxOptions: ICommon
  productCanalLengths: ICommon
  productWireless: ICommon
  productAudiograms: ICommon
}

export interface IteOptionInfo {
  leftInfo: IteOptionLRInfo
  rightInfo: IteOptionLRInfo
  name: string
  isBarcode: boolean
  direction: 'left' | 'right' | 'all'
  earImpressionFiles: {cartId: number; id: number; url: string}[]
  addFiles: {cartId: number; id: number; url: string}[]
  memo: string
}

export interface IDeliveryInfo {
  deliveryType: string
  addressName: string
  name: string
  address: string
  addressDetail: string
  phone: string
  memo: string
}

export interface IOrder {
  orderId: number
  orderProductId: number
  orderStatus: string
  trackingNumber: string
  parcel: ICommon
  buyerName: string
  couponInfo: any
  productInfo: IProductInfo
  basicOptionInfo: IBasicOptionInfo
  iteOptionInfo: IteOptionInfo
  deliveryInfo: IDeliveryInfo
  amount: number
  quantity: number
  createdAt: string
}

export interface IPostOrder {
  address: string
  adressDetail: string
  zipcode: string
  sheetId: string
  deliveryTypeId: number
  receiverName: string
  deliveryFee: number
  receiverTel: string
  memo: string
}

interface IPatchOrderCancel {
  status:
    | 'registered'
    | 'confirmed'
    | 'pickingConfirmed'
    | 'pickingRegistered'
    | 'inspection'
    | 'send'
    | 'delivered'
    | 'cancelled'
    | 'adminCancelled'
}

interface IGetOrderParams {
  start: number
  perPage: number
  startDate?: string
  endDate?: string
}

export async function postOrder(data: IPostOrder): Promise<{orderId: number}> {
  try {
    const res = await api.post(`/orders`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postOrderReplicate(id: string): Promise<{orderId: number}> {
  try {
    const res = await api.post(`/orders/${id}/replicate`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postOrderIteReplicate(id: string): Promise<{orderId: number}> {
  try {
    const res = await api.post(`/orders/${id}/ite/replicate`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOrders(
  params: IGetOrderParams = {start: 0, perPage: 1000}
): Promise<{data: IOrder[]; vendor: IVendor[]; total: number}> {
  try {
    const res = await api.get(`/orders`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getOrder(id: number): Promise<{data: IOrder[]; total: number}> {
  try {
    const res = await api.get(`/orders/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchOrderCancel(id: number, data: IPatchOrderCancel): Promise<{id: number}> {
  try {
    const res = await api.patch(`/orders/${id}/cancelled`, data)
    return res.data
  } catch (e) {
    throw e
  }
}
