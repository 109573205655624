/* eslint-disable security/detect-unsafe-regex */
import React, {forwardRef, RefObject, useContext, useEffect, useRef, useState} from 'react'
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Typography
} from 'antd'
import BasicTableModal, {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import {IProduct} from '../../../../api/products'
import SimpleTitle from '../../../../components/common/SimpleTitle'
import styled, {css} from 'styled-components'
import ImageView from '../../../../components/ImageView'
import OrderModal from '../../cart/components/orderModal'
import PageContext from '../../../../contexts/PageContext'
import {useRecoilValue} from 'recoil'
import {userState} from '../../../../states/user.state'
import FileUpload from '../../../../components/FileUpload'
import {putCartIte} from '../../../../api/cart'
import {useWatch} from 'antd/es/form/Form'

interface ModalProps {
  onFetch?: any
  ref: RefObject<BasicTableModalRef>
  actions?: ActionType[]
  title?: string
  record?: Partial<IProduct>
  onAction: (type: ActionType, record: Partial<IProduct>) => void | Promise<void>
}

function Description({record}: {record: any}) {
  return (
    <Row gutter={25}>
      <Col>
        <ImageView src={record?.titleImage} width={299} height={299} />
      </Col>
      <Col>
        <SimpleTitle title={record.name} subTitle={record?.brand} />
        <Descriptions style={{width: '500px'}} size="small" column={1} bordered title="상품 정보">
          <Descriptions.Item span={1} label="브랜드">
            {record?.brand || '-'}
          </Descriptions.Item>
          <Descriptions.Item span={1} label="제품 유형">
            {record?.orderType}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  )
}

function Show({record: {data: recordRaw}, onFetch}: {record: any; onFetch: any}) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [modalActions] = useState<ActionType[]>(['show'])
  const orderModalRef = useRef<BasicTableModalRef>(null)
  const {state, apiHandler} = useContext(PageContext)
  const [record, setRecord] = useState<any>([])
  const [dir, setDir] = useState<any>('')
  const [iteOpts, setIteOpts] = useState<any>({})
  const user: any = useRecoilValue(userState)
  const leftBent = useWatch(['left', 'bentId'], form)
  const rightBent = useWatch(['right', 'bentId'], form)

  async function handleFinish(values: any, isDirectBuy?: boolean) {
    console.log(values)
    if (
      (rightBent !== 7 && values.direction !== 'left' && !values.right.bentStyleId) ||
      (leftBent !== 7 && values.direction !== 'right' && !values.left.bentStyleId)
    ) {
      Modal.error({content: '선택하신 방향의 ITE 옵션을 모두 입력해주세요.'})
      return
    }

    let selectedId: number = 0

    if (values.left.audiograms)
      values.left.audiograms = values.left.audiograms.map((audiograms, index) => ({
        ...iteOpts.productAudiograms[index],
        value: audiograms || 0
      }))
    if (values.right.audiograms)
      values.right.audiograms = values.right.audiograms.map((audiograms, index) => ({
        ...iteOpts.productAudiograms[index],
        value: audiograms || 0
      }))

    if (!values.left.volumeControllerId) values.left.volumeControllerId = 'X'
    if (!values.left.programButtonId) values.left.programButtonId = 'X'

    if (!values.right.volumeControllerId) values.right.volumeControllerId = 'X'
    if (!values.right.programButtonId) values.right.programButtonId = 'X'

    if (values.direction === 'right') delete values.left
    if (values.direction === 'left') delete values.right

    Object.keys(values).forEach((key) => {
      if (!values[key]) values[key] = record[key]
    })

    setLoading(true)
    try {
      if (!values.quantity) values.quantity = 1
      if (values.earImpressionFiles) {
        values.earImpressionFiles = values.earImpressionFiles.map((file) => {
          if (typeof file === 'string') return file
          else return file.url
        })
      }
      if (!values.isBarcode) values.isBarcode = false
      await putCartIte(record.id, values)
      selectedId = record.id
      Modal.success({
        content: '수정이 완료되었습니다.',
        onOk: () => location.reload()
      })
      onFetch()
      selectedId = record.id
    } catch (e: any) {
      if (e.response.status === 409) {
        if (e.response.data.message === 'quantity_is_not_enough') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else if (e.response.data.message === 'lessThan_safetyQuantity') {
          Modal.error({content: '수량이 적당하지 않아 구매가 불가능합니다.'})
        } else {
          Modal.error({
            content: '특정 옵션이 중복되었습니다.'
          })
        }
      } else if (e.response) {
        if (e.response.status === 400) Modal.error({content: '선택하신 방향의 ITE 옵션을 모두 입력해주세요.'})
        else Modal.error({content: `${e.response.status}: ${e.response.data.message}`})
      }
    } finally {
      setLoading(false)
      return selectedId
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    if (recordRaw.iteOption) setIteOpts(recordRaw.iteOption)
  }, [recordRaw])

  useEffect(() => {
    ;(async function () {
      setRecord({...recordRaw, originPrice: user.isPricePublic ? recordRaw.originPrice : -1})
      form.setFieldsValue({
        ...recordRaw.iteOptionInfo,
        quantity:
          recordRaw.iteOptionInfo.leftInfo && recordRaw.iteOptionInfo.rightInfo
            ? recordRaw.quantity / 2
            : recordRaw.quantity,
        productId: recordRaw.productInfo.id,
        left: recordRaw.iteOptionInfo.leftInfo
          ? {
              shellTypeId: recordRaw.iteOptionInfo.leftInfo.productShellTypes.id,
              shellColorId: recordRaw.iteOptionInfo.leftInfo.productShellColors.id,
              shellSizeId: recordRaw.iteOptionInfo.leftInfo.productShellSizes.id,
              plateId: recordRaw.iteOptionInfo.leftInfo.productPlates.id,
              outputId: recordRaw.iteOptionInfo.leftInfo.productOutputs.id,
              bentId: recordRaw.iteOptionInfo.leftInfo.productBents.id,
              bentStyleId: recordRaw.iteOptionInfo.leftInfo.productBentStyles.id,
              handleId: recordRaw.iteOptionInfo.leftInfo.productHandles.id,
              earwaxOptionId: recordRaw.iteOptionInfo.leftInfo.productEarwaxOptions.id,
              coatingId: recordRaw.iteOptionInfo.leftInfo.productCoatings.id,
              canalLengthId: recordRaw.iteOptionInfo.leftInfo.productCanalLengths.id,
              wirelessId: recordRaw.iteOptionInfo.leftInfo.productWireless.id,
              audiograms: recordRaw.iteOptionInfo.leftInfo.productAudiograms.map(({value}) => value),
              volumeControllerId: recordRaw.iteOptionInfo.leftInfo.volumeControllers?.id,
              programButtonId: recordRaw.iteOptionInfo.leftInfo.programButtons?.id
            }
          : {
              volumeControllerId: 'X',
              programButtonId: 'X'
            },
        right: recordRaw.iteOptionInfo.rightInfo
          ? {
              shellTypeId: recordRaw.iteOptionInfo.rightInfo.productShellTypes.id,
              shellColorId: recordRaw.iteOptionInfo.rightInfo.productShellColors.id,
              shellSizeId: recordRaw.iteOptionInfo.rightInfo.productShellSizes.id,
              plateId: recordRaw.iteOptionInfo.rightInfo.productPlates.id,
              outputId: recordRaw.iteOptionInfo.rightInfo.productOutputs.id,
              bentId: recordRaw.iteOptionInfo.rightInfo.productBents.id,
              bentStyleId: recordRaw.iteOptionInfo.rightInfo.productBentStyles.id,
              handleId: recordRaw.iteOptionInfo.rightInfo.productHandles.id,
              earwaxOptionId: recordRaw.iteOptionInfo.rightInfo.productEarwaxOptions.id,
              coatingId: recordRaw.iteOptionInfo.rightInfo.productCoatings.id,
              canalLengthId: recordRaw.iteOptionInfo.rightInfo.productCanalLengths.id,
              wirelessId: recordRaw.iteOptionInfo.rightInfo.productWireless.id,
              audiograms: recordRaw.iteOptionInfo.leftInfo.productAudiograms.map(({value}) => value),
              volumeControllerId: recordRaw.iteOptionInfo.rightInfo.volumeControllers?.id,
              programButtonId: recordRaw.iteOptionInfo.rightInfo.programButtons?.id
            }
          : {
              volumeControllerId: 'X',
              programButtonId: 'X'
            }
      })
      setDir(
        recordRaw.iteOptionInfo.leftInfo && recordRaw.iteOptionInfo.rightInfo
          ? 'all'
          : recordRaw.iteOptionInfo.leftInfo
            ? 'left'
            : 'right'
      )
    })()
  }, [])

  return (
    <Form form={form} layout="vertical" onFinish={handleFinish}>
      <Space direction="vertical" style={{width: '100%', padding: '0 28px 28px 28px'}}>
        <Description record={record} />
        {user.isPricePublic && (
          <Col span={24} style={{marginBottom: 20}}>
            {typeof record.originPrice === 'number' && record.originPrice !== -1 && (
              <Card>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>본 상품의 가격은 다음과 같습니다.</div>
                  <Space>
                    <div style={{color: process.env.REACT_APP_THEME_COLOR, fontSize: 20, fontWeight: 'bold'}}>
                      {record.originPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                    원
                  </Space>
                </div>
              </Card>
            )}
          </Col>
        )}

        <Form.Item name="productId" hidden />
        <Row gutter={30}>
          <Col span={4}>
            <Form.Item name="name" label="착용자명">
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <StyledFormItem name="direction" label="방향">
              <StyledRadioGroup
                onChange={(e) => {
                  setDir(e.target.value)
                }}
              >
                <Radio.Button value="left">좌측</Radio.Button>
                <Radio.Button value="all">모두</Radio.Button>
                <Radio.Button value="right">우측</Radio.Button>
              </StyledRadioGroup>
            </StyledFormItem>
          </Col>
          <Col span={4}>
            <Form.Item name="isBarcode" label="바코드 신청" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={24} style={{marginBottom: 20}}>
            <Row gutter={24}>
              <Col span={12}>
                <IteOptsContainer title="좌측 옵션" size="small" enabled={dir && dir !== 'right'}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item label="쉘 타입" name={['left', 'shellTypeId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productShellTypes?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="쉘 색상" name={['left', 'shellColorId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productShellColors?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="쉘 사이즈" name={['left', 'shellSizeId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productShellSizes?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="플레이트" name={['left', 'plateId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productPlates?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="출력" name={['left', 'outputId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productOutputs?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="벤트" name={['left', 'bentId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productBents?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="벤트 스타일" name={['left', 'bentStyleId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productBentStyles?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="손잡이" name={['left', 'handleId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productHandles?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="귀지 방지 옵션" name={['left', 'earwaxOptionId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productEarwaxOptions?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="코팅" name={['left', 'coatingId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productCoatings?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Space>
                            카날 길이
                            <Popover
                              content={
                                <div style={{width: 470}}>
                                  <ul style={{paddingLeft: 10}}>
                                    <li>
                                      <Typography.Title level={5}>
                                        canal길이는 귓본 채취 시 2커브굴곡에 따라 선택바랍니다.
                                      </Typography.Title>
                                    </li>
                                    <ul style={{paddingLeft: 10}}>
                                      <li>canal 길게 : 2커브에서 2~3mm 이상까지 0 °~30 °의 완만한 굴곡일 때 선택</li>
                                      <li>canal 중간 : 2커브에서 2~3mm까지 30 °~60 °의 적당한 굴곡일 때 선택</li>
                                      <li>canal 짧게 : 2커브이전에 60 °~90 °의 심한 굴곡일 때 선택</li>
                                    </ul>
                                    <br />
                                    <li>
                                      <Typography.Title level={5}>
                                        위 선택사항은 귓본채취가 잘 되었다는 조건하에 선택되어야 하며, <br />
                                        제작자 판단 시 귓본모양에 맞게 옵션이 변경될 수 있음을 안내드립니다.
                                      </Typography.Title>
                                    </li>
                                  </ul>
                                </div>
                              }
                            >
                              <Button shape="circle" size="small">
                                ?
                              </Button>
                            </Popover>
                          </Space>
                        }
                        name={['left', 'canalLengthId']}
                      >
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productCanalLengths?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="무선" name={['left', 'wirelessId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productWireless?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="청력도" name={['left', 'audiograms']}>
                        <Descriptions bordered size="small" column={2}>
                          {iteOpts?.productAudiograms?.map((audiogram, index) => (
                            <Descriptions.Item label={audiogram.name}>
                              <Form.Item name={['left', 'audiograms', index]}>
                                <InputNumber min={0} />
                              </Form.Item>
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="볼륨 조절기" name={['left', 'volumeControllerId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productVolumeControllers?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="프로그램 버튼" name={['left', 'programButtonId']}>
                        <Select disabled={!dir || dir === 'right'}>
                          {iteOpts?.productProgramButtons?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </IteOptsContainer>
              </Col>
              <Col span={12}>
                <IteOptsContainer title="우측 옵션" size="small" enabled={dir && dir !== 'left'}>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Form.Item label="쉘 타입" name={['right', 'shellTypeId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productShellTypes?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="쉘 색상" name={['right', 'shellColorId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productShellColors?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="쉘 사이즈" name={['right', 'shellSizeId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productShellSizes?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="플레이트" name={['right', 'plateId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productPlates?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="출력" name={['right', 'outputId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productOutputs?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="벤트" name={['right', 'bentId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productBents?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="벤트 스타일" name={['right', 'bentStyleId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productBentStyles?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="손잡이" name={['right', 'handleId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productHandles?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="귀지 방지 옵션" name={['right', 'earwaxOptionId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productEarwaxOptions?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="코팅" name={['right', 'coatingId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productCoatings?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <Space>
                            카날 길이
                            <Popover
                              content={
                                <div style={{width: 470}}>
                                  <ul style={{paddingLeft: 10}}>
                                    <li>
                                      <Typography.Title level={5}>
                                        canal길이는 귓본 채취 시 2커브굴곡에 따라 선택바랍니다.
                                      </Typography.Title>
                                    </li>
                                    <ul style={{paddingLeft: 10}}>
                                      <li>canal 길게 : 2커브에서 2~3mm 이상까지 0 °~30 °의 완만한 굴곡일 때 선택</li>
                                      <li>canal 중간 : 2커브에서 2~3mm까지 30 °~60 °의 적당한 굴곡일 때 선택</li>
                                      <li>canal 짧게 : 2커브이전에 60 °~90 °의 심한 굴곡일 때 선택</li>
                                    </ul>
                                    <br />
                                    <li>
                                      <Typography.Title level={5}>
                                        위 선택사항은 귓본채취가 잘 되었다는 조건하에 선택되어야 하며, <br />
                                        제작자 판단 시 귓본모양에 맞게 옵션이 변경될 수 있음을 안내드립니다.
                                      </Typography.Title>
                                    </li>
                                  </ul>
                                </div>
                              }
                            >
                              <Button shape="circle" size="small">
                                ?
                              </Button>
                            </Popover>
                          </Space>
                        }
                        name={['right', 'canalLengthId']}
                      >
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productCanalLengths?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="무선" name={['right', 'wirelessId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productWireless?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="청력도" name={['right', 'audiograms']}>
                        <Descriptions bordered size="small" column={2}>
                          {iteOpts?.productAudiograms?.map((audiogram, index) => (
                            <Descriptions.Item label={audiogram.name}>
                              <Form.Item name={['right', 'audiograms', index]}>
                                <InputNumber min={0} />
                              </Form.Item>
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="볼륨 조절기" name={['right', 'volumeControllerId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productVolumeControllers?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="프로그램 버튼" name={['right', 'programButtonId']}>
                        <Select disabled={!dir || dir === 'left'}>
                          {iteOpts?.productProgramButtons?.map((ite) => (
                            <Select.Option value={ite.id}>{ite.name}</Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </IteOptsContainer>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={30} style={{marginBottom: 20}}>
          <Col span={6}>
            <Form.Item name="earImpressionFiles_temp" hidden />
            <Form.Item name="addFiles_temp" hidden />
            <Form.Item label="귓본파일" name="earImpressionFiles">
              <FileUpload form={form} kind="etc" multiple type="array" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="제작참고사진" name="addFiles">
              <FileUpload form={form} kind="etc" multiple type="array" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="memo" label="제작요청사항">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <StyledSpace>
          <Form.Item>
            <Space>
              <Button
                size="large"
                shape="round"
                type="primary"
                onClick={() => handleFinish(form.getFieldsValue())}
                loading={loading}
                disabled={!record.onSale}
              >
                옵션 변경
              </Button>
            </Space>
          </Form.Item>
        </StyledSpace>
      </Space>
      <OrderModal actions={modalActions} ref={orderModalRef} onAction={onModalAction} />
    </Form>
  )
}

const IteProductModal = forwardRef<BasicTableModalRef, ModalProps>((props, ref) => {
  const [form] = Form.useForm()
  const {title = '상품 ', onAction, onFetch, actions = ['show', 'add', 'edit', 'delete']} = props

  return (
    <BasicTableModal
      maskClosable={false}
      ref={ref}
      actions={actions}
      title={title}
      form={form}
      width={950}
      onAction={onAction}
      render={(type, record) => {
        return (
          <Form form={form}>
            <Show record={record} onFetch={onFetch} />
          </Form>
        )
      }}
    />
  )
})

const IteOptsContainer = styled(Card)<any>`
  transition: all 0.5s;

  ${({enabled}) =>
    enabled
      ? css`
          border: 1px solid ${process.env.REACT_APP_THEME_COLOR};
        `
      : css`
          border: 1px solid rgba(0, 0, 0, 0.06);
        `};

  label {
    color: ${({enabled}) => (enabled ? 'black' : '#9e9e9e')};
  }

  .ant-card-head {
    transition: all 0.5s;
    ${({enabled}) =>
      enabled
        ? css`
            background: ${process.env.REACT_APP_THEME_COLOR};
          `
        : css`
            background: none;
          `};
    color: ${({enabled}) => (enabled ? 'white' : '#9e9e9e')};
  }
`

const StyledSpace = styled(Space)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  width: 100%;

  .ant-radio-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 40px;
  }
`

const StyledFormItem = styled(Form.Item)`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default IteProductModal
