import api from './index'

interface ICommon {
  id: number
  name: string
  hidden: boolean
  createdAt: string
  updatedAt: string
}

export interface IHook {
  id: number
  materialDescription: string
  material: string
  barcode: string
  quantity: number
  quantityUnit: number
  salesNumber: number
  purchaseNumber: number
  deliveryType: string
  currencyType: string
  onSale: boolean
  createdAt: string
  updatedAt: string
}

export interface ICart {
  id: number
  quantity: number
  memo: string
  hidden: boolean
  productGrade: ICommon
  product: any
  color: ICommon
  hook: IHook
  charger: ICommon
  productReceiverDirections: ICommon[]
  productReceiverLengths: ICommon[]
  productReceiverPowers: ICommon[]
  createdAt: string
  updatedAt: string
}

export interface Ite {
  shellTypeId: 0
  shellColorId: 0
  shellSizeId: 0
  plateId: 0
  outputId: 0
  bentId: 0
  bentStyleId: 0
  handleId: 0
  earwaxOptionId: 0
  coatingId: 0
  canalLengthId: 0
}

export interface IPostCart {
  hidden?: boolean
  gradeId: number
  productId: number
  colorId: number
  hookId?: number
  chargerIds?: number[]
  receivers?: {
    receiverDirectionId?: number
    receiverLengthId?: number
    receiverPowerId?: number
  }[]
}

export interface IPostCartIte {
  hidden?: false
  productId: 0
  left?: Ite
  right?: Ite
  name?: string
  direction?: 'left' | 'right' | 'all'
  isBarcode?: true
  memo?: string
  earImpressionFiles?: string[]
  addFile?: string
}

export interface IPutCart {
  quantity: number
}

interface IGetCartParams {
  start: number
  perPage: number
}

interface IGetCartSheetParams {
  cartId?: string
  productId: string
  brand?: string
  orderType?: string
}

export async function postCart(data: IPostCart): Promise<{id: number}> {
  try {
    const res = await api.post(`/cart`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postCartReplicate(cartId: number): Promise<{id: number}> {
  try {
    const res = await api.post(`/cart/replicate`, {cartId})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postCartIteReplicate(cartId: number): Promise<{id: number}> {
  try {
    const res = await api.post(`/cart/ite/replicate`, {cartId})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function postCartIte(data: IPostCartIte): Promise<{id: number}> {
  try {
    const res = await api.post('/cart/ite', data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCart(
  params: IGetCartParams = {start: 0, perPage: 1000}
): Promise<{data: ICart[]; total: number}> {
  try {
    const res = await api.get(`/cart`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getCartItem(id: number): Promise<{data: any[]}> {
  try {
    const res = await api.get(`/cart/app/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getAppCart(): Promise<{data: any; total: number}> {
  try {
    const res = await api.get(`/cart/app`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getSheet(params?: IGetCartSheetParams): Promise<any> {
  try {
    const res = await api.get('/cart/sheet', {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function putCart(id: number, option: any): Promise<{id: number}> {
  try {
    const res = await api.put(`/cart/${id}`, option)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function putCartIte(id: number, data: any): Promise<{id: number}> {
  try {
    const res = await api.put(`/cart/${id}/ite`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteCart(ids: number[]): Promise<void> {
  try {
    const res = await api.delete(`/cart`, {params: {ids}})
    return res.data
  } catch (e) {
    throw e
  }
}
