import React from 'react'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import koKR from 'antd/es/locale/ko_KR'
import './App.css'
import {RecoilRoot} from 'recoil'
import DefaultRoutes from './routes'
import {ConfigProvider} from 'antd'
import {CookiesProvider} from 'react-cookie'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false
    }
  }
})

function App() {
  return (
    <RecoilRoot>
      <ConfigProvider locale={koKR}>
        <QueryClientProvider client={queryClient}>
          <CookiesProvider>
            <DefaultRoutes />
          </CookiesProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </RecoilRoot>
  )
}

export default App
