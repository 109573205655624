import api from './index'

export interface IDataGroup {
  id: number
  name: string
  image: string
  parentId: string
  children?: string
  createdAt: string
  updatedAt: string
  sortOrder: number
  data: {
    id: number
    title: string
    content: string
    files: string
    isFixation: string
    createdAt: string
    updatedAt: string
    brands: {id: number; name: string}[]
    sortOrder: number
  }[]
}

export async function getDataGroups(): Promise<{data: IDataGroup[]}> {
  try {
    const res = await api.get(`/dataGroups`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getDataGroup(id: number): Promise<IDataGroup> {
  try {
    const res = await api.get(`/dataGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
