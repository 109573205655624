import {Button, Form, Input, Modal, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import rules from '../../libs/rules'
import withRequireNoAuth from '../../hocs/withRequireNoAuth'
import {patchResetPassword} from '../../api/auth'
import styled from 'styled-components'
import {
  IPostVerification,
  IPostVerificationConfirm,
  postVerification,
  postVerificationConfirm
} from '../../api/verifications'
import {useForm} from 'antd/lib/form/Form'
import dayjs from 'dayjs'

const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 17
  }
}

function ResetPassword() {
  const [form] = useForm()
  const navigate = useNavigate()
  const [isInputed, setIsInputed] = useState({phone: false, id: false, code: false, newPw: false, newPwConfirm: false})
  const [token, setToken] = useState('')
  const [isVerified, setIsVerified] = useState(false)
  const [expireAt, setExpireAt] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [page, setPage] = useState(1)
  const [remainedTime, setRemainedTime] = useState(dayjs(0))
  const expireAtDayjsed = dayjs(expireAt)

  useEffect(() => {
    const id = setInterval(() => {
      const now = dayjs()
      const diff = expireAtDayjsed.diff(now)
      diff >= 0 ? setRemainedTime(dayjs(diff)) : clearInterval(id)
    }, 1000)
    return () => clearInterval(id)
  }, [expireAt])

  async function handleGetCode(values: IPostVerification) {
    try {
      if (values.codeId) values.code = values.codeId
      const {expireAt, codeToken} = await postVerification(values)
      setToken(codeToken)
      setExpireAt(expireAt)
      setPhoneNumber(values.phone)
      alert('인증번호가 전송되었습니다.')
    } catch (e) {
      Modal.error({
        title: '인증번호 전송 실패',
        content: '휴대폰 번호와 아이디가 일치하지 않습니다.'
      })
    }
  }

  async function handleResendCode() {
    try {
      const values = {
        phone: phoneNumber,
        type: 'resetPassword',
        forAndroid: false,
        code: form.getFieldValue('codeId')
      }
      const {expireAt, codeToken} = await postVerification(values)
      setPhoneNumber(values.phone)
      setToken(codeToken)
      setExpireAt(expireAt)
      Modal.success({content: '인증번호가 전송되었습니다.'})
    } catch (e) {
      Modal.error({
        title: '인증번호 전송 실패',
        content: '관리자에게 문의하세요.'
      })
    }
  }

  async function handleConfirm(values: IPostVerificationConfirm) {
    try {
      const {codeToken} = await postVerificationConfirm(values)
      setToken(codeToken)
      setIsVerified(true)
      Modal.success({content: '인증에 성공했습니다.'})
    } catch (e) {
      Modal.error({
        title: '인증번호가 올바르지 않습니다.',
        content: '이 현상이 지속되면 관리자에게 문의하세요.'
      })
    }
  }

  async function handleResetPassword(values: any) {
    if (values.newPassword === values.newPasswordConfirm) {
      try {
        if (values.codeId) values.code = values.codeId
        await patchResetPassword(values)
        Modal.success({content: '비밀번호가 변경되었습니다.', onOk: () => navigate('/auth')})
      } catch (e: any) {
        if (e?.response?.status === 409) {
          Modal.error({
            title: '존재하지 않는 판매자 계정',
            content: `휴대폰 번호 ${phoneNumber}를 사용하는 판매자 계정이 없습니다.`
          })
        } else {
          Modal.error({
            title: '알 수 없는 오류가 발생했습니다.',
            content: '이 현상이 지속되면 관리자에게 문의하세요.'
          })
        }
      }
    } else {
      Modal.error({
        content: '비밀번호가 일치하지 않습니다.'
      })
    }
  }

  useEffect(() => {
    form.setFieldValue('type', 'resetPassword')
  }, [])

  useEffect(() => {
    if (isVerified) form.setFieldsValue({phone: phoneNumber, phoneToken: token})
    else if (token) form.setFieldValue('codeToken', token)
  }, [token])

  return (
    <>
      <Header>
        <Logo />
        <Button type="text">로그인</Button>
      </Header>

      {page === 1 ? (
        <Content>
          <Typography.Text
            style={{
              fontSize: 18,
              fontWeight: '500',
              textAlign: 'center',
              padding: '14px 93px',
              marginBottom: 36
            }}
          >
            비밀번호 찾기
          </Typography.Text>
          <Form {...layout} form={form} layout="vertical" name="basic" initialValues={{remember: true}}>
            <Form.Item hidden name="type">
              <Input />
            </Form.Item>
            {!token ? (
              <FormItemFixedWidth
                label="아이디"
                name="codeId"
                rules={[
                  {
                    required: true,
                    message: '아이디를 입력해주세요.'
                  }
                ]}
              >
                <InputRoundPw
                  onChange={(e) =>
                    e.target.value
                      ? setIsInputed((prev) => ({...prev, id: true}))
                      : setIsInputed((prev) => ({...prev, id: false}))
                  }
                  placeholder="아이디를 입력해주세요."
                />
              </FormItemFixedWidth>
            ) : (
              <Form.Item name="codeId" hidden>
                <Input />
              </Form.Item>
            )}

            <FormItemWidthButtonWrapper>
              <FormItemFixedWidth
                label="휴대폰 번호"
                name="phone"
                rules={[
                  {required: true, message: '휴대폰 번호를 입력해주세요'},
                  {pattern: /^01([016789]?)(\d{3,4})(\d{4})$/, message: '01XXXX(X)XXXX 형태로 입력해주세요'}
                ]}
              >
                <InputRound
                  onChange={(e) =>
                    e.target.value
                      ? setIsInputed((prev) => ({...prev, phone: true}))
                      : setIsInputed((prev) => ({...prev, phone: false}))
                  }
                  placeholder="휴대폰 번호를 입력해주세요."
                  width={258}
                />
              </FormItemFixedWidth>
              {!token ? (
                <SignInButton
                  type="primary"
                  disabled={!isInputed.phone}
                  width={84}
                  onClick={() => {
                    handleGetCode(form.getFieldsValue())
                  }}
                >
                  전송
                </SignInButton>
              ) : (
                <SignInButton type="primary" disabled={!isInputed.phone} onClick={handleResendCode} width={84}>
                  전송
                </SignInButton>
              )}
            </FormItemWidthButtonWrapper>

            <Form.Item hidden name="codeToken">
              <Input />
            </Form.Item>

            <FormItemWidthButtonWrapper>
              <FormItemFixedWidth
                label="인증 번호"
                name="code"
                rules={[
                  {
                    required: true,
                    message: '인증번호를 입력해주세요.'
                  }
                ]}
              >
                <InputRound
                  disabled={!token}
                  onChange={(e) => {
                    if (e.target.value) {
                      setIsInputed((prev) => ({...prev, code: true}))
                      form.setFieldValue('code', e.target.value)
                    } else setIsInputed((prev) => ({...prev, code: false}))
                  }}
                  placeholder="인증번호를 입력해주세요."
                  width={258}
                />
                {token &&
                  (!+remainedTime ? (
                    <RemainedTimeWrapper>인증 시간이 초과되었습니다.</RemainedTimeWrapper>
                  ) : (
                    <RemainedTimeWrapper>남은 시간 : {remainedTime.format('mm:ss')}</RemainedTimeWrapper>
                  ))}
              </FormItemFixedWidth>
              <SignInButton
                type="primary"
                disabled={!isInputed.code || !+remainedTime}
                width={84}
                onClick={() => handleConfirm(form.getFieldsValue())}
              >
                인증
              </SignInButton>
            </FormItemWidthButtonWrapper>

            <Form.Item style={{margin: '12px 0'}}>
              <SignInButton type="primary" disabled={!isVerified} width={350} onClick={() => setPage(2)}>
                비밀번호 찾기
              </SignInButton>
            </Form.Item>
          </Form>
        </Content>
      ) : (
        <Content>
          <Typography.Text
            style={{
              fontSize: 18,
              fontWeight: '500',
              textAlign: 'center',
              padding: '14px 93px',
              marginBottom: 36
            }}
          >
            비밀번호 재설정
          </Typography.Text>

          <Form
            {...layout}
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{remember: true}}
            onFinish={handleResetPassword}
          >
            <Form.Item hidden name="phoneToken">
              <Input />
            </Form.Item>

            <Form.Item name="phone" hidden>
              <Input />
            </Form.Item>

            <Form.Item name="codeId" hidden>
              <Input />
            </Form.Item>

            <FormItemFixedWidth
              label="새로운 비밀번호"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: '비밀번호를 입력해주세요.'
                },
                ...rules.password
              ]}
            >
              <InputRoundPw
                onChange={(e) =>
                  e.target.value
                    ? setIsInputed((prev) => ({...prev, newPw: true}))
                    : setIsInputed((prev) => ({...prev, newPw: false}))
                }
                placeholder="비밀번호를 입력해주세요."
              />
            </FormItemFixedWidth>

            <FormItemFixedWidth
              label="새로운 비밀번호 확인"
              name="newPasswordConfirm"
              rules={[
                {
                  required: true,
                  message: '비밀번호를 한번 더 입력해주세요.'
                },
                ...rules.password
              ]}
            >
              <InputRoundPw
                onChange={(e) =>
                  e.target.value
                    ? setIsInputed((prev) => ({...prev, newPwConfirm: true}))
                    : setIsInputed((prev) => ({...prev, newPwConfirm: false}))
                }
                placeholder="비밀번호를 한번 더 입력해주세요."
              />
            </FormItemFixedWidth>

            <CancelSettingWrapper>
              <Button
                type="link"
                onClick={() => confirm('정말로 비밀번호 변경을 취소하시겠습니까?') && navigate('/auth')}
              >
                설정 취소
              </Button>
            </CancelSettingWrapper>

            <Form.Item style={{margin: '12px 0'}}>
              <SignInButton
                disabled={!isInputed.newPw || !isInputed.newPwConfirm}
                width={350}
                type="primary"
                htmlType="submit"
              >
                비밀번호 재설정
              </SignInButton>
            </Form.Item>
          </Form>
        </Content>
      )}
    </>
  )
}

export default withRequireNoAuth(ResetPassword)

const Logo = styled.div`
  width: 80.64px;
  height: 32px;
  background-image: url('/logo256.png');
  background-size: contain;
  background-repeat: no-repeat;
`

const Header = styled.div`
  padding: 25px 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5ea;
`

const Content = styled.div`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SignInButton = styled<any>(Button)`
  width: ${({width}: any) => width}px;
  height: 48px;
  padding: 12px 0;
  margin: 31.75px 0 auto 0;
  border-radius: 6px;
`

const FormItemWidthButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 350px;
`

const FormItemFixedWidth = styled(Form.Item)`
  max-width: 350px;
  margin-bottom: 34px;

  .ant-col-4 {
    max-width: 258px;
  }

  .ant-col-17 {
    max-width: 258px;
  }

  .ant-form-item-required {
    &::before {
      margin: -10px;
      background: white;
    }
  }
`

const InputRound = styled<any>(Input)`
  width: ${({width}) => width}px;
  height: 48px;
  padding: 14px;
  border-radius: 8px;
`

const InputRoundPw = styled(Input.Password)`
  width: 350px;
  padding: 14px;
  border-radius: 8px;
`

const CancelSettingWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin: 6px 0 -9.75px 0;

  .ant-btn {
    height: 14px;
    color: #aeaeb2;
    font-size: 12px;
    padding: 0;

    span {
      text-decoration: underline;
    }
  }
`

const RemainedTimeWrapper = styled.div`
  color: #aeaeb2;
  font-size: 12px;
`
